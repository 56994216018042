import { TextInput } from '@coverright/ui/inputs';
import * as React from 'react';


interface Props {
  error?: string,
  label?: string,
  autoFocus?: boolean,
  loading?: boolean,
  value: string,
  onChange: (value: string) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
}

export function ZipInput(props: Props) {
  const {error, autoFocus, onChange, value, loading, label, onFocus, onClick} = props;
  return <TextInput fullWidth
                    error={!!error}
                    helperText={error}
                    autoFocus={autoFocus ?? true}
                    value={value}
                    validator={val => (val as string)?.length === 5}
                    loading={loading}
                    data-test={'zip'}
                    onFocus={onFocus}
                    onClick={onClick}
                    onChange={(event) => {
                      onChange(event.target.value)
                    }}
                    label={label || 'Zip Code'}
                    name={'zip'}
                    inputProps={{
                      maxLength: 5
                    }}
                    type={'tel'}
                    placeholder={'Enter 5 digit zip code'} />
}
