import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { FetchResult } from '@apollo/client';
import { PdpQuoteOutput, QueryPdpQuoteArgs } from '@coverright/data-access/types/medicare';
import { client } from '@coverright/data-access/apollo-clients';

export function usePdpQuote(options?: LazyQueryHookOptions<{pdpQuote: PdpQuoteOutput}, QueryPdpQuoteArgs>) {
  return useLazyQuery<{pdpQuote: PdpQuoteOutput}, QueryPdpQuoteArgs>(
    gql(query),
    {
      ...options,
      fetchPolicy: 'no-cache'
    }
  );
}

export const getPdpQuote = (id: string): Promise<FetchResult<{pdpQuote: PdpQuoteOutput}>> =>
  client.query({
    query: gql(query),
    variables: { id }
  });

const query = `query ($id: UUID!) {
  pdpQuote(id: $id) {
    cId
    clientId
    countyName
    id
    insulinSavings
    zip
    drugDeliveryType
    preferredDrugs {
      dosageRxcui
      frequency
      productRxcui
      purchaseFrequency
      quantity
    }
    preferredPharmacies
  }
}`

