import { PageWrapper } from '@coverright/ui/shared';
import React from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getStoredUser } from '@coverright/utils';
import {Calendly, CalendlyCallType, useCalendlyUrl} from '@coverright/features/shared';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { AppStateActionTypes, AppStateContext } from '../../AppStateContext';
import { AnimationContext } from '@coverright/shared/contexts';
import {environment} from "../../../environments/environment";

export default function () {
  const history = useHistory();
  const {keycloak} = React.useContext(KeycloakContext);
  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(true);
  const [state, dispatch] = React.useContext(AppStateContext);
  const {reverse} = React.useContext(AnimationContext)
  const calendlyUrl = useCalendlyUrl(environment.production);

  const onSubmit = () => {
    dispatch({type: AppStateActionTypes.RESET});
    keycloak.logout({ redirectUri: document.location.origin });
  };

  return (
    <PageWrapper title={'Please select an appointment time.'}
                 subtitle={'If you\'d like to be included in the meeting with your client, please add yourself as a \'Guest\'.'}
                 formName={'schedule-a-call'}
                 sx={{mt: {md: '70px'}}}
                 nextButtonTitle={'Close and return'}
                 nextButtonDisabled={nextButtonDisabled}
                 onNextButtonClick={onSubmit}
                 onBackButtonClick={() => {
                   reverse && reverse();
                   setTimeout(() => history.push('/want-a-book'), 50)
                 }}
    >
      <Box sx={{minHeight: '288px',
        display: 'flex',
        paddingLeft: {xs: '10px', sm:  0 },
        paddingRight: '10px',
        alignItems: 'center',
        justifyContent: 'center'}}>
        <Calendly onEventScheduled={() => setNextButtonDisabled(false)}
                  height={'760px'}
                  type={CalendlyCallType.consultation}
                  dataUrl={calendlyUrl}
        />
      </Box>
    </PageWrapper>
  );
}

