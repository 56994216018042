import { IZipCountyFips } from '@coverright/data-access/medicare';
import { GetLicensedStates } from '@coverright/utils';

export const getState = (countyName: string, counties: IZipCountyFips[]): string => (
  counties.find(c => c.countyname === countyName)?.state || 'unknown'
)

export const isInLicensedStates = (state = '') => GetLicensedStates().includes(state);


const sessionKey = 'NOT_SERVED_SENT';

const getSentRequests = (): string[] => (
  JSON.parse(sessionStorage.getItem(sessionKey) || '[]')
)

export const addZipToSentRequests = (zip: string) => (
  sessionStorage.setItem(sessionKey, JSON.stringify([...getSentRequests(), zip]))
)

export const isRequestSent = (zip: string) => getSentRequests().includes(zip);
