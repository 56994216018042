import { Box } from '@mui/material';
import { SxProps } from '@mui/material';

export function V3Preloader({sx}: {sx?: SxProps}) {
  return <Box className={'relative'}
              data-test={'preloader'}
              sx={{minHeight: 100, maxWidth: 100, m: 'auto', ...sx}}>
    <div className="preloader">
      <hr/>
      <hr/>
      <hr/>
    </div>
  </Box>
}
