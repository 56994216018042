import { useLogEvent } from './useLogEvent';
import * as React from 'react';
import { getCalendlyInvitee } from '@coverright/data-access/enrollment';

export function useTrackAppointment() {
  const logEvent = useLogEvent();

  React.useEffect(() => {
    function isCalendlyEvent(e: MessageEvent<any>) {
      return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    const listener = async (message: MessageEvent<any>) => {
      if (isCalendlyEvent(message)) {
        if (message.data.event === 'calendly.event_scheduled') {
          await logEvent('Appointment is successfully booked', await getCalendlyInvitee(message.data.payload.event.uri, message.data.payload.invitee.uri));
        }
      }
    };
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [])

}
