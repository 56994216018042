import { Rating } from '@mui/lab';
import { styled } from '@mui/material';

export const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#1D9C80!important',
  },
  '& .MuiRating-icon': {
    color: 'rgba(212, 212, 212, 1)',
  },
  root: {
    marginTop: 7,
  },
});
