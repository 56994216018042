import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { UserProfileInput, UserProfileSaveOutput } from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';

export function useSaveUserProfile(options?: MutationHookOptions<{saveUserProfile: UserProfileSaveOutput}, {data: UserProfileInput}>) {
  return useMutation<{saveUserProfile: UserProfileSaveOutput}, {data: UserProfileInput}>(gql(saveUserInfoMutation), {
    client: enrollmentClient,
    ...options
  })
}

const saveUserInfoMutation = `
mutation ($data: UserProfileInput!) {
  saveUserProfile(input: $data) {
    enrollmentId
    profileId
    userId
    userToken {
      accessToken
      idToken
      refreshToken
    }
  }
}
`;
