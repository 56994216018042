import * as yup from "yup";
import {useFormik} from "formik";
import { Box, Button, Typography } from '@mui/material';
import { PhoneNumberInput } from '@coverright/ui/inputs';
import { CRModal } from '@coverright/ui/shared';
import { colors } from '@coverright/ui/themes';
import { getStoredUser } from '@coverright/utils';
import { useSaveUserProfile, validatePhone } from '@coverright/data-access/enrollment';
import { ProfileSource } from '@coverright/data-access/types/enrollment';
import * as React from 'react';
import { useLogEvent } from '@coverright/shared/analytics';
import { LockKey } from '@phosphor-icons/react';
import { ModalCloseButton } from '@coverright/shared/ui';

const validationSchema = yup.object({
  phoneNumber: yup.string()
    .required("Phone number is required")
    .test("test-phone",
      "Please enter a valid phone",
      validatePhone)
});

type NotServedProps = {
  onSubmit: () => void,
  onClose: () => void,
  open: boolean
}

export function NotServed(props: NotServedProps) {
  const [requestACall] = useSaveUserProfile();
  const logEvent = useLogEvent();

  const formik = useFormik({
    initialValues: {
      phoneNumber: getStoredUser()?.phoneNumber || '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (formik.isValid) {
        requestACall({variables: {
            data: {
              phoneNumber: values.phoneNumber,
              cId: (window as any).gaGlobal?.vid,
              source: ProfileSource.OutOfService,
            }
          }}).then((res) => {
          logEvent('profile-saved', {'profile_id': res.data?.saveUserProfile.profileId, source: ProfileSource.OutOfService})
          props.onSubmit();
        }).catch(() => {
          props.onClose();
        });
      }
    },
    validateOnChange: true,
  });

  return <CRModal
    width={450}
    open={props.open}
    onClose={props.onClose}
    modalScroll
    paperPadding={'24px'}
    data-test={'not-served-modal'}
  >
      <Box display={'flex'} flexDirection={'column'} flex={1} gap={2}>
        <Box sx={{display: 'flex', justifyContent: 'flex-end', m: '-8px'}}>
          <ModalCloseButton onClick={props.onClose} />
        </Box>
        <Typography variant={'h4'} align={'center'}>Sorry, we’re currently not in your area!</Typography>
        <Typography align={'center'}>We currently aren’t licensed in your state.</Typography>
        <Typography sx={{color: colors.custom.green.variant2}} align={'center'}>If you would like one of our trusted local insurance partners to reach out to you, provide your number.</Typography>

        <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
          <PhoneNumberInput onChange={value => formik.setFieldValue('phoneNumber', value)}
                            name="phone"
                            label="Phone number"
                            defaultValue={getStoredUser()?.phoneNumber}
                            error={formik.errors.phoneNumber as string}
                            isValid={() => !formik.submitCount || formik.values.phoneNumber !== '1' && !formik.errors.phoneNumber}
          />
          <Box mt={3} display={"flex"} justifyContent={"center"}>
            <Button variant={"contained"}
                    color={'primary'}
                    sx={{width: '310px'}}
                    className={'gtm-button-event'}
                    data-gtm-button-name={'CREATE_PROFILE_BUTTON'}
                    disabled={!formik.isValid}
                    type={"submit"}>
              Submit
            </Button>
          </Box>
        </form>
        <Box display={'flex'} alignItems={'start'} gap={2}>
          <LockKey size={24} color={'#1C434F'} />
          <Typography className={'bold fs-14 ml-8'} color={'textPrimary'}>100% secure.  Your information will passed on securely to a trusted partner.</Typography>
        </Box>
        <Box sx={{ backgroundColor: 'white', boxSizing: 'border-box', width: 1, borderRadius: '8px', p: 2, mb: 1, overflowY: 'auto', height: 106 }}>
          <Typography sx={{maxWidth: 800, m: 'auto', fontFamily: 'Inter, Sailec, Roboto'}} className={'fs-15'}>
            By submitting my phone number above and clicking ‘Submit’ above, I confirm that
            the phone number provided is my own, and I provide my express written consent to be contacted by CoverRight Inc.
            and its subsidiaries at the phone number I have provided above regarding various Medicare products including
            Medicare Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans and other marketing and
            informational content, through the use of text message and/or live, automated and pre-recorded telephone calls,
            even if my number is on a Do Not Call list. I further understand and agree that my communications with
            CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</a>.
            <br /><br />
            This is a solicitation for insurance. Your information will be provided to a licensed agent for future contact.
            <br /><br />
            I understand my consents to receive text messages and telephone calls are not required to receive services from
            CoverRight Inc.; I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can revoke
            my consents at any time by emailing hello@coverright.com.
            <br /><br />
            CoverRight Insurance Services Inc. represents Medicare Advantage HMO, PPO and PFFS organizations that have a
            Medicare contract. Enrollment depends on the plan’s contract renewal.
            <br /><br />
            We do not offer every plan available in your area. Any information we provide is limited to those plans we do
            offer in your area. Please contact Medicare.gov or 1–800–MEDICARE 24 hours a day/7 days a week to get
            information on all of your options.
          </Typography>
        </Box>
      </Box>
  </CRModal>
}
