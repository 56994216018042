import React from "react";
import { ClassNameMap } from '@mui/styles';
import {
  Box,
  FormControlProps,
  MenuItem, OutlinedInputProps,
  SelectProps
} from '@mui/material';
import { TextInput } from '../text-input/text-input';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CaretDown } from '@phosphor-icons/react';

type SelectInputProps = FormControlProps<'select', SelectProps> & {
  helperText?: string | React.ReactElement,
  placeholder?: string,
  labelClassName?: string,
  paperClassName?: string,
  loading?: boolean,
  hideTick?: boolean,
  styles?: ClassNameMap<'paper' | 'root' | 'select' | 'inputLabel'>,
  InputProps?: Partial<OutlinedInputProps | undefined>,
}

// 55385
export function SelectInput(props: SelectInputProps) {
  const {helperText, paperClassName, loading, labelClassName, hideTick, InputProps, ...rest} = props;

  const placeholderClassName = React.useMemo(() => {
    return !props.value && props.placeholder ? 'MuiSelect-placeholder' : '';
  }, [props.value, props.placeholder]);

  return (
      <TextInput
        fullWidth
        select
        loading={loading}
        className={props.className}
        hideTick={hideTick}
        value={props.value}
        label={props.label}
        inputLabelClass={props.labelClassName}
        name={props.name}
        error={props.error}
        helperText={helperText}
        tickPosition={'start'}
        endAdornmentSx={{mr: '27px'}}
        InputProps={InputProps}
        SelectProps={{
          IconComponent: Caret,
          ...rest,
          displayEmpty: true,
          className: placeholderClassName,
          MenuProps: {
            ...rest.MenuProps,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            PopoverClasses: {
              paper: paperClassName,
            },
            BackdropProps: {
              sx: {
                background: 'transparent',
              }
            }
          }
        }}
        placeholder={props.placeholder} >
        {!!props.placeholder && <MenuItem sx={{display: 'none'}} value="" disabled>
          {props.placeholder}
        </MenuItem>}
        {props.children}
      </TextInput>
  );
}

const Caret = (props: any) => <Box {...props}><CaretDown size={24} color="#1C434F" weight="regular" /></Box>

