import { SxProps } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { WarningCircle } from '@phosphor-icons/react';
import React from 'react';

export const ErrorAdornment = ({sx}: {sx?: SxProps}) => (
  <InputAdornment sx={sx}
                  disablePointerEvents
                  position="end">
    <WarningCircle size={24} color="#D32C1C" weight="regular" />
  </InputAdornment>
);
