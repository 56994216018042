import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import {
  AgentViewOutput,
  Maybe,
  QueryAgentAssignedToClientArgs
} from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';

export function useAgentAssignedToClient(options?: LazyQueryHookOptions<{agentAssignedToClient: Maybe<AgentViewOutput>}, QueryAgentAssignedToClientArgs>) {
  return useLazyQuery<{agentAssignedToClient: Maybe<AgentViewOutput>}, QueryAgentAssignedToClientArgs>(gql(query), {
    client: enrollmentClient,
    ...options
  });
}

const query = `
query ($clientId: UUID!) {
  agentAssignedToClient(clientId: $clientId) {
    email
    firstName
    id
    lastName
    picUrl
    phone
    enrollmentMeetingUrl
    planReviewUrl
    consultationUrl
  }
}
`
