import * as React from 'react';
import { KeycloakContext, KeycloakRoles } from '@coverright/shared/keycloak';
import Keycloak from 'keycloak-js';
import moment from "moment/moment";
import { AppActionTypes, AppContext } from './AppContext';
import { useEffect } from 'react';

type ABTestType = 'pre_congrats_modal'

export enum ABVariant {
  a = 'a',
  b = 'b',
  ab = 'ab',
}

type ABTestConfig = {
  [key in ABTestType]?: ABVariant
}

interface UserTestConfig {
  compare_year_to_year: boolean,
  flexpa: boolean,
}

interface IUserTestContext {
  isTester: boolean,
  config: UserTestConfig,
  abTesting?: ABTestConfig,
  getABVariant: (key: keyof ABTestConfig) => Promise<ABVariant | false | undefined>,
  generateABVariant: (key: keyof ABTestConfig) => ABVariant,
}

const initialConfig: UserTestConfig = {
  compare_year_to_year: false,
  flexpa: false
}

export const UserTestContext = React.createContext<IUserTestContext>({
  isTester: false,
  config: initialConfig,
  getABVariant: async (key: keyof ABTestConfig) => undefined,
  generateABVariant: () => ABVariant.a
});

export const getIsTester = (keycloak: Keycloak) => keycloak.hasRealmRole(KeycloakRoles.ROLE_ALFA);

export function UserTestContextProvider(props: React.PropsWithChildren<any>) {
  const [config, setConfig] = React.useState<UserTestConfig>(initialConfig);
  const [abTests, setAbTests] = React.useState<ABTest[]>();
  const [isTester, setIsTester] = React.useState<boolean>(false);
  const {initialized, keycloak} = React.useContext(KeycloakContext);
  const [{state: {abTesting}}, dispatch, loaded] = React.useContext(AppContext);
  const [abListeners, setAbListeners] = React.useState<Array<(tests: ABTest[], ab: ABTestConfig) => void>>([]);

  React.useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      setIsTester(getIsTester(keycloak));
      init(getIsTester(keycloak))
    }
  }, [initialized, keycloak?.authenticated])

  useEffect(() => {
    init(false)
  }, []);

  const init = async (tester: boolean) => {
    try {
      await fetchABTesting().then(setAbTests);
      const state = await fetch(process.env.NX_USER_TEST_CONFIG_URL as string).then(r => r.json())
      setConfig(tester ? state.alfa : state.default)
    } catch (e) {
      console.error('Error:', e);
    }
  }

  React.useEffect(() => {
    if (abTests && loaded && abListeners.length) {
      abListeners.forEach(listener => listener(abTests, abTesting || {}))
    }
  }, [abTesting, loaded, abTests, abListeners])

  const generateABVariant = (key: keyof ABTestConfig): ABVariant => {
    const value = Math.random() > .5 ? ABVariant.b : ABVariant.a
    dispatch({
      type: AppActionTypes.SAVE_STATE,
      payload: {
        stateKey: 'abTesting',
        value: {
          ...abTesting,
          [key]: value
        }
      },
    });
    return value;
  }

  const getABVariant = (key: keyof ABTestConfig): Promise<ABVariant | undefined | false> => {
    return new Promise(resolve => {
      if (abTests && abTesting) {
        if (abTests.find(t => t.key === key)?.active) {
          resolve(abTesting[key] as ABVariant);
        } else {
          resolve(false)
        }
      } else {
        setAbListeners(prev => [...prev, (tests, ab) => {
          if (tests.find(t => t.key === key)?.active) {
            resolve(ab[key] as ABVariant);
          } else {
            resolve(false)
          }
        }]);
      }
    })
  }

  return <UserTestContext.Provider value={{config, isTester, getABVariant, generateABVariant, abTesting}}>
    {props.children}
  </UserTestContext.Provider>
}

export const withUserTestContext = (WrappedComponent: any) => (props: any) => {
  return (
    <UserTestContextProvider>
      <WrappedComponent {...props} />
    </UserTestContextProvider>
  )
}

export const fetchABTesting = async () => {
  const data = await fetch(
    process.env.NX_STRAPI + `/api/a-b-tests`,
    {
      headers: {
        "content-type": "application/json",
        'Authorization': 'Bearer ' + process.env.NX_STRAPI_TOKEN
      },
      cache: 'no-cache',
    }
  ).then(res => res.json())
  const tests: ABTest[] = data.data.map((i: {attributes: ABTest}) => i.attributes);

  return tests.filter(t => {
    if (t.stagingOnly && process.env.NX_PRODUCTION === 'true') {
      return false
    }

    if (t.from && t.to) {
      return moment().isBetween(moment(t.from), moment(t.to));
    } else if (t.from) {
      return moment().isAfter(moment(t.from))
    } if (t.to) {
      return moment().isBefore(moment(t.to))
    }
    return true;
  })
}




interface ABTest {
  active: boolean,
  stagingOnly: boolean,
  from: string | null,
  to: string | null,
  key: ABTestType,
}
