import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import { QueryIsPhoneValidArgs } from '@coverright/data-access/types/enrollment';

export function useIsPhoneValid(options?: LazyQueryHookOptions<{isPhoneValid: boolean}, QueryIsPhoneValidArgs>) {
  return useLazyQuery<{isPhoneValid: boolean}, QueryIsPhoneValidArgs>(
    gql(request),
    {
      ...options,
      client: enrollmentClient
    }
  );
}

export function validatePhone(value?: string) {
  if (!!value && /\d{11}/.test(value.toString())) {
    return new Promise((resolve, reject) => {
      if (value === '+10000000000') {
        resolve(true);
      } else {
        enrollmentClient.query<{ isPhoneValid: boolean }, QueryIsPhoneValidArgs>({
          query: gql(request),
          variables: { phone: value || '' }
        }).then(res => {
          resolve(res.data.isPhoneValid)
        })
      }
    }) as any
  } else {
    return false
  }
}

const request = `
query ($phone: String!) {
  isPhoneValid(phone: $phone)
}
`;
