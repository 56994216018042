import * as React from 'react';
import { Box, Card, Collapse, IconButton, RadioGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CRRadio } from '@coverright/ui/inputs';
import { formatPharmacyName } from '@coverright/utils';
import PharmaciesModal from './PharmaciesModal';
import * as _ from 'lodash';
import { DrugDeliveryTypeDto, PharmacyLocationDistanceOutput } from '@coverright/data-access/types/medicare';
import { MedigapDrugDeliveryType } from '@coverright/data-access/types/medigap';

const useStyles = makeStyles({
  radioGroup: {
    flexDirection: 'row',
  },
  link: {
    color: '#5ECB9E',
    cursor: 'pointer',
    fontWeight: 500,
  },
  linkGray: {
    color: '#029094!important',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    flexDirection: 'row!important' as any,
    alignItems: 'center',
    padding: '13px 16px 8px 20px',
    background: 'white',
    marginBottom: 20
  },
  addPharmacyButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: `1px dashed #029094`,
    background: 'rgba(2, 144, 148, 0.05)',
    borderRadius: 8,
    width: '100%',
    paddingTop: 17,
    paddingBottom: 17,
    color: '#029094',
    fontWeight: 700,
    fontSize: 16,
  },
  removeButton: {
    width: 24,
    height: 24,
    minWidth: 24,
    minHeight: 24,
  }
});

/* eslint-disable-next-line */
export interface PharmaciesSelectionProps {
  zip?: string,
  value: PharmacyLocationDistanceOutput[],
  onChange: (data: PharmacyLocationDistanceOutput[]) => void,
  onOrderTypeChange: (data?: DrugDeliveryTypeDto) => void,
  orderType?: DrugDeliveryTypeDto | MedigapDrugDeliveryType,
}

export function PharmaciesSelection(props: PharmaciesSelectionProps) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const onPopupSubmit = (paharmacies: PharmacyLocationDistanceOutput[]) => {
    setModalOpen(false);
    props.onChange(paharmacies)
  };

  const onModalClose = () => {
    setModalOpen(false);
  }

  return <>
    {!!props.zip && <PharmaciesModal zip={props.zip}
                               values={props.value}
                               open={modalOpen}
                               onClose={onModalClose}
                               onSubmit={onPopupSubmit} />}
    <RadioGroup row={!phone}>
      <Box sx={{display: 'flex', flex: 1, gap: '12px', flexDirection: {xs: 'column', md: 'row'}}}>
        <Box display={'flex'} flex={1}>
          <CRRadio checked={props.orderType === DrugDeliveryTypeDto.Pharmacy} onChange={(e, checked) => props.onOrderTypeChange(DrugDeliveryTypeDto.Pharmacy)} value="yes" label="Retail Pharmacy" />
        </Box>
        <Box display={'flex'} flex={1}>
          <CRRadio checked={props.orderType === DrugDeliveryTypeDto.Mail} onChange={(e, checked) => {
            props.onOrderTypeChange(DrugDeliveryTypeDto.Mail);
          }} value="no" label="Mail Order" />
        </Box>
      </Box>
    </RadioGroup>
    <Collapse in={props.orderType === DrugDeliveryTypeDto.Pharmacy}>
      <Box display={'flex'} flexDirection={'column'} flex={1} mt={'16px'}>
        {!!props.value?.length && <Box mb={'23px'}>
          <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>Your selected pharmacy:</Typography>
        </Box>}
        {props.value?.map(pharmacy => <Card key={pharmacy.npi} className={classes.lineWrapper}>
          <PharmacyLine pharmacy={pharmacy} />
          <Box ml={2} minWidth={'123px'}>
            <Typography variant={'body2'} className={classes.linkGray} onClick={() => setModalOpen(true)}>Change pharmacy</Typography>
          </Box>
          <Box ml={'6px'} mr={'-12px'}>
            <IconButton onClick={() => props.onChange(_.without(props.value, pharmacy))}>
              <img src={'/assets/img/remove_round_green_2.svg'} className={classes.removeButton} />
            </IconButton>
          </Box>
        </Card>)}
        {(props.orderType === DrugDeliveryTypeDto.Pharmacy && !props.value?.length) && <Box className={classes.addPharmacyButton} onClick={() => setModalOpen(true)}>
          <Box mr={'10px'} mt={'11px'}>
            <img src={'/assets/img/plus_green_2.svg'} />
          </Box>
          Add pharmacy
        </Box>}
      </Box>
    </Collapse>
  </>
}

const useLineStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
  }
});

type PharmacyLineProps = {
  pharmacy: PharmacyLocationDistanceOutput,
  green?: boolean
}

const PharmacyLine = (props: PharmacyLineProps) => {
  const classes = useLineStyles(props);

  return <Box className={classes.root}>
    <Box>
      <Box mb={'10px'}>
        <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>{formatPharmacyName(props.pharmacy.name || '')}</Typography>
      </Box>
      <Typography variant={'body2'} color={'textSecondary'}>{props.pharmacy.address}</Typography>
    </Box>
  </Box>
}
