import React from "react";
import PhoneInput from "react-phone-input-2";
import { createStyles, makeStyles } from '@mui/styles';
import { Box, FormHelperText, Theme } from '@mui/material';
import { Check, WarningCircle } from '@phosphor-icons/react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .special-label': {
        fontSize: '16px',
        lineHeight: '19px',
        marginBottom: '8px',
        color: '#000',
        fontWeight: 600,
        fontFamily: 'Inter,Roboto,Helvetica Neue,sans-serif',
      },
      '& .invalid-number-message': {
        display: 'none'
      }
    },
    input: {
      padding: '16px 16px 15px!important',
      fontSize: '16px!important',
      lineHeight: '20px',
      fontFamily: 'Inter,Roboto,Helvetica Neue,sans-serif',
      fontWeight: 500,
      backgroundColor: 'white!important',
      border: '1px solid #0B741C!important',
      borderRadius: '8px',
      width: '100%',
      boxSizing: 'border-box',
      '&.invalid-number': {
        border: '1px solid #d32f2f!important',
        backgroundColor: 'white!important',
        outline: 'none'
      },
      '&.invalid-number:focus': {
        boxShadow: '0px 0px 4px 4px rgba(211, 44, 28, 0.25)',
      },
      '&::placeholder': {
        color: '#808080',
        opacity: 1,
        fontSize: '16px'
      },
      '&:not(.invalid-number):focus': {
        borderColor: `#1C434F!important`,
        borderWidth: '1px!important',
        boxShadow: '0px 0px 4px 4px rgba(28, 67, 79, 0.25)',
        color: '#000',
        outline: 'none'
      }
    },
    tick: {
      position: 'absolute',
      bottom: 35,
      right: 13,
    },
    cross: {
      position: 'absolute',
      bottom: 42,
      right: 13,
    }
  }),
);

interface PhoneNumberInputProps {
  name: string;
  onChange: (value: string) => void;
  isValid?: () => boolean;
  label: React.ReactNode,
  placeholder?: string,
  error?: string,
  defaultValue?: string | null,
}

export function V3PhoneNumberInput(props: PhoneNumberInputProps) {
  const classes = useStyles(props);
  const [value, setValue] = React.useState<string>(props.defaultValue || '');
  const [showTick, setShowTick] = React.useState(false);

  React.useEffect(() => {
    setShowTick(/\d{11}/.test(value.toString()))
    props.onChange('+' + value);
  }, [value]);

  return (
    <Box position={'relative'} width={'100%'}>
      {(showTick && props.isValid ? props.isValid() : showTick) && <Check className={classes.tick} size={24} color="#0B741C" weight="regular" />}
      {(props.isValid && !props.isValid()) && <WarningCircle className={classes.cross} size={24} color="#D32C1C" weight="regular" />}
      <PhoneInput
        specialLabel={props.label as string}
        country={'us'}
        onlyCountries={['us']}
        isValid={props.isValid}
        defaultMask={'(...) ...-....'}
        alwaysDefaultMask
        disableCountryCode={false}
        countryCodeEditable={false}
        placeholder={props.placeholder || 'Enter your phone number'}
        defaultErrorMessage={props.error || 'Please enter a valid phone'}
        containerClass={classes.root}
        inputClass={classes.input}
        value={value}
        onChange={setValue}
        inputProps={{ name: props.name, 'data-test': "phone_number" }}
      />
      <div className={'h-20'}>
        {(props.isValid && !props.isValid()) && <FormHelperText sx={{color: '#D32C1C', fontSize: 16, lineHeight: '19px', mt: '8px'}}>Please enter a valid phone</FormHelperText>}
      </div>
    </Box>
  );
}
