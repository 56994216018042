import { keycloakConfig } from './config';
// @ts-ignore
import Keycloak from 'keycloak-js'

export let keycloak = new Keycloak(keycloakConfig);

export const setKeycloakInstance = (instance: Keycloak) => keycloak = instance;

export enum KeycloakRoles {
  ROLE_PUBLIC_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_ALFA = 'ROLE_ALFA',
  ROLE_TECH_STAFF = 'ROLE_TECH_STAFF',
}
