import { Collapse, Grid, GridDirection, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { DateInput, SelectInput } from '@coverright/ui/inputs';
import React from 'react';
import { useFormik } from 'formik';
import { getStoredUser } from '@coverright/utils';
import * as yup from 'yup';
import * as _ from 'lodash';
import { ZipSelector } from '@coverright/features/shared';
import moment from 'moment';
import { Gender } from '@coverright/data-access/types/medigap';

interface CollectInfoFormProps {
  fields?: string[],
  onSubmit: (values: any) => void,
  onValidChange?: (value: boolean) => void,
  setFormik: any,
  direction?: 'row' | 'column'
}

const availableFields = ['zip', 'countyName', 'birthDate', 'gender', 'tobacco', 'enrolled']

const schemaFields: any = {
  zip: yup
    .string()
    .required('Zip is required'),
  countyName: yup
    .string()
    .required('County name is required'),
  birthDate: yup
    .string()
    .typeError('Birth date is required')
    .test('birthDate', 'Birth date is not valid', value => {
      const val = moment(value);
      return val.isValid() && val.isBetween(moment(new Date(1900, 0, 1)), moment())
    })
    .required('Birth date is required'),
  gender: yup
    .string()
    .typeError('Gender is required')
    .required('Gender is required'),
  enrolled: yup
    .string()
    .required('Medicare status is required'),
  tobacco: yup
    .string()
    .required('Tobacco status is required'),
}

const CollectInfoForm = (props: CollectInfoFormProps) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  const fields = React.useMemo(() => {
    if (props.fields) {
      return props.fields
    } else {
      let f = availableFields;
      if (f.includes('zip') && f.includes('countyName')) {
        f = _.without(f, 'countyName');
      } else if (!f.includes('zip') && f.includes('countyName')) {
        f = _.without(f, 'countyName');
        f = ['zip', ...f];
      }
      return f
    }
  }, [props.fields])

  const formik = useFormik({
    initialValues: _.fromPairs(fields.map(field => [field, ((getStoredUser() || {}) as any)[field] || ''])),
    validationSchema: yup.object(_.fromPairs(fields.map(field => [field, schemaFields[field]]))),
    onSubmit: props.onSubmit,
    validateOnChange: true
  });

  React.useEffect(() => {
    if (formik) {
      props.setFormik(formik)
    }
  }, [formik.handleSubmit])

  React.useEffect(() => {
    if (props.onValidChange) {
      props.onValidChange(formik.isValid)
    }
  }, [formik.isValid])

  return <form onSubmit={formik.handleSubmit} className={'fill-width'}>
    <Grid container spacing={2} direction={props.direction ? props.direction : phone ? 'column' : 'row'} sx={{mr: {xs: 1, md: 0}}}>
      {fields.map((field, i) => <Field key={field} open={i > 0 ? !!formik.values[fields[i - 1]] : true} name={field} formik={formik} direction={props.direction} />)}
    </Grid>
  </form>
}

export default CollectInfoForm;

const Field = ({name, formik, direction, open}: {name: string, open: boolean, formik: any, direction?: 'row' | 'column'}) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  let result: any;

  switch (name) {
    case 'gender': return <Grid item xs={12} md={6}>
      <Collapse in={true}>
        <SelectInput
          fullWidth
          data-test={'gender'}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          helperText={formik.touched.gender && formik.errors.gender || ''}
          label={'Gender'}
          value={formik.values.gender}
          placeholder={'Select gender'}
          onChange={(event) => {
            formik.setFieldValue('gender', event.target.value as string);
          }}
        >
          <MenuItem data-test={'gender-male'} value={Gender.M}>Male</MenuItem>
          <MenuItem data-test={'gender-female'} value={Gender.F}>Female</MenuItem>
        </SelectInput>
      </Collapse>
    </Grid>;
    case 'tobacco': return <Grid item xs={12} md={6}>
      <Collapse in={true}>
        <SelectInput
          fullWidth
          data-test={'tobacco'}
          error={formik.touched.tobacco && Boolean(formik.errors.tobacco)}
          helperText={formik.touched.tobacco && formik.errors.tobacco || ''}
          label={'Uses tobacco products?'}
          value={formik.values.tobacco}
          placeholder={'Select tobacco status'}
          onChange={(event) => {
            formik.setFieldValue('tobacco', event.target.value as string);
          }}
        >
          <MenuItem data-test={'tobacco-false'} value={'false'}>No</MenuItem>
          <MenuItem data-test={'tobacco-true'} value={'true'}>Yes</MenuItem>
        </SelectInput>
      </Collapse>
    </Grid>;
    case 'enrolled': return <Grid item xs={12} md={6}>
      <Collapse in={true}>
        <SelectInput
          fullWidth
          data-test={'enrolled'}
          error={formik.touched.enrolled && Boolean(formik.errors.enrolled)}
          helperText={formik.touched.enrolled && formik.errors.enrolled || ''}
          label={'Enrolled in Medicare A & B?'}
          value={formik.values.enrolled}
          placeholder={'Select Medicare status'}
          onChange={(event) => {
            formik.setFieldValue('enrolled', event.target.value as string);
          }}
        >
          <MenuItem data-test={'a+b'} value={'all'}>Part A + B</MenuItem>
          <MenuItem data-test={'a'} value={'a'}>Part A only</MenuItem>
          <MenuItem data-test={'not'} value={'not'}>Not enrolled</MenuItem>
        </SelectInput>
      </Collapse>
    </Grid>;
    case 'birthDate': return <Grid data-test={'dob'} item xs={12} md={6}>
      <Collapse in={true}>
        <DateInput
          inputProps={{
            name: "birthDate",
            label: "Date of birth",
            error: formik.touched.birthDate && Boolean(formik.errors.birthDate),
            helperText: formik.touched.birthDate && formik.errors.birthDate,
            placeholder: 'MM / DD / YYYY'
          }}
          value={formik.values.birthDate}
          onChange={value => {
            formik.setFieldValue('birthDate', value);
          }}
        />
      </Collapse>
    </Grid>;
    case 'countyName':
    case 'zip': return <Grid item xs={12}>
      <Collapse in={true}>
        <ZipSelector
          direction={direction ? direction : phone ? 'column' : 'row'}
          acceptAllZips
          zip={formik.values.zip}
          countyName={formik.values.countyName}
          onChange={data => formik.setValues({...formik.values, ...data})}
          errors={{
            zip: formik.touched.zip && formik.errors.zip,
            countyName: formik.touched.countyName && formik.errors.countyName,
          }} />
      </Collapse>
    </Grid>;
    default: return null;
  }
}
