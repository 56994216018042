import { Autocomplete, styled } from '@mui/material';

export const CRAutocomplete = styled(Autocomplete)({
  inputRoot: {
    padding: '0!important',
  },
  popupIndicator: {
    marginRight: 11
  },
  popper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    marginTop: 10,
  },
  paper: {
    boxShadow: 'none',
    borderRadius: 2,
    margin: 0
  },
  listbox: {

  },
  endAdornment: {
    display: 'none',
  },
  option: {
    padding: '18px 12px 18px 18px',
  }
})
