import { useScrollTrigger } from '@mui/material';
import * as React from 'react';

export const ElevationScroll = ({children}: any)  => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 3 : 1,
  });
}
