import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { enrollmentClient } from '@coverright/data-access/apollo-clients';

export function useMyClientId(options?: LazyQueryHookOptions<{myClientId: string}>) {
  return useLazyQuery<{myClientId: string}>(
    gql(query),
    {
      ...options,
      client: enrollmentClient
    }
  );
}

const query = `
{
  myClientId
}
`;
