import React from "react";
import { Box, Button, Typography } from '@mui/material';
import {MedicareDisclaimersModal} from './MedicareDisclaimersModal';
import {SxProps} from '@mui/material';
import { useMaPlansCount } from '@coverright/data-access/medicare';
import moment from 'moment';

function Func(props: {sx?: SxProps, hideMultiplan?: boolean, zip?: string, pdp?: boolean}) {
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const maZip = props.pdp ? undefined : props.zip;
  const pdpZip = props.pdp ? props.zip : undefined;
  const { maPlansCount, maOrgCount, pdpOrgCount, pdpPlansCount } = useMaPlansCount(maZip || pdpZip);

  return <Box sx={{maxWidth: 800, ...props.sx}} mt={'50px'}>
    <MedicareDisclaimersModal open={openDisclaimer} onClose={() => setOpenDisclaimer(false)}/>
    <Typography color={'textPrimary'} className={'bold pointer fs-14'}
                style={{textDecoration: 'underline'}} onClick={() => setOpenDisclaimer(true)}>
      View Medicare Disclaimers
    </Typography>
    <Box my={'10px'}>
      <Typography className={'mb-16 fs-12'} color={'textPrimary'}>
        If you need help, please call +1 (888) 969-7667 (TTY: 711) Mon-Fri 8am-10pm (ET), Sat-Sun 9am-7pm (ET) for licensed CoverRight sales agents who can assist with finding information on available Medicare Advantage, Medicare Supplement (Medigap) Insurance and Prescription Drug Plans.
      </Typography>
      <Typography className={'mb-16 fs-12'} color={'textPrimary'}>
        CoverRight Insurance Services Inc. represents Medicare Advantage [HMO,
        PPO and PFFS] organizations and stand-alone PDP prescription drug plans that have a Medicare contract.
        Enrollment depends on the plan’s contract renewal.
      </Typography>
      <Typography className={'fs-12'} color={'textPrimary'}>
        We do not offer every plan available in your area. {(!!maPlansCount || !!pdpPlansCount) && <>Currently we represent {props.pdp ? pdpOrgCount : maOrgCount} organizations which
      offer {props.pdp ? pdpPlansCount : maPlansCount} products in your area.</>} Please contact Medicare.gov, 1–800–MEDICARE, or your local State Health
        Insurance Program (SHIP) to get information on all of your options.
      </Typography>
    </Box>
    <Typography className={'fs-12'} color={'textPrimary'}>
      Last Updated Date: {moment().format("MM/DD/YYYY")}<br/>{!props.hideMultiplan && <>MULTIPLAN_COVERRIGHT_2024U_M<br/>MULTIPLAN_COVERRIGHT_2024_M</>}
    </Typography>
    <div className={'h-200'}/>
  </Box>
}

export const Footer = React.memo(Func)
