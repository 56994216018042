import * as React from 'react';
import { Backdrop, Box, Modal, ModalProps, Slide, Theme } from '@mui/material';
import { SxProps } from '@mui/material';

/* eslint-disable-next-line */
export interface CRModalProps extends ModalProps {
  width?: string | number,
  height?: string | number,
  disableSidePadding?: boolean,
  disableBackdropClick?: boolean,
  onExited?: () => void,
  paperPadding?: any,
  paperSx?: SxProps,
  modalScroll?: boolean;
}

export function CRModal(props: CRModalProps) {
  const {paperPadding, modalScroll, ...rest} = props

  return <Modal
    closeAfterTransition
    sx={{
      display: 'flex',
      alignItems: modalScroll ? 'flex-start' : 'center',
      justifyContent: 'center',
      overflowY: modalScroll ? 'auto' : undefined,
      paddingLeft: props.disableSidePadding ? 0 : 320,
      padding: {xs: '40px 10px!important', lg: undefined},
    }}
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
      sx: {
        background: 'rgba(0, 0, 0, 0.25)',
      }
    }}
    {...rest}
  >
    <Slide direction={props.open ? 'up' : 'down'} in={props.open} onExited={props.onExited}>
      <Box sx={{
        position: 'relative',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
        padding: typeof props.paperPadding === 'undefined' ? '50px 36px 40px' : props.paperPadding,
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: modalScroll ? undefined : '90vh',
        overflowY: 'auto',
        width: props.width,
        height: props.height,
        marginTop: modalScroll ? '50px' : undefined,
        marginBottom: modalScroll ? '50px' : undefined,
        ...props.paperSx
      }}>
        {props.children}
      </Box>
    </Slide>
  </Modal>
}
