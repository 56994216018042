import {useMutation, MutationHookOptions} from "@apollo/client";
import {gql} from "@apollo/client";
import { FetchResult } from '@apollo/client';
import {
  MedicareQuoteInput,
  MutationSaveMedicareQuoteArgs,
  SaveMedicareQuoteOutput
} from '@coverright/data-access/types/medicare';
import { client } from '@coverright/data-access/apollo-clients';

export function useSaveMedicareQuote(options?: MutationHookOptions<{saveMedicareQuote: SaveMedicareQuoteOutput}, MutationSaveMedicareQuoteArgs>) {
  return useMutation<{saveMedicareQuote: SaveMedicareQuoteOutput}, MutationSaveMedicareQuoteArgs>(
    gql(saveRequestMutation),
    options
  );
}

export const saveMedicareQuote = (data: MedicareQuoteInput): Promise<FetchResult<{ saveMedicareQuote: SaveMedicareQuoteOutput }>> =>
  client.mutate({
    mutation: gql(saveRequestMutation),
    variables: { data }
  });

const saveRequestMutation = `
mutation ($data: MedicareQuoteInput!) {
  saveMedicareQuote(data: $data) {
    maQuoteID
    clientId  
  }
}`;
