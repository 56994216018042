import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Upload: any;
};

export type Benefit = {
  __typename?: 'Benefit';
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BenefitDescription = {
  __typename?: 'BenefitDescription';
  benefit?: Maybe<BenefitEntityResponse>;
  carrier?: Maybe<CarrierEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  howToActivate?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BenefitDescriptionEntity = {
  __typename?: 'BenefitDescriptionEntity';
  attributes?: Maybe<BenefitDescription>;
  id?: Maybe<Scalars['ID']>;
};

export type BenefitDescriptionEntityResponse = {
  __typename?: 'BenefitDescriptionEntityResponse';
  data?: Maybe<BenefitDescriptionEntity>;
};

export type BenefitDescriptionEntityResponseCollection = {
  __typename?: 'BenefitDescriptionEntityResponseCollection';
  data: Array<BenefitDescriptionEntity>;
  meta: ResponseCollectionMeta;
};

export type BenefitDescriptionFiltersInput = {
  and?: Maybe<Array<Maybe<BenefitDescriptionFiltersInput>>>;
  benefit?: Maybe<BenefitFiltersInput>;
  carrier?: Maybe<CarrierFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  description?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<BenefitDescriptionFiltersInput>;
  or?: Maybe<Array<Maybe<BenefitDescriptionFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type BenefitDescriptionInput = {
  benefit?: Maybe<Scalars['ID']>;
  carrier?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type BenefitEntity = {
  __typename?: 'BenefitEntity';
  attributes?: Maybe<Benefit>;
  id?: Maybe<Scalars['ID']>;
};

export type BenefitEntityResponse = {
  __typename?: 'BenefitEntityResponse';
  data?: Maybe<BenefitEntity>;
};

export type BenefitEntityResponseCollection = {
  __typename?: 'BenefitEntityResponseCollection';
  data: Array<BenefitEntity>;
  meta: ResponseCollectionMeta;
};

export type BenefitFiltersInput = {
  and?: Maybe<Array<Maybe<BenefitFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<BenefitFiltersInput>;
  or?: Maybe<Array<Maybe<BenefitFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type BenefitInput = {
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type BooleanFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  between?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  contains?: Maybe<Scalars['Boolean']>;
  containsi?: Maybe<Scalars['Boolean']>;
  endsWith?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Boolean']>;
  eqi?: Maybe<Scalars['Boolean']>;
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  nei?: Maybe<Scalars['Boolean']>;
  not?: Maybe<BooleanFilterInput>;
  notContains?: Maybe<Scalars['Boolean']>;
  notContainsi?: Maybe<Scalars['Boolean']>;
  notIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  startsWith?: Maybe<Scalars['Boolean']>;
};

export type Carrier = {
  __typename?: 'Carrier';
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarrierEntity = {
  __typename?: 'CarrierEntity';
  attributes?: Maybe<Carrier>;
  id?: Maybe<Scalars['ID']>;
};

export type CarrierEntityResponse = {
  __typename?: 'CarrierEntityResponse';
  data?: Maybe<CarrierEntity>;
};

export type CarrierEntityResponseCollection = {
  __typename?: 'CarrierEntityResponseCollection';
  data: Array<CarrierEntity>;
  meta: ResponseCollectionMeta;
};

export type CarrierFiltersInput = {
  and?: Maybe<Array<Maybe<CarrierFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<CarrierFiltersInput>;
  or?: Maybe<Array<Maybe<CarrierFiltersInput>>>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type CarrierInput = {
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  between?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  contains?: Maybe<Scalars['DateTime']>;
  containsi?: Maybe<Scalars['DateTime']>;
  endsWith?: Maybe<Scalars['DateTime']>;
  eq?: Maybe<Scalars['DateTime']>;
  eqi?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  ne?: Maybe<Scalars['DateTime']>;
  nei?: Maybe<Scalars['DateTime']>;
  not?: Maybe<DateTimeFilterInput>;
  notContains?: Maybe<Scalars['DateTime']>;
  notContainsi?: Maybe<Scalars['DateTime']>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  startsWith?: Maybe<Scalars['DateTime']>;
};

export type FileInfoInput = {
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FloatFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Float']>>>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
  contains?: Maybe<Scalars['Float']>;
  containsi?: Maybe<Scalars['Float']>;
  endsWith?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  eqi?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  nei?: Maybe<Scalars['Float']>;
  not?: Maybe<FloatFilterInput>;
  notContains?: Maybe<Scalars['Float']>;
  notContainsi?: Maybe<Scalars['Float']>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['Float']>>>;
  startsWith?: Maybe<Scalars['Float']>;
};

export type GenericMorph = Benefit | BenefitDescription | Carrier | I18NLocale | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser;

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: Maybe<Array<Maybe<I18NLocaleFiltersInput>>>;
  code?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<I18NLocaleFiltersInput>;
  or?: Maybe<Array<Maybe<I18NLocaleFiltersInput>>>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['ID']>>>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contains?: Maybe<Scalars['ID']>;
  containsi?: Maybe<Scalars['ID']>;
  endsWith?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  eqi?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  nei?: Maybe<Scalars['ID']>;
  not?: Maybe<IdFilterInput>;
  notContains?: Maybe<Scalars['ID']>;
  notContainsi?: Maybe<Scalars['ID']>;
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['ID']>>>;
  startsWith?: Maybe<Scalars['ID']>;
};

export type IntFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Int']>>>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  contains?: Maybe<Scalars['Int']>;
  containsi?: Maybe<Scalars['Int']>;
  endsWith?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  eqi?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  nei?: Maybe<Scalars['Int']>;
  not?: Maybe<IntFilterInput>;
  notContains?: Maybe<Scalars['Int']>;
  notContainsi?: Maybe<Scalars['Int']>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['Int']>>>;
  startsWith?: Maybe<Scalars['Int']>;
};

export type JsonFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  between?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  contains?: Maybe<Scalars['JSON']>;
  containsi?: Maybe<Scalars['JSON']>;
  endsWith?: Maybe<Scalars['JSON']>;
  eq?: Maybe<Scalars['JSON']>;
  eqi?: Maybe<Scalars['JSON']>;
  gt?: Maybe<Scalars['JSON']>;
  gte?: Maybe<Scalars['JSON']>;
  in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  lt?: Maybe<Scalars['JSON']>;
  lte?: Maybe<Scalars['JSON']>;
  ne?: Maybe<Scalars['JSON']>;
  nei?: Maybe<Scalars['JSON']>;
  not?: Maybe<JsonFilterInput>;
  notContains?: Maybe<Scalars['JSON']>;
  notContainsi?: Maybe<Scalars['JSON']>;
  notIn?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  startsWith?: Maybe<Scalars['JSON']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createBenefit?: Maybe<BenefitEntityResponse>;
  createBenefitDescription?: Maybe<BenefitDescriptionEntityResponse>;
  createCarrier?: Maybe<CarrierEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteBenefit?: Maybe<BenefitEntityResponse>;
  deleteBenefitDescription?: Maybe<BenefitDescriptionEntityResponse>;
  deleteCarrier?: Maybe<CarrierEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateBenefit?: Maybe<BenefitEntityResponse>;
  updateBenefitDescription?: Maybe<BenefitDescriptionEntityResponse>;
  updateCarrier?: Maybe<CarrierEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationCreateBenefitArgs = {
  data: BenefitInput;
};


export type MutationCreateBenefitDescriptionArgs = {
  data: BenefitDescriptionInput;
};


export type MutationCreateCarrierArgs = {
  data: CarrierInput;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationDeleteBenefitArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBenefitDescriptionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCarrierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationMultipleUploadArgs = {
  field?: Maybe<Scalars['String']>;
  files: Array<Maybe<Scalars['Upload']>>;
  ref?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['ID']>;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationUpdateBenefitArgs = {
  data: BenefitInput;
  id: Scalars['ID'];
};


export type MutationUpdateBenefitDescriptionArgs = {
  data: BenefitDescriptionInput;
  id: Scalars['ID'];
};


export type MutationUpdateCarrierArgs = {
  data: CarrierInput;
  id: Scalars['ID'];
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: Maybe<FileInfoInput>;
};


export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  field?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: Maybe<FileInfoInput>;
  ref?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['ID']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationArg = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query';
  benefit?: Maybe<BenefitEntityResponse>;
  benefitDescription?: Maybe<BenefitDescriptionEntityResponse>;
  benefitDescriptions?: Maybe<BenefitDescriptionEntityResponseCollection>;
  benefits?: Maybe<BenefitEntityResponseCollection>;
  carrier?: Maybe<CarrierEntityResponse>;
  carriers?: Maybe<CarrierEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
};


export type QueryBenefitArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryBenefitDescriptionArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryBenefitDescriptionsArgs = {
  filters?: Maybe<BenefitDescriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryBenefitsArgs = {
  filters?: Maybe<BenefitFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCarrierArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCarriersArgs = {
  filters?: Maybe<CarrierFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryI18NLocaleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: Maybe<I18NLocaleFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUploadFileArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: Maybe<UploadFolderFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: Maybe<UsersPermissionsRoleFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type StringFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['String']>>>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  containsi?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  eqi?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  nei?: Maybe<Scalars['String']>;
  not?: Maybe<StringFilterInput>;
  notContains?: Maybe<Scalars['String']>;
  notContainsi?: Maybe<Scalars['String']>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  notNull?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<UploadFileFiltersInput>>>;
  caption?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  ext?: Maybe<StringFilterInput>;
  folder?: Maybe<UploadFolderFiltersInput>;
  folderPath?: Maybe<StringFilterInput>;
  formats?: Maybe<JsonFilterInput>;
  hash?: Maybe<StringFilterInput>;
  height?: Maybe<IntFilterInput>;
  id?: Maybe<IdFilterInput>;
  mime?: Maybe<StringFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<UploadFileFiltersInput>;
  or?: Maybe<Array<Maybe<UploadFileFiltersInput>>>;
  previewUrl?: Maybe<StringFilterInput>;
  provider?: Maybe<StringFilterInput>;
  provider_metadata?: Maybe<JsonFilterInput>;
  size?: Maybe<FloatFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  url?: Maybe<StringFilterInput>;
  width?: Maybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['ID']>;
  folderPath?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_metadata?: Maybe<Scalars['JSON']>;
  size?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String'];
  pathId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: Maybe<UploadFolderFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: Maybe<Array<Maybe<UploadFolderFiltersInput>>>;
  children?: Maybe<UploadFolderFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  files?: Maybe<UploadFileFiltersInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<UploadFolderFiltersInput>;
  or?: Maybe<Array<Maybe<UploadFolderFiltersInput>>>;
  parent?: Maybe<UploadFolderFiltersInput>;
  path?: Maybe<StringFilterInput>;
  pathId?: Maybe<IntFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: Maybe<Array<Maybe<Scalars['ID']>>>;
  files?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  pathId?: Maybe<Scalars['Int']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  id?: Maybe<IdFilterInput>;
  not?: Maybe<UsersPermissionsPermissionFiltersInput>;
  or?: Maybe<Array<Maybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: Maybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: Maybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: Maybe<Array<Maybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: Maybe<DateTimeFilterInput>;
  description?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  not?: Maybe<UsersPermissionsRoleFiltersInput>;
  or?: Maybe<Array<Maybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: Maybe<UsersPermissionsPermissionFiltersInput>;
  type?: Maybe<StringFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  users?: Maybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  type?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: Maybe<Array<Maybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: Maybe<BooleanFilterInput>;
  confirmationToken?: Maybe<StringFilterInput>;
  confirmed?: Maybe<BooleanFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  email?: Maybe<StringFilterInput>;
  id?: Maybe<IdFilterInput>;
  not?: Maybe<UsersPermissionsUserFiltersInput>;
  or?: Maybe<Array<Maybe<UsersPermissionsUserFiltersInput>>>;
  password?: Maybe<StringFilterInput>;
  provider?: Maybe<StringFilterInput>;
  resetPasswordToken?: Maybe<StringFilterInput>;
  role?: Maybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  username?: Maybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: Maybe<Scalars['Boolean']>;
  confirmationToken?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type BenefitDescriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type BenefitDescriptionsQuery = { __typename?: 'Query', benefitDescriptions?: { __typename?: 'BenefitDescriptionEntityResponseCollection', data: Array<{ __typename?: 'BenefitDescriptionEntity', id?: string | null | undefined, attributes?: { __typename?: 'BenefitDescription', description?: string | null | undefined, name: string, benefit?: { __typename?: 'BenefitEntityResponse', data?: { __typename?: 'BenefitEntity', attributes?: { __typename?: 'Benefit', name?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined, carrier?: { __typename?: 'CarrierEntityResponse', data?: { __typename?: 'CarrierEntity', attributes?: { __typename?: 'Carrier', name?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined } | null | undefined }> } | null | undefined };


export const BenefitDescriptionsDocument = gql`
    query benefitDescriptions {
  benefitDescriptions {
    data {
      id
      attributes {
        benefit {
          data {
            attributes {
              name
            }
          }
        }
        carrier {
          data {
            attributes {
              name
            }
          }
        }
        description
        name
      }
    }
  }
}
    `;

/**
 * __useBenefitDescriptionsQuery__
 *
 * To run a query within a React component, call `useBenefitDescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefitDescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefitDescriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBenefitDescriptionsQuery(baseOptions?: Apollo.QueryHookOptions<BenefitDescriptionsQuery, BenefitDescriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefitDescriptionsQuery, BenefitDescriptionsQueryVariables>(BenefitDescriptionsDocument, options);
      }
export function useBenefitDescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefitDescriptionsQuery, BenefitDescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefitDescriptionsQuery, BenefitDescriptionsQueryVariables>(BenefitDescriptionsDocument, options);
        }
export type BenefitDescriptionsQueryHookResult = ReturnType<typeof useBenefitDescriptionsQuery>;
export type BenefitDescriptionsLazyQueryHookResult = ReturnType<typeof useBenefitDescriptionsLazyQuery>;
export type BenefitDescriptionsQueryResult = Apollo.QueryResult<BenefitDescriptionsQuery, BenefitDescriptionsQueryVariables>;