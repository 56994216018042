import { HttpLink, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";

import { keycloak } from '@coverright/shared/keycloak';
import { errorTracker, graphQLErrorHandler } from './GraphQLErrorHandler';

const httpLink = new HttpLink({
  uri: process.env.NX_GRAPHQL,
  headers: {
    "content-type": "application/json",
    // version: config.buildVersion,
  }
});


export const getToken = () => {
  return new Promise<string | undefined>((resolve, reject) => {
    keycloak.updateToken(5)
      .then(() => {
        resolve(keycloak.token);
      })
      .catch((e: any) => {
        reject(e);
      })
  });
}

const authLink = setContext(async (_, { headers }: any) => {
  const token = await getToken().catch(e => {
    throw new Error('Update token failed');
  });
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    }
  };
});

const elink = onError((errResponse: ErrorResponse) => {
  try {
    getToken().then(token => errorTracker(errResponse, token)).catch(() => errorTracker(errResponse));
  } catch (e) {
    errorTracker(errResponse);
  }
  if (graphQLErrorHandler) {
    graphQLErrorHandler(errResponse);
  }
});

const link =
  from([
    authLink,
    elink,
    httpLink,
  ])
;

export const authClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});


export const getClient = (keycloak: any) => {
  const getToken = () => {
    return new Promise((resolve, reject) => {
      keycloak.updateToken(5)
        .then(() => {
          resolve(keycloak.token);
        })
        .catch((e: any) => {
          reject(e);
        })
    });
  }

  const authLink = setContext(async (_, { headers }: any) => {
    let token: string;
    try {
      token = await getToken() as string
    } catch (e) {
      token = '';
    }
    if (token) {
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
          ...headers,
        }
      };
    } else {
      return {
        headers
      };
    }
  });

  const link =
    from([
      authLink,
      elink,
      httpLink,
    ])
  ;

  return new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      addTypename: false
    })
  });
}

