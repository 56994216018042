import * as React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Theme,
  Typography,
  useTheme,
  useMediaQuery,
  styled,
  Divider
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { SelectInput, TextInput } from '@coverright/ui/inputs';
import { CRPreloader, Preloader, ScrollbarList, CRModal } from '@coverright/ui/shared';
import { formatPharmacyName, useDebouncedEffect } from '@coverright/utils';
import GoogleMap from './GoogleMap';
import { Bounds } from 'google-map-react';
import Marker from './Marker';
import {
  LocationOutput,
  PageablePharmacyLocationOutput, PharmacyLocationDistanceOutput,
  PharmacyWithinRectangleFilterInput
} from '@coverright/data-access/types/medicare';
import { ArrowLeft, Check, MagnifyingGlass, MapPin, PlusCircle, X } from '@phosphor-icons/react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '36px',
      marginBottom: 12,
      fontWeight: 500
    },
    icon: {
      position: 'absolute!important' as any,
      top: 0,
      right: 8
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
      flex: 1,
      '& > div:first-child > div > div': {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
      },
      height: 'inherit'
    },
    contentWrapper: {
      display: 'flex',
      flex: 2,
      flexDirection: 'column',
      padding: '24px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto'
      },
      [theme.breakpoints.down('md')]: {
        minWidth: 336
      },
      minWidth: 441,
      height: '100%'
    },
    centered: {
      textAlign: 'center'
    },
    sort: {
      padding: '4px 14px',
      fontSize: 14,
      color: '#1C434F',
      cursor: 'pointer',
      borderRadius: 4,
      background: '#F5F7F6'
    },
    sortActive: {
      color: 'white',
      background: '#1D9C80'
    },
    linkDisabled: {
      cursor: 'default',
      opacity: .5
    },
    searchAreaButton: {
      position: 'absolute!important' as any,
      left: 33,
      top: 26,
      zIndex: 1
    }
  })
);

type PharmaciesModalProps = {
  onClose: () => void,
  onSubmit: (values: PharmacyLocationDistanceOutput[]) => void,
  open: boolean,
  values?: PharmacyLocationDistanceOutput[],
  zip?: string
}

const LINE_HEIGHT = 81;

export function PharmaciesModal(props: PharmaciesModalProps) {
  const classes = useStyles();
  const [sort, setSort] = React.useState<'DISTANCE' | 'NAME'>('DISTANCE');
  const [address, setAddress] = React.useState('');
  const [filter, setFilter] = React.useState('');
  const [distance, setDistance] = React.useState<number>(5);
  const [scrollTo, setScrollTo] = React.useState<number>();
  const [page, setPage] = React.useState<number>(0);
  const [bounds, setBounds] = React.useState<Bounds>();
  const [selectedPharmacies, setSelectedPharmacies] = React.useState<string[]>([]);
  const distances = [1, 5, 10, 15].map(value => ({ value, label: `Within ${value} miles` }));
  const theme = useTheme();
  const hideMap = useMediaQuery(theme.breakpoints.down('sm'));
  const [pharmacies, setPharmacies] = React.useState<PharmacyLocationDistanceOutput[]>([]);
  const [showPaginator, setShowPaginator] = React.useState(true);

  const [getPharmacies, pharmaciesData] = useLazyQuery<IGetPharmaciesResponse>(gql(getPharmaciesQuery), {
    onCompleted: () => setShowPaginator(true)
  });
  const [getPharmaciesByRect, pharmaciesByRectData] =
    useLazyQuery<{
      findPharmaciesWithinRectangle: PageablePharmacyLocationOutput
    }, PharmacyWithinRectangleFilterInput>(gql(getPharmaciesByRectQuery), {
      onCompleted: () => setShowPaginator(false)
    });
  const [getZipLocation, { data: zipData }] = useLazyQuery<{ findZipLocation: LocationOutput }>(gql(zipQuery));

  const fallbackLocation = React.useMemo(() => {
    return zipData?.findZipLocation;
  }, [zipData]);

  React.useEffect(() => {
    setSelectedPharmacies(props.values?.map(p => p.npi) || []);
  }, [props.values]);

  React.useEffect(() => {
    if (props.zip) {
      getZipLocation({
        variables: {
          zip: props.zip
        }
      });
      setAddress(props.zip);
    }
  }, [props.zip]);

  React.useEffect(() => {
    if (props.open && address) {
      getPharmacies({
        variables: {
          address,
          distance,
          page,
          sort,
          name: filter || null
        }
      });
    }
  }, [page, sort, distance, props.open]);

  useDebouncedEffect(() => {
    if (props.open) {
      if (page > 0) {
        setPage(0);
      } else if (address?.length >= 5) {
        getPharmacies({
          variables: {
            address,
            distance,
            page,
            sort,
            name: filter
          }
        });
      }
    }
  }, 1000, [address, filter], 1);

  const toggleValue = (val: string) => {
    setSelectedPharmacies([val]);
  };

  React.useEffect(() => {
    if (pharmaciesData.data?.findPharmaciesByAddress?.data) {
      setPharmacies(pharmaciesData.data?.findPharmaciesByAddress?.data);
    }
  }, [pharmaciesData.data?.findPharmaciesByAddress?.data]);

  React.useEffect(() => {
    if (pharmaciesByRectData.data?.findPharmaciesWithinRectangle?.data) {
      setPharmacies(pharmaciesByRectData.data?.findPharmaciesWithinRectangle?.data);
    }
  }, [pharmaciesByRectData.data?.findPharmaciesWithinRectangle?.data]);

  const findText = React.useMemo(() => {
    if (pharmaciesData.data?.findPharmaciesByAddress?.totalElements) {
      return <Typography className={'fs-18'}>
        There are <Box component={'b'}
                       sx={{ color: '#1C434F' }}>{pharmaciesData.data?.findPharmaciesByAddress?.totalElements}</Box> pharmacies
        within <Box component={'b'} sx={{ color: '#1C434F' }}>{distance}</Box> miles of <Box component={'b'}
                                                                                             sx={{ color: '#1C434F' }}>{address || props.zip}</Box>.
      </Typography>;
    } else {
      return null;
    }
  }, [pharmaciesData.data?.findPharmaciesByAddress?.totalElements]);

  const onMarkerClick = (key: string) => {
    const index = pharmacies.findIndex((p: any) => p.npi === key);
    toggleValue(key);
    setScrollTo(LINE_HEIGHT * index);
  };

  const margins = React.useMemo(() => {
    return hideMap ? '100%' : 'calc(100% - 160px)';
  }, [hideMap]);

  const onAreaSearchClick = () => {
    if (bounds) {
      getPharmaciesByRect({
        variables: {
          rectangle: {
            firstPoint: {
              latitude: bounds.nw.lat,
              longitude: bounds.nw.lng
            },
            diagonalPoint: {
              latitude: bounds.se.lat,
              longitude: bounds.se.lng
            }
          },
          name: filter
        }
      });
      setBounds(undefined);
    }
  };

  return <CRModal
    width={margins}
    height={'100%'}
    disableSidePadding
    paperPadding={0}
    open={props.open}
    onClose={props.onClose}
    data-test={'pharmacies-modal'}
    BackdropProps={{
      sx: {
        background: 'rgba(0, 0, 0, 0.25)'
      }
    }}

  >
    <Box className={classes.wrapper}>
      {!hideMap && <Box display={'flex'} flex={3} position={'relative'}>
        {bounds && <RoundedButton variant={'rounded'} className={classes.searchAreaButton} onClick={onAreaSearchClick}>Search
          this area</RoundedButton>}
        {pharmaciesData.loading && <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
                                        sx={{ width: '100%' }}><CRPreloader/></Box>}
        {!pharmaciesData.loading && <GoogleMap selected={selectedPharmacies[0]}
                                               page={page}
                                               pharmacies={pharmacies}
                                               onChildClick={onMarkerClick}
                                               onChange={setBounds}
                                               fallbackLocation={fallbackLocation}/>}
      </Box>}
      <Box className={classes.contentWrapper}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography sx={{ fontFamily: 'Epilogue', fontWeight: 600 }} className={'fs-17 lh-17'}>Find
            Pharmacy</Typography>
          <IconButton sx={{ mt: '-12px', mr: '-12px' }} onClick={() => props.onClose()}>
            <X size={24} color="#000000" weight="regular"/>
          </IconButton>
        </Box>
        <Box sx={{ height: 1, mr: '-20px', ml: '-8px', mb: 3 }}>
          <ScrollbarList height={'100%'} scrollTo={scrollTo}>
            <Box sx={{ pl: 1 }} mr={{ 'xs': '3px', 'sm': '20px' }}>
              <TextInput fullWidth
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           setFilter(event.target.value as string);
                         }}
                         InputProps={{
                           startAdornment: <MagnifyingGlass size={16} color="#1C434F" weight="regular"/>,
                           classes: {
                             input: 'p-8'
                           }
                         }}
                         label={'Search by Pharmacy Name'}
                         value={filter}
                         inputLabelClass={'regular'}
                         placeholder={'Enter pharmacy name'}/>

              <Box sx={{
                mt: { xs: '-15px', md: '-20px' },
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { md: '12px' }
              }}>
                <TextInput fullWidth
                           value={address}
                           onChange={event => {
                             setAddress(event.target.value as string);
                           }}
                           InputProps={{
                             startAdornment: <MapPin size={16} color="#1C434F" weight="fill"/>,
                             classes: {
                               input: 'p-8'
                             }
                           }}
                           validator={v => (v as string).length === 5}
                           label={'Zip Code'}
                           inputLabelClass={'regular'}
                           placeholder={'Enter zip code'}/>
                <Box sx={{ mt: { xs: '-15px', md: 0 }, width: 1 }}>
                  <SelectInput placeholder={'Distance'}
                               defaultValue={5}
                               label={'Distance from zipcode'}
                               labelClassName={'regular'}
                               InputProps={{
                                 classes: {
                                   input: 'p-8'
                                 }
                               }}
                               onChange={event => {
                                 setDistance(event.target.value as number);
                               }}
                  >
                    {distances.map(d => <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>)}
                  </SelectInput>
                </Box>
              </Box>
              <Box sx={{ mt: '-4px', display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography className={'fs-12 lh-15 medium'}>Sort by:</Typography>
                <Button variant={sort === 'DISTANCE' ? 'contained' : 'outlined'} color={'primary'}
                        className={'fs-12 lh-15 medium pv-8 ph-16'}
                        sx={{ borderRadius: '16px' }}
                        onClick={() => setSort('DISTANCE')}
                >
                  Distance
                </Button>
                <Button variant={sort === 'NAME' ? 'contained' : 'outlined'} color={'primary'}
                        className={'fs-12 lh-15 medium pv-8 ph-16'}
                        sx={{ borderRadius: '16px' }}
                        onClick={() => setSort('NAME')}
                >
                  Name
                </Button>
              </Box>
              <Box sx={{ width: 1, mt: 4, mb: 3 }}>
                <Typography sx={{ fontFamily: 'Epilogue', fontWeight: 600, mb: '4px' }}>Results</Typography>
                {findText}
              </Box>
              {!!pharmacies.length && pharmacies.map((pharmacy: PharmacyLocationDistanceOutput, i: number) => <>
                <PharmacyLine index={page * 10 + i + 1}
                              key={pharmacy.npi}
                              onClick={toggleValue}
                              pharmacy={pharmacy}
                              checked={selectedPharmacies.includes(pharmacy.npi)}/>
                {i < pharmacies.length - 1 && <Divider sx={{ my: 2, borderColor: '#999999' }}/>}
              </>)}
              {pharmaciesData.called && !pharmaciesData.loading && !pharmacies.length &&
                <Typography color={'textPrimary'} className={'fs-14 medium mt-50'} align={'center'}>There are no
                  pharmacies available with your selected criteria. Please update and try again</Typography>}
              {pharmaciesData.loading && <Preloader/>}
              {(!pharmaciesData.loading && !!pharmacies.length && !!pharmaciesData?.data?.findPharmaciesByAddress && showPaginator) && (
                <Box mt={4}>
                  <Typography align={'center'}>
                    Page {pharmaciesData?.data?.findPharmaciesByAddress?.number + 1} of {pharmaciesData?.data?.findPharmaciesByAddress?.totalPages}
                  </Typography>
                  <Box mt={4} display={'flex'} justifyContent={'space-around'}>
                    <Typography onClick={() => {
                      if (pharmaciesData?.data?.findPharmaciesByAddress?.hasPrevious) {
                        setPage(prev => prev - 1);
                        setScrollTo(260 + Math.random());
                      }
                    }}
                                className={`
                                  ${pharmaciesData?.data?.findPharmaciesByAddress?.hasPrevious ? 'pointer' : ''}
                                  ${!pharmaciesData?.data?.findPharmaciesByAddress?.hasPrevious ? classes.linkDisabled : ''}
                                `}>
                      {'< Previous'}
                    </Typography>
                    <Box>
                      <Typography onClick={() => {
                        if (pharmaciesData?.data?.findPharmaciesByAddress?.hasNext) {
                          setPage(prev => prev + 1);
                          setScrollTo(260 + Math.random());
                        }
                      }}
                                  className={`
                                    ${pharmaciesData?.data?.findPharmaciesByAddress?.hasNext ? 'pointer' : ''}
                                    ${!pharmaciesData?.data?.findPharmaciesByAddress?.hasNext ? classes.linkDisabled : ''}
                                  `}>
                        {'Next >'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </ScrollbarList>
        </Box>
        <Button fullWidth disabled={!selectedPharmacies?.length} variant={'contained'}
                onClick={() => props.onSubmit(pharmacies.filter(p => selectedPharmacies.includes(p.npi)))}
        >Confirm Selection</Button>
      </Box>
    </Box>
  </CRModal>;
}

interface IGetPharmaciesResponse {
  findPharmaciesByAddress: PageablePharmacyLocationOutput;
}

const getPharmaciesQuery = `
query ($address: String!, $sort: SortType!, $name: String, $distance: Float!, $page: Int!) {
  findPharmaciesByAddress(filterInput: {address: $address, sort: $sort, name: $name, radius: $distance, lengthUnit: MILE}, page: {page: $page, size: 10}) {
     data {
      address
      distance
      latitude
      longitude
      name
      npi
      zip
    }
    totalPages
    number
    totalElements
    hasNext
    hasPrevious
  }
}
`;

const getPharmaciesByRectQuery = `
query ($name: String, $rectangle: RectangleInput!) {
  findPharmaciesWithinRectangle(filterInput: {name: $name, rectangle: $rectangle}, page: {page: 0, size: 1000}) {
     data {
      address
      distance
      latitude
      longitude
      name
      npi
      zip
    }
    totalPages
    number
    totalElements
    hasNext
    hasPrevious
  }
}
`;

const zipQuery = `
query ($zip: String!) {
  findZipLocation(zip: $zip) {
    latitude
    longitude
  }
}
`;
const useLineStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      padding: '16px 8px',
      cursor: 'pointer',
      '&.active': {
        background: '#F7F7F7',
        cursor: 'default',
        borderRadius: '8px'
      },
      '& .plus-icon': {
        display: 'none'
      },
      '&:not(.active):hover': {
        background: '#F7F7F7',
        borderRadius: '8px',
        '& .plus-icon': {
          display: 'flex'
        }
      }
    }
  })
);

type PharmacyLineProps = {
  pharmacy: PharmacyLocationDistanceOutput,
  index: number,
  checked: boolean,
  onClick: (id: string) => void
}

const PharmacyLine = (props: PharmacyLineProps) => {
  const classes = useLineStyles(props);

  return <Box className={`${classes.root} ${props.checked ? 'active' : ''}`}
              data-test={'pharmacy-modal-line'}
              onClick={() => props.onClick(props.pharmacy.npi)}>
    <Marker active={props.checked} index={props.index}/>
    <Box sx={{ ml: 2, mr: { md: 2 } }} flex={1}>
      <Typography variant={'body2'} className={'bold mb-8'}>{formatPharmacyName(props.pharmacy.name || '')}</Typography>
      <Typography sx={{ color: '#333333' }}>{props.pharmacy.address}</Typography>
    </Box>
    <Box sx={{ width: '32px', display: { xs: 'none', md: 'undefined' } }}>
      <PlusCircle className={'plus-icon'} size={32} color="#1C434F" weight="regular"/>
      {props.checked && <Check className={'check-icon'} size={32} color="#0B741C" weight="regular"/>}
    </Box>
  </Box>;
};

const RoundedButton = styled(Button)({
  backgroundColor: '#029094',
  fontWeight: 500,
  fontSize: 22,
  lineHeight: '27px',
  padding: '6px 18px'
});
