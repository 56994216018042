import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A type representing BigDecimal */
  BigDecimal: any;
  /** A type representing a formatted an instantaneous point on the timeline */
  Instant: any;
  /** A type representing local date */
  LocalDate: any;
  /** A type representing long */
  Long: any;
  /** A type representing a string identifier */
  UUID: any;
};

export enum AvailableStateDto {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export type BrandedInfo = {
  __typename?: 'BrandedInfo';
  name: Scalars['String'];
  rxcui: Scalars['String'];
};

export type BrokenAddress = {
  __typename?: 'BrokenAddress';
  address: Scalars['String'];
  geocodingType: GeocodingType;
};

export enum CalcDrugFrequencyPeriod {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY'
}

export type ComparablePlan = {
  __typename?: 'ComparablePlan';
  bidId: Scalars['String'];
  county: Scalars['String'];
  effectiveDate?: Maybe<Scalars['LocalDate']>;
  pdpPrimary?: Maybe<Scalars['Boolean']>;
  planYear?: Maybe<PlanYear>;
  type: ComparablePlanType;
  zip: Scalars['String'];
};

export type ComparablePlanInput = {
  bidId: Scalars['String'];
  county: Scalars['String'];
  effectiveDate?: Maybe<Scalars['LocalDate']>;
  pdpPrimary?: Maybe<Scalars['Boolean']>;
  planYear?: Maybe<PlanYear>;
  type: ComparablePlanType;
  zip: Scalars['String'];
};

export enum ComparablePlanType {
  Ma = 'MA',
  Mg = 'MG',
  Pdp = 'PDP'
}

export type CompareOffer = {
  __typename?: 'CompareOffer';
  clientId: Scalars['UUID'];
  id?: Maybe<Scalars['UUID']>;
  plans: Array<ComparablePlan>;
};

export type CompareOfferInput = {
  clientId: Scalars['UUID'];
  id?: Maybe<Scalars['UUID']>;
  plans: Array<ComparablePlanInput>;
};

export type CompareYearToYearInput = {
  clientId: Scalars['UUID'];
  county: Scalars['String'];
  currentMaPlanId?: Maybe<Scalars['String']>;
  currentMgNaic?: Maybe<Scalars['String']>;
  currentMgPlanKey?: Maybe<Scalars['String']>;
  currentMgPlanName?: Maybe<Scalars['String']>;
  currentPdpPlanId?: Maybe<Scalars['String']>;
  currentYear: PlanYear;
  id?: Maybe<Scalars['UUID']>;
  maIsHidden?: Maybe<Scalars['Boolean']>;
  mgIsHidden?: Maybe<Scalars['Boolean']>;
  pdpIsHidden?: Maybe<Scalars['Boolean']>;
  suggestedMaPlanId?: Maybe<Scalars['String']>;
  suggestedMgNaic?: Maybe<Scalars['String']>;
  suggestedMgPlanKey?: Maybe<Scalars['String']>;
  suggestedMgPlanName?: Maybe<Scalars['String']>;
  suggestedPdpPlanId?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type CompareYearToYearOutput = {
  __typename?: 'CompareYearToYearOutput';
  clientId: Scalars['UUID'];
  county?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Instant']>;
  currentMaPlanId?: Maybe<Scalars['String']>;
  currentMgNaic?: Maybe<Scalars['String']>;
  currentMgPlanKey?: Maybe<Scalars['String']>;
  currentMgPlanName?: Maybe<Scalars['String']>;
  currentPdpPlanId?: Maybe<Scalars['String']>;
  currentYear?: Maybe<PlanYear>;
  id: Scalars['UUID'];
  isLocked: Scalars['Boolean'];
  maIsHidden: Scalars['Boolean'];
  mgIsHidden: Scalars['Boolean'];
  pdpIsHidden: Scalars['Boolean'];
  suggestedMaPlanId?: Maybe<Scalars['String']>;
  suggestedMgNaic?: Maybe<Scalars['String']>;
  suggestedMgPlanKey?: Maybe<Scalars['String']>;
  suggestedMgPlanName?: Maybe<Scalars['String']>;
  suggestedPdpPlanId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Instant'];
  zip?: Maybe<Scalars['String']>;
};

export type CostPlusOfferOutput = {
  __typename?: 'CostPlusOfferOutput';
  quantity: Scalars['BigDecimal'];
  rxcui: Scalars['String'];
  unitPrice: Scalars['BigDecimal'];
  url: Scalars['String'];
};

export type CoverageEntryOutput = {
  __typename?: 'CoverageEntryOutput';
  month: Scalars['Int'];
  stage: DrugCalculationStage;
};

export type DentalBenefitDto = {
  __typename?: 'DentalBenefitDto';
  benefits: Array<Scalars['String']>;
  maxPlanCoverageAmount?: Maybe<Scalars['BigDecimal']>;
};

export type Doctor = {
  __typename?: 'Doctor';
  businessPracticeLocationFirstLine?: Maybe<Scalars['String']>;
  businessPracticeLocationSecondLine?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Long'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  officialFirstName?: Maybe<Scalars['String']>;
  officialLastName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type DoctorCoverage = {
  __typename?: 'DoctorCoverage';
  coverage?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  npi: Scalars['String'];
};

export type DoctorInput = {
  addresses: Array<DoctorInputAddressInput>;
  npi: Scalars['String'];
};

export type DoctorInputAddressInput = {
  addressId: Scalars['String'];
};

export enum DrugCalculationStage {
  Catastrophic = 'CATASTROPHIC',
  CoverageGap = 'COVERAGE_GAP',
  Deductible = 'DEDUCTIBLE',
  InitialCoverage = 'INITIAL_COVERAGE',
  NotCovered = 'NOT_COVERED'
}

export type DrugCostMonthlyBreakdownOutput = {
  __typename?: 'DrugCostMonthlyBreakdownOutput';
  costs: Array<DrugCostOutput>;
  coverageEntry: Array<CoverageEntryOutput>;
  monthlyCosts: Array<MonthlyTotalCost>;
  totalClientCost: Scalars['String'];
};

export type DrugCostOutput = {
  __typename?: 'DrugCostOutput';
  cost: Scalars['String'];
  isGeneric?: Maybe<Scalars['Boolean']>;
  month: Scalars['Int'];
  name: Scalars['String'];
  numericCost: Scalars['BigDecimal'];
  packageName: Scalars['String'];
  rxcui: Scalars['String'];
  stage: DrugCalculationStage;
};

export type DrugCoverage = {
  __typename?: 'DrugCoverage';
  coverage: Scalars['Boolean'];
  name: Scalars['String'];
  packName: Scalars['String'];
  packRxcui: Scalars['String'];
  rxcui?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
};

export enum DrugDeliveryMethod {
  PreferredMail = 'PREFERRED_MAIL',
  PreferredRetail = 'PREFERRED_RETAIL',
  StandardMail = 'STANDARD_MAIL',
  StandardRetail = 'STANDARD_RETAIL'
}

export enum DrugDeliveryTypeDto {
  Both = 'BOTH',
  Mail = 'MAIL',
  Pharmacy = 'PHARMACY'
}

export type DrugDiscount = {
  __typename?: 'DrugDiscount';
  carrierCost: Scalars['BigDecimal'];
  costPlusCost?: Maybe<Scalars['BigDecimal']>;
  costPlusDetails?: Maybe<CostPlusOfferOutput>;
  discount?: Maybe<Scalars['BigDecimal']>;
  discountBrand?: Maybe<DrugDiscountBrand>;
  goodRxCost?: Maybe<Scalars['BigDecimal']>;
  goodRxDetails?: Maybe<GoodRxOfferOutput>;
  normalizedQuantity?: Maybe<Scalars['BigDecimal']>;
  rxcui: Scalars['String'];
};

export enum DrugDiscountBrand {
  CostPlus = 'COST_PLUS',
  GoodRx = 'GOOD_RX'
}

export type DrugInfoWithGeneric = {
  __typename?: 'DrugInfoWithGeneric';
  genericName?: Maybe<Scalars['String']>;
  genericRxcui?: Maybe<Scalars['String']>;
  isGeneric?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  rxcui?: Maybe<Scalars['String']>;
};

export type DrugInfoWithPack = {
  __typename?: 'DrugInfoWithPack';
  genericName?: Maybe<Scalars['String']>;
  genericRxcui?: Maybe<Scalars['String']>;
  isGeneric?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  packName?: Maybe<Scalars['String']>;
  packRxcui?: Maybe<Scalars['String']>;
  rxcui?: Maybe<Scalars['String']>;
};

export type DrugInfoWithPackagesFlags = {
  __typename?: 'DrugInfoWithPackagesFlags';
  brandedDetails: Array<BrandedInfo>;
  genericName?: Maybe<Scalars['String']>;
  genericRxcui?: Maybe<Scalars['String']>;
  hasBrandedPackages: Scalars['Boolean'];
  hasGenericPackages: Scalars['Boolean'];
  isGeneric: Scalars['Boolean'];
  name: Scalars['String'];
  rxcui: Scalars['String'];
};

export type DrugOutput = {
  __typename?: 'DrugOutput';
  frequency: CalcDrugFrequencyPeriod;
  genericName?: Maybe<Scalars['String']>;
  genericRxcui?: Maybe<Scalars['String']>;
  isGeneric: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  packName?: Maybe<Scalars['String']>;
  packRxcui: Scalars['String'];
  purchaseFrequency: FrequencyPeriod;
  quantity: Scalars['BigDecimal'];
  rxcui: Scalars['String'];
};

export type DrugPackageOutput = {
  __typename?: 'DrugPackageOutput';
  packageDescription: Scalars['String'];
  unitType: Scalars['String'];
};

export type DrugPriceOutput = {
  __typename?: 'DrugPriceOutput';
  catastrophicPrice: Scalars['String'];
  coverageGapPrice: Scalars['String'];
  initialCoveragePrice: Scalars['String'];
  isAggregatedPrice: Scalars['Boolean'];
  isCoveredByPlan: Scalars['Boolean'];
  isGeneric: Scalars['Boolean'];
  isInsulinDrug: Scalars['Boolean'];
  name: Scalars['String'];
  packageDescription: Scalars['String'];
  price: Scalars['String'];
  quantityLimit?: Maybe<Scalars['String']>;
  quoteQuantity: Scalars['String'];
  rxcui: Scalars['String'];
  tier?: Maybe<Scalars['Int']>;
  unitPrice: Scalars['String'];
};

export type DrugProductDto = {
  __typename?: 'DrugProductDto';
  activeIngredUnit?: Maybe<Scalars['String']>;
  activeNumeratorStrength?: Maybe<Scalars['String']>;
  applicationNumber?: Maybe<Scalars['String']>;
  deadSchedule?: Maybe<Scalars['String']>;
  dosageFormName?: Maybe<Scalars['String']>;
  endMarketingDate?: Maybe<Scalars['String']>;
  id: Scalars['Long'];
  isGeneric: Scalars['Boolean'];
  labelName?: Maybe<Scalars['String']>;
  listingRecordCertifiedThrough?: Maybe<Scalars['String']>;
  marketingCategoryName?: Maybe<Scalars['String']>;
  ndcExcludeFlag?: Maybe<Scalars['String']>;
  nonProprietaryName?: Maybe<Scalars['String']>;
  pharmClasses?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  productNdc?: Maybe<Scalars['String']>;
  productTypeName?: Maybe<Scalars['String']>;
  proprietaryName?: Maybe<Scalars['String']>;
  proprietaryNameSuffix?: Maybe<Scalars['String']>;
  routeName?: Maybe<Scalars['String']>;
  startMarketingDate?: Maybe<Scalars['String']>;
  substanceName?: Maybe<Scalars['String']>;
};

export enum DrugSupplyDuration {
  OneMonth = 'ONE_MONTH',
  ThreeMonths = 'THREE_MONTHS',
  TwoMonths = 'TWO_MONTHS'
}

export type DrugTier = {
  __typename?: 'DrugTier';
  title: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type DrugTierCoverageOutput = {
  __typename?: 'DrugTierCoverageOutput';
  coverage: Scalars['String'];
  tier: DrugTierOutput;
  tierName: Scalars['String'];
};

export enum DrugTierOutput {
  Generic = 'GENERIC',
  NonPreferredBrand = 'NON_PREFERRED_BRAND',
  PreferredBrand = 'PREFERRED_BRAND',
  PreferredGeneric = 'PREFERRED_GENERIC',
  SelectCareDrugs = 'SELECT_CARE_DRUGS',
  Specialty = 'SPECIALTY'
}

export type DrugTiersOutput = {
  __typename?: 'DrugTiersOutput';
  catastrophicCoverageDrugTiers: Array<DrugTierCoverageOutput>;
  coverageGapDrugTiers: Array<DrugTierCoverageOutput>;
  initialCoverageDrugTiers: Array<DrugTierCoverageOutput>;
};

export type DrugsDetails = {
  __typename?: 'DrugsDetails';
  catastrophicCopayBranded: Scalars['String'];
  catastrophicCopayGeneric: Scalars['String'];
  catastrophicCoverage: Array<PharmacyTier>;
  catastrophicCoverageDescription: Scalars['String'];
  catastrophicThreshold: Scalars['String'];
  coverageGap: Array<PharmacyTier>;
  coverageGapDescription: Scalars['String'];
  initialCoverage: Array<PharmacyTier>;
  initialCoverageDescription: Scalars['String'];
  initialCoverageLimit: Scalars['String'];
  mrxAltDedAmount: Scalars['String'];
  mrxAltDedAmountShort: Scalars['String'];
  mrxAltNoDedTier: Scalars['String'];
};

export type EnabledPbpContractInput = {
  company: Scalars['String'];
  contract: Scalars['String'];
  enabled: Scalars['Boolean'];
  parentCompany: Scalars['String'];
};

export type EnabledPbpContractOutput = {
  __typename?: 'EnabledPbpContractOutput';
  company: Scalars['String'];
  contract: Scalars['String'];
  enabled: Scalars['Boolean'];
  parentCompany: Scalars['String'];
};

export type EnabledPbpStatesOutput = {
  __typename?: 'EnabledPbpStatesOutput';
  enabled: Scalars['Boolean'];
  state: AvailableStateDto;
};

export type EnabledPdpContractInput = {
  company: Scalars['String'];
  contract: Scalars['String'];
  enabled: Scalars['Boolean'];
  parentCompany: Scalars['String'];
};

export type EnabledPdpContractOutput = {
  __typename?: 'EnabledPdpContractOutput';
  company: Scalars['String'];
  contract: Scalars['String'];
  enabled: Scalars['Boolean'];
  parentCompany: Scalars['String'];
};

export type EnabledPdpStatesOutput = {
  __typename?: 'EnabledPdpStatesOutput';
  enabled: Scalars['Boolean'];
  state: AvailableStateDto;
};

export enum Event {
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  RefreshTokenError = 'REFRESH_TOKEN_ERROR'
}

export type ExternalId = {
  __typename?: 'ExternalId';
  type: Scalars['String'];
  value: Scalars['String'];
};

export enum ExtraBenefit {
  Dental = 'DENTAL',
  DrugCoverage = 'DRUG_COVERAGE',
  Fitness = 'FITNESS',
  Hearing = 'HEARING',
  InsulinSavings = 'INSULIN_SAVINGS',
  OverTheCounter = 'OVER_THE_COUNTER',
  PartBPremiumGiveback = 'PART_B_PREMIUM_GIVEBACK',
  Telehealth = 'TELEHEALTH',
  Transportation = 'TRANSPORTATION',
  Vision = 'VISION',
  WorldwideEmergency = 'WORLDWIDE_EMERGENCY'
}

export type ExtraBenefits = {
  __typename?: 'ExtraBenefits';
  dental: Scalars['Boolean'];
  drugCoverage: Scalars['Boolean'];
  fitness: Scalars['Boolean'];
  hearing: Scalars['Boolean'];
  insulinSavings: Scalars['Boolean'];
  overTheCounter: Scalars['Boolean'];
  telehealth: Scalars['Boolean'];
  transportation: Scalars['Boolean'];
  vision: Scalars['Boolean'];
  worldwideEmergency: Scalars['Boolean'];
};

export type ExtraBenefitsWithMaxBenefitAmountOutput = {
  __typename?: 'ExtraBenefitsWithMaxBenefitAmountOutput';
  dental: Scalars['String'];
  fitness: Scalars['String'];
  hearing: Scalars['String'];
  insulinSavings: Scalars['String'];
  overTheCounter: Scalars['String'];
  partB: Scalars['String'];
  telehealth: Scalars['String'];
  transportation: Scalars['String'];
  vision: Scalars['String'];
};

export enum Field {
  Deductible = 'DEDUCTIBLE',
  DoctorsCoverage = 'DOCTORS_COVERAGE',
  MaxOutOfPocket = 'MAX_OUT_OF_POCKET',
  MonthlyCost = 'MONTHLY_COST',
  Name = 'NAME',
  Premium = 'PREMIUM',
  Rating = 'RATING'
}

export type FilterItem = {
  __typename?: 'FilterItem';
  count: Scalars['Int'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export type FiltersDto = {
  __typename?: 'FiltersDto';
  SNPTypes?: Maybe<Array<SnpType>>;
  companies?: Maybe<Array<Scalars['String']>>;
  extraBenefits: Array<MedicareQuoteFilterExtraBenefit>;
  maxCostRangeNames?: Maybe<Array<Scalars['String']>>;
  maxOutOfPocketRangeNames?: Maybe<Array<Scalars['String']>>;
  partBPremiumReduction?: Maybe<Scalars['Boolean']>;
  partDDrugCoverage?: Maybe<Scalars['Boolean']>;
  planTypes?: Maybe<Array<Scalars['String']>>;
  rating?: Maybe<Array<Rating>>;
  showTopDoctors?: Maybe<Scalars['Boolean']>;
  showTopDrugs?: Maybe<Scalars['Boolean']>;
};

export type FiltersDtoInput = {
  SNPTypes?: Maybe<Array<SnpType>>;
  companies?: Maybe<Array<Scalars['String']>>;
  extraBenefits: Array<MedicareQuoteFilterExtraBenefit>;
  maxCostRangeNames?: Maybe<Array<Scalars['String']>>;
  maxOutOfPocketRangeNames?: Maybe<Array<Scalars['String']>>;
  partBPremiumReduction?: Maybe<Scalars['Boolean']>;
  partDDrugCoverage?: Maybe<Scalars['Boolean']>;
  planTypes?: Maybe<Array<Scalars['String']>>;
  rating?: Maybe<Array<Rating>>;
  showTopDoctors?: Maybe<Scalars['Boolean']>;
  showTopDrugs?: Maybe<Scalars['Boolean']>;
};

export enum FormName {
  BasicInfo = 'BASIC_INFO',
  Calendar = 'CALENDAR',
  ChronicIllness = 'CHRONIC_ILLNESS',
  Doctors = 'DOCTORS',
  Drugs = 'DRUGS',
  Enrolled = 'ENROLLED',
  GovernmentAssistant = 'GOVERNMENT_ASSISTANT',
  HomeAddress = 'HOME_ADDRESS',
  Medicaid = 'MEDICAID',
  MedicareOverview = 'MEDICARE_OVERVIEW',
  PersonalDetails = 'PERSONAL_DETAILS',
  Pharmacies = 'PHARMACIES',
  PhoneNumber = 'PHONE_NUMBER',
  ReceivingBenefits = 'RECEIVING_BENEFITS',
  SearchResults = 'SEARCH_RESULTS',
  WorkInfo = 'WORK_INFO'
}

export enum FrequencyPeriod {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export enum GeocodingType {
  Google = 'GOOGLE'
}

export type GoodRxOfferOutput = {
  __typename?: 'GoodRxOfferOutput';
  dosage: Scalars['String'];
  form: Scalars['String'];
  mobileUrl: Scalars['String'];
  price?: Maybe<Scalars['String']>;
  quantity: Scalars['Long'];
  rxcui: Scalars['String'];
  url: Scalars['String'];
};

export type HearingBenefitDto = {
  __typename?: 'HearingBenefitDto';
  benefits: Array<Scalars['String']>;
  coverageAmountForEars?: Maybe<Scalars['BigDecimal']>;
};

export type HospitalityDetails = {
  __typename?: 'HospitalityDetails';
  ambulanceServices: Array<Scalars['String']>;
  emergencyRoom: Scalars['String'];
  urgentServices: Scalars['String'];
};

export enum LengthUnit {
  Kilometer = 'KILOMETER',
  Mile = 'MILE'
}

export type LocationOutput = {
  __typename?: 'LocationOutput';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ManuallyUploadedPlanFileOutput = {
  __typename?: 'ManuallyUploadedPlanFileOutput';
  bidId: Scalars['String'];
  evidenceOfCoverageDocUrl?: Maybe<Scalars['String']>;
  nondiscriminationNoticeDocUrl?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  parentOrgName?: Maybe<Scalars['String']>;
  planName?: Maybe<Scalars['String']>;
  planYear: PlanYear;
  preEnrollmentChecklistDocUrl?: Maybe<Scalars['String']>;
  starRatingDescDocUrl?: Maybe<Scalars['String']>;
  summaryOfBenefitsUrl?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export enum MedicarePlanType {
  Hmo = 'HMO',
  MmpHmo = 'MMP_HMO',
  Ppo = 'PPO'
}

export type MedicarePlansAggregationByCompanyOutput = {
  __typename?: 'MedicarePlansAggregationByCompanyOutput';
  averageRating?: Maybe<Scalars['BigDecimal']>;
  maxOutOfPocketInNetworkMax?: Maybe<Scalars['BigDecimal']>;
  maxOutOfPocketInNetworkMin?: Maybe<Scalars['BigDecimal']>;
  maxOutOfPocketOutOfNetworkMax?: Maybe<Scalars['BigDecimal']>;
  maxOutOfPocketOutOfNetworkMin?: Maybe<Scalars['BigDecimal']>;
  monthlyPremiumMax?: Maybe<Scalars['BigDecimal']>;
  monthlyPremiumMin?: Maybe<Scalars['BigDecimal']>;
  orgName: Scalars['String'];
  parentOrgName: Scalars['String'];
  plansCount: Scalars['Int'];
  primaryCareVisitsInNetworkMax?: Maybe<Scalars['BigDecimal']>;
  primaryCareVisitsInNetworkMin?: Maybe<Scalars['BigDecimal']>;
  primaryCareVisitsOutOfNetworkMax?: Maybe<Scalars['BigDecimal']>;
  primaryCareVisitsOutOfNetworkMin?: Maybe<Scalars['BigDecimal']>;
};

export type MedicarePlansAggregationOutput = {
  __typename?: 'MedicarePlansAggregationOutput';
  deductibleMax?: Maybe<Scalars['String']>;
  deductibleMin?: Maybe<Scalars['String']>;
  doctorInNetworkMax?: Maybe<Scalars['String']>;
  doctorInNetworkMin?: Maybe<Scalars['String']>;
  doctorMax?: Maybe<Scalars['String']>;
  doctorMin?: Maybe<Scalars['String']>;
  doctorOutOfNetworkMax?: Maybe<Scalars['String']>;
  doctorOutOfNetworkMin?: Maybe<Scalars['String']>;
  dsnpPlansExist: Scalars['Boolean'];
  longTermHospitalStayMax?: Maybe<Scalars['String']>;
  longTermHospitalStayMaxDays?: Maybe<Scalars['Int']>;
  longTermHospitalStayMin?: Maybe<Scalars['String']>;
  longTermHospitalStayMinDays?: Maybe<Scalars['Int']>;
  maxOutOfPocketInNetworkMax?: Maybe<Scalars['String']>;
  maxOutOfPocketInNetworkMin?: Maybe<Scalars['String']>;
  maxOutOfPocketOutOfNetworkMax?: Maybe<Scalars['String']>;
  maxOutOfPocketOutOfNetworkMin?: Maybe<Scalars['String']>;
  mmpHmoPlansExist: Scalars['Boolean'];
  monthlyPremiumMax?: Maybe<Scalars['String']>;
  monthlyPremiumMin?: Maybe<Scalars['String']>;
  shortTermHospitalStayMax?: Maybe<Scalars['String']>;
  shortTermHospitalStayMaxDays?: Maybe<Scalars['Int']>;
  shortTermHospitalStayMin?: Maybe<Scalars['String']>;
  shortTermHospitalStayMinDays?: Maybe<Scalars['Int']>;
  tooFewMPDPPlans: Scalars['Boolean'];
  tooFewPPOPlans: Scalars['Boolean'];
};

export type MedicareQuoteAuditLogOutput = {
  __typename?: 'MedicareQuoteAuditLogOutput';
  cId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Instant']>;
  form?: Maybe<FormName>;
  id: Scalars['UUID'];
};

export enum MedicareQuoteFilterExtraBenefit {
  Dental = 'DENTAL',
  DrugCoverage = 'DRUG_COVERAGE',
  Fitness = 'FITNESS',
  Hearing = 'HEARING',
  InsulinSavings = 'INSULIN_SAVINGS',
  OverTheCounter = 'OVER_THE_COUNTER',
  PartBPremiumGiveback = 'PART_B_PREMIUM_GIVEBACK',
  Telehealth = 'TELEHEALTH',
  Transportation = 'TRANSPORTATION',
  Vision = 'VISION',
  WorldwideEmergency = 'WORLDWIDE_EMERGENCY'
}

export type MedicareQuoteFilterInput = {
  endDate?: Maybe<Scalars['Instant']>;
  startDate?: Maybe<Scalars['Instant']>;
  statuses?: Maybe<Array<RequestStatusDto>>;
};

export type MedicareQuoteInput = {
  abCase?: Maybe<Scalars['String']>;
  cId?: Maybe<Scalars['String']>;
  chronicIllness?: Maybe<Scalars['Boolean']>;
  county?: Maybe<Scalars['String']>;
  coverage?: Maybe<Array<Scalars['String']>>;
  coverageFilled?: Maybe<Scalars['Boolean']>;
  customAnswers?: Maybe<Scalars['String']>;
  doctorVisitsNumber?: Maybe<Scalars['Int']>;
  doctorsWithinNetwork?: Maybe<Scalars['Boolean']>;
  employed?: Maybe<Scalars['Boolean']>;
  enrolled?: Maybe<Array<Scalars['String']>>;
  enrolledFilled?: Maybe<Scalars['Boolean']>;
  favorites?: Maybe<Array<Scalars['String']>>;
  filters?: Maybe<FiltersDtoInput>;
  form?: Maybe<FormName>;
  governmentAssistant?: Maybe<Array<Scalars['String']>>;
  governmentAssistantFilled?: Maybe<Scalars['Boolean']>;
  hasMore20?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  medicarePlanType?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  planYear?: Maybe<PlanYear>;
  preferredDoctors?: Maybe<Array<DoctorInput>>;
  preferredDoctorsFilled?: Maybe<Scalars['Boolean']>;
  prepayCoverage?: Maybe<Scalars['Boolean']>;
  receivedBenefits?: Maybe<Scalars['Boolean']>;
  receivesLIS?: Maybe<Scalars['Boolean']>;
  receivesMedicalSupport?: Maybe<Scalars['Boolean']>;
  restrictProviderNetwork?: Maybe<Scalars['Boolean']>;
  specialistVisitsNumber?: Maybe<Scalars['Int']>;
  spouseEmployed?: Maybe<Scalars['Boolean']>;
  spouseHasMore20?: Maybe<Scalars['Boolean']>;
  status?: Maybe<RequestStatusDto>;
  travelMoreThan30DaysAYear?: Maybe<Scalars['Boolean']>;
  work8years?: Maybe<Scalars['Boolean']>;
  zip?: Maybe<Scalars['String']>;
};

export type MedicareQuoteOutput = {
  __typename?: 'MedicareQuoteOutput';
  abCase?: Maybe<Scalars['String']>;
  agentOffer?: Maybe<Scalars['Boolean']>;
  cId?: Maybe<Scalars['String']>;
  chronicIllness?: Maybe<Scalars['Boolean']>;
  clientId: Scalars['UUID'];
  county?: Maybe<Scalars['String']>;
  coverage?: Maybe<Array<Scalars['String']>>;
  coverageFilled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Instant']>;
  customAnswers?: Maybe<Scalars['String']>;
  doctorVisitsNumber?: Maybe<Scalars['Int']>;
  doctorsWithinNetwork?: Maybe<Scalars['Boolean']>;
  drugDeliveryType?: Maybe<DrugDeliveryTypeDto>;
  employed?: Maybe<Scalars['Boolean']>;
  enrolled?: Maybe<Array<Scalars['String']>>;
  enrolledFilled?: Maybe<Scalars['Boolean']>;
  favorites: Array<Scalars['String']>;
  filters?: Maybe<FiltersDto>;
  governmentAssistant?: Maybe<Array<Scalars['String']>>;
  governmentAssistantFilled?: Maybe<Scalars['Boolean']>;
  hasMore20?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  medicarePlanType?: Maybe<Scalars['String']>;
  pin: Scalars['String'];
  planYear?: Maybe<PlanYear>;
  preferredDoctors: Array<ProviderOutput>;
  preferredDoctorsFilled?: Maybe<Scalars['Boolean']>;
  preferredDrugs: Array<DrugOutput>;
  preferredDrugsFilled?: Maybe<Scalars['Boolean']>;
  preferredPharmacies: Array<PharmacyLocationDistanceOutput>;
  preferredPharmaciesFilled?: Maybe<Scalars['Boolean']>;
  prepayCoverage?: Maybe<Scalars['Boolean']>;
  receivedBenefits?: Maybe<Scalars['Boolean']>;
  receivesLIS?: Maybe<Scalars['Boolean']>;
  receivesMedicalSupport?: Maybe<Scalars['Boolean']>;
  restrictProviderNetwork?: Maybe<Scalars['Boolean']>;
  specialistVisitsNumber?: Maybe<Scalars['Int']>;
  spouseEmployed?: Maybe<Scalars['Boolean']>;
  spouseHasMore20?: Maybe<Scalars['Boolean']>;
  status?: Maybe<RequestStatusDto>;
  travelMoreThan30DaysAYear?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['UUID']>;
  work8years?: Maybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};

export type MedicareTypeCompareInput = {
  certainDoctors?: Maybe<Scalars['Boolean']>;
  drugsCoverage?: Maybe<Scalars['Boolean']>;
  extraBenefits?: Maybe<Scalars['Boolean']>;
  monthlyBudget?: Maybe<Scalars['Boolean']>;
  prepay?: Maybe<Scalars['Boolean']>;
  receiveMedicaid?: Maybe<Scalars['Boolean']>;
};

export enum MedigapDrugDeliveryType {
  Both = 'BOTH',
  Mail = 'MAIL',
  Pharmacy = 'PHARMACY'
}

export enum MedigapFrequencyPeriodOutput {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export type MedigapPreferredDrugOutput = {
  __typename?: 'MedigapPreferredDrugOutput';
  dosageRxcui: Scalars['String'];
  frequency: CalcDrugFrequencyPeriod;
  productRxcui: Scalars['String'];
  purchaseFrequency: MedigapFrequencyPeriodOutput;
  quantity: Scalars['BigDecimal'];
};

export type MonthlyTotalCost = {
  __typename?: 'MonthlyTotalCost';
  amount: Scalars['BigDecimal'];
  amountString: Scalars['String'];
  month: Scalars['Int'];
  stage: DrugCalculationStage;
};

export type Mutation = {
  __typename?: 'Mutation';
  createMedicareQuoteForClient: SaveMedicareQuoteOutput;
  createMedicareQuoteFromMedigapQuote: SaveMedicareQuoteOutput;
  createPdpQuoteForClient: PdpQuoteOutput;
  generatePlanEmail?: Maybe<Scalars['String']>;
  refreshPlans: Scalars['Boolean'];
  saveCompareOffer: Scalars['String'];
  saveCompareYearToYear: Scalars['Boolean'];
  saveEnabledPbpContract: Scalars['Boolean'];
  saveEnabledPbpState: Scalars['Boolean'];
  saveEnabledPdpContract: Scalars['Boolean'];
  saveEnabledPdpState: Scalars['Boolean'];
  saveMedicareQuote: SaveMedicareQuoteOutput;
  savePdpQuote: PdpQuoteOutput;
  sendMedicareTypeCompareEmail: Scalars['String'];
  sendRequestFormReceivedEmail: Scalars['String'];
  setCompareYearToYearLocked: Scalars['Boolean'];
  warmUpPharmaciesGeocodingCache?: Maybe<Scalars['String']>;
};


export type MutationCreateMedicareQuoteForClientArgs = {
  agentOffer?: Maybe<Scalars['Boolean']>;
  clientId: Scalars['UUID'];
  data: MedicareQuoteInput;
};


export type MutationCreateMedicareQuoteFromMedigapQuoteArgs = {
  medigapQuoteId: Scalars['UUID'];
};


export type MutationCreatePdpQuoteForClientArgs = {
  clientId: Scalars['UUID'];
  input: PdpQuoteInput;
  userId?: Maybe<Scalars['UUID']>;
};


export type MutationGeneratePlanEmailArgs = {
  input: PlansCompareInput;
};


export type MutationRefreshPlansArgs = {
  clientId: Scalars['UUID'];
};


export type MutationSaveCompareOfferArgs = {
  compareOffer: CompareOfferInput;
};


export type MutationSaveCompareYearToYearArgs = {
  input: CompareYearToYearInput;
};


export type MutationSaveEnabledPbpContractArgs = {
  input: EnabledPbpContractInput;
};


export type MutationSaveEnabledPbpStateArgs = {
  enabled: Scalars['Boolean'];
  state: AvailableStateDto;
};


export type MutationSaveEnabledPdpContractArgs = {
  input: EnabledPdpContractInput;
};


export type MutationSaveEnabledPdpStateArgs = {
  enabled: Scalars['Boolean'];
  state: AvailableStateDto;
};


export type MutationSaveMedicareQuoteArgs = {
  data: MedicareQuoteInput;
};


export type MutationSavePdpQuoteArgs = {
  clientId?: Maybe<Scalars['UUID']>;
  input: PdpQuoteInput;
  userId?: Maybe<Scalars['UUID']>;
};


export type MutationSendMedicareTypeCompareEmailArgs = {
  email: Scalars['String'];
  input: MedicareTypeCompareInput;
};


export type MutationSendRequestFormReceivedEmailArgs = {
  bidId: Scalars['String'];
  email: Scalars['String'];
  filterInput: PlansFilterInput;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  planYear?: Maybe<PlanYear>;
  quoteId: Scalars['UUID'];
};


export type MutationSetCompareYearToYearLockedArgs = {
  id: Scalars['UUID'];
  isLocked: Scalars['Boolean'];
};


export type MutationWarmUpPharmaciesGeocodingCacheArgs = {
  stateCode?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type Network = {
  __typename?: 'Network';
  name: Scalars['String'];
  tier?: Maybe<Scalars['String']>;
};

export type NetworkInfoOutput = {
  __typename?: 'NetworkInfoOutput';
  carrierName: Scalars['String'];
  name: Scalars['String'];
};

export type OtherDefinedSupplementalBenefits = {
  __typename?: 'OtherDefinedSupplementalBenefits';
  counselingServices: Array<Scalars['String']>;
  enhancedDiseaseManagement: Array<Scalars['String']>;
  fitnessBenefit: Array<Scalars['String']>;
  healthEducation: Array<Scalars['String']>;
  homeAndBathroomSafety: Array<Scalars['String']>;
  inHomeSafetyAssessment: Array<Scalars['String']>;
  medicalNutritionTherapy: Array<Scalars['String']>;
  nutritionalDietaryBenefit: Array<Scalars['String']>;
  personalEmergencyResponseSystem: Array<Scalars['String']>;
  postDischargeInHome: Array<Scalars['String']>;
  remoteAccessTechnologies: Array<Scalars['String']>;
  smokingAndTobaccoCessationCounseling: Array<Scalars['String']>;
  telemonitoringServices: Array<Scalars['String']>;
};

export type PackInfoOuput = {
  __typename?: 'PackInfoOuput';
  name?: Maybe<Scalars['String']>;
  rxcui?: Maybe<Scalars['String']>;
};

export type PageInput = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export type PageableManuallyUploadedPlanFileOutput = {
  __typename?: 'PageableManuallyUploadedPlanFileOutput';
  data: Array<ManuallyUploadedPlanFileOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageableMedicareQuoteAuditLogOutput = {
  __typename?: 'PageableMedicareQuoteAuditLogOutput';
  data: Array<MedicareQuoteAuditLogOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageableMedicareQuoteOutput = {
  __typename?: 'PageableMedicareQuoteOutput';
  data: Array<MedicareQuoteOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageablePdpPlanOutput = {
  __typename?: 'PageablePdpPlanOutput';
  data: Array<PdpPlan>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageablePharmacyLocationOutput = {
  __typename?: 'PageablePharmacyLocationOutput';
  data: Array<PharmacyLocationDistanceOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageablePlanOutput = {
  __typename?: 'PageablePlanOutput';
  data: Array<Plan>;
  doctorsCheckBoxMessage?: Maybe<Scalars['String']>;
  drugsCheckBoxMessage?: Maybe<Scalars['String']>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PdpDrugsDetailsDto = {
  __typename?: 'PdpDrugsDetailsDto';
  catastrophicCopayBranded: Scalars['String'];
  catastrophicCopayGeneric: Scalars['String'];
  catastrophicCoverageDescription: Scalars['String'];
  catastrophicThreshold: Scalars['String'];
  coverageGapDescription: Scalars['String'];
  initialCoverageDescription: Scalars['String'];
  initialCoverageLimit: Scalars['String'];
  mrxAltDedAmount: Scalars['String'];
  mrxAltDedAmountShort: Scalars['String'];
  mrxAltNoDedTier: Scalars['String'];
};

export enum PdpField {
  Deductible = 'DEDUCTIBLE',
  MaxOutOfPocket = 'MAX_OUT_OF_POCKET',
  MonthlyCost = 'MONTHLY_COST',
  Name = 'NAME',
  Premium = 'PREMIUM',
  Rating = 'RATING'
}

export type PdpPlan = {
  __typename?: 'PdpPlan';
  SNPType?: Maybe<SnpType>;
  bidId: Scalars['String'];
  contractNumber?: Maybe<Scalars['String']>;
  discountedDrugs?: Maybe<Array<DrugDiscount>>;
  drugCostMonthlyBreakdown?: Maybe<DrugCostMonthlyBreakdownOutput>;
  drugDetails?: Maybe<PdpDrugsDetailsDto>;
  drugPrices: Array<DrugPriceOutput>;
  drugTiers?: Maybe<DrugTiersOutput>;
  drugsCost?: Maybe<Scalars['String']>;
  drugsCoverage: Array<DrugCoverage>;
  inNetworkDeductableAmount?: Maybe<Scalars['BigDecimal']>;
  insulinSavings?: Maybe<Scalars['Boolean']>;
  maxEnrollmentAmount?: Maybe<Scalars['BigDecimal']>;
  missingFormularyInAep?: Maybe<Scalars['Boolean']>;
  monthlyCost?: Maybe<Scalars['String']>;
  monthlyPremium?: Maybe<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  outOfPocketAmount?: Maybe<Scalars['BigDecimal']>;
  parentOrgName?: Maybe<Scalars['String']>;
  partBPremium?: Maybe<Scalars['BigDecimal']>;
  pbpADsnpZeroDollar?: Maybe<Scalars['Boolean']>;
  pbpASnpStateCvgYn?: Maybe<Scalars['String']>;
  planName?: Maybe<Scalars['String']>;
  planType?: Maybe<Scalars['String']>;
  planTypeCode?: Maybe<Scalars['String']>;
  planYear?: Maybe<PlanYear>;
  quoteDrugsTiers?: Maybe<QuoteDrugTiersOutput>;
};


export type PdpPlanDrugTiersArgs = {
  deliveryMethod: DrugDeliveryMethod;
  supplyDuration: DrugSupplyDuration;
};


export type PdpPlanQuoteDrugsTiersArgs = {
  supplyDuration: DrugSupplyDuration;
};

export type PdpPlanAggregationOutput = {
  __typename?: 'PdpPlanAggregationOutput';
  monthlyPremiumMax?: Maybe<Scalars['BigDecimal']>;
  monthlyPremiumMin?: Maybe<Scalars['BigDecimal']>;
};

export type PdpPlansFilterInput = {
  companies?: Maybe<Array<Scalars['String']>>;
  countyName: Scalars['String'];
  insulinSavings?: Maybe<Scalars['Boolean']>;
  maxCostRangeNames?: Maybe<Array<Scalars['String']>>;
  planYear?: Maybe<PlanYear>;
  showAllPlans?: Maybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};

export type PdpPlansSortInput = {
  direction: PdpSort;
  pdpField: PdpField;
};

export type PdpQuoteInput = {
  cId?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  insulinSavings?: Maybe<Scalars['Boolean']>;
  zip?: Maybe<Scalars['String']>;
};

export type PdpQuoteOutput = {
  __typename?: 'PdpQuoteOutput';
  cId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['UUID']>;
  countyName?: Maybe<Scalars['String']>;
  drugDeliveryType?: Maybe<MedigapDrugDeliveryType>;
  id?: Maybe<Scalars['UUID']>;
  insulinSavings?: Maybe<Scalars['Boolean']>;
  preferredDrugs: Array<MedigapPreferredDrugOutput>;
  preferredPharmacies: Array<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export enum PdpSort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PharmacyAddressFilterInput = {
  address: Scalars['String'];
  lengthUnit?: Maybe<LengthUnit>;
  name?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['Float']>;
  sort?: Maybe<SortType>;
};

export type PharmacyLocationDistanceOutput = {
  __typename?: 'PharmacyLocationDistanceOutput';
  address: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  npi: Scalars['String'];
  zip: Scalars['String'];
};

export type PharmacyLocationFilterInput = {
  latitude: Scalars['Float'];
  lengthUnit?: Maybe<LengthUnit>;
  longitude: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['Float']>;
  sort?: Maybe<SortType>;
};

export type PharmacyTier = {
  __typename?: 'PharmacyTier';
  items: Array<DrugTier>;
  title?: Maybe<Scalars['String']>;
};

export type PharmacyWithinRectangleFilterInput = {
  name?: Maybe<Scalars['String']>;
  rectangle: RectangleInput;
};

export type Plan = {
  __typename?: 'Plan';
  SNPType?: Maybe<SnpType>;
  alternativeMedicine: Array<Scalars['String']>;
  annualPhysicalExam: Array<Scalars['String']>;
  bidId: Scalars['String'];
  calculableDoctorsCost: Scalars['Boolean'];
  chiropractic: Array<Scalars['String']>;
  dental?: Maybe<DentalBenefitDto>;
  discountedDrugs?: Maybe<Array<DrugDiscount>>;
  doctorsCost?: Maybe<Scalars['String']>;
  doctorsCoverage: Array<DoctorCoverage>;
  drugCostMonthlyBreakdown?: Maybe<DrugCostMonthlyBreakdownOutput>;
  drugDetails?: Maybe<DrugsDetails>;
  drugPrices: Array<DrugPriceOutput>;
  drugTiers?: Maybe<DrugTiersOutput>;
  drugsCost?: Maybe<Scalars['String']>;
  drugsCoverage: Array<DrugCoverage>;
  extraBenefits: ExtraBenefits;
  firstWeekOfStay?: Maybe<Scalars['String']>;
  hearing?: Maybe<HearingBenefitDto>;
  homeHealth: Array<Scalars['String']>;
  hospitalBenefits: Array<Scalars['String']>;
  hospitalityDetails: HospitalityDetails;
  inNetworkDeductableAmount?: Maybe<Scalars['String']>;
  maxEnrollmentAmount?: Maybe<Scalars['String']>;
  meal: Array<Scalars['String']>;
  mentalHealth: Array<Scalars['String']>;
  missingFormularyInAep?: Maybe<Scalars['Boolean']>;
  monthlyCost?: Maybe<Scalars['String']>;
  monthlyPremium?: Maybe<Scalars['String']>;
  oTC: Array<Scalars['String']>;
  optionalSupplementalBenefits: Scalars['Boolean'];
  optionalSupplementalBenefitsItems: Array<Scalars['String']>;
  orgName?: Maybe<Scalars['String']>;
  otherDefinedSupplemental: OtherDefinedSupplementalBenefits;
  outOfPocketAmount?: Maybe<Scalars['String']>;
  outOfPocketMaximum: Array<Scalars['String']>;
  outpatientHospitality: Array<Scalars['String']>;
  outpatientRehabilitation: Array<Scalars['String']>;
  outpatientSurgery: Array<Scalars['String']>;
  parentOrgName?: Maybe<Scalars['String']>;
  partBAmount?: Maybe<Scalars['String']>;
  partBGiveBack?: Maybe<Scalars['Boolean']>;
  physicianSpecialist: Array<Scalars['String']>;
  physicianSpecialistShort: Array<Scalars['String']>;
  planFiles?: Maybe<PlanFilesOutput>;
  planName?: Maybe<Scalars['String']>;
  planType?: Maybe<Scalars['String']>;
  planTypeCode?: Maybe<Scalars['String']>;
  planYear?: Maybe<PlanYear>;
  preventiveCare: Array<Scalars['String']>;
  primaryCarePhysician: Array<Scalars['String']>;
  primaryCarePhysicianInNetwork?: Maybe<Scalars['String']>;
  primaryCarePhysicianOutOfNetwork?: Maybe<Scalars['String']>;
  primaryCarePhysicianShort?: Maybe<Scalars['String']>;
  quoteDrugsTiers?: Maybe<QuoteDrugTiersOutput>;
  rating?: Maybe<Scalars['Float']>;
  ratingNote?: Maybe<Scalars['String']>;
  skilledNursing: Array<Scalars['String']>;
  telehealth: Array<Scalars['String']>;
  transportation: Array<Scalars['String']>;
  vision?: Maybe<VisionBenefitDto>;
  zip: Scalars['String'];
};


export type PlanDrugTiersArgs = {
  deliveryMethod: DrugDeliveryMethod;
  supplyDuration: DrugSupplyDuration;
};


export type PlanQuoteDrugsTiersArgs = {
  supplyDuration: DrugSupplyDuration;
};

export type PlanFileFilterInput = {
  bidId?: Maybe<Scalars['String']>;
  planYear?: Maybe<PlanYear>;
};

export type PlanFilesOutput = {
  __typename?: 'PlanFilesOutput';
  bidId: Scalars['String'];
  evidenceOfCoverageDocUrl?: Maybe<Scalars['String']>;
  nonDiscriminationNoticeDocUrl?: Maybe<Scalars['String']>;
  preEnrollmentChecklistDocUrl?: Maybe<Scalars['String']>;
  starRatingDescDocUrl?: Maybe<Scalars['String']>;
  summaryOfBenefitsUrl?: Maybe<Scalars['String']>;
};

export type PlanForFlexpaDto = {
  __typename?: 'PlanForFlexpaDto';
  bidId: Scalars['String'];
  drugDeductible?: Maybe<Scalars['BigDecimal']>;
  drugOutOfPocket?: Maybe<Scalars['BigDecimal']>;
  innDeductible: Scalars['BigDecimal'];
  oonDeductible: Scalars['BigDecimal'];
  orgName: Scalars['String'];
  outOfPocket?: Maybe<Scalars['BigDecimal']>;
  parentOrgName: Scalars['String'];
  planName: Scalars['String'];
  premium: Scalars['BigDecimal'];
};

export enum PlanYear {
  Year2021 = 'Year2021',
  Year2022 = 'Year2022',
  Year2023 = 'Year2023',
  Year2024 = 'Year2024'
}

export type PlansCompareInput = {
  bidIds: Array<Scalars['String']>;
  county: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  onlyPreview?: Maybe<Scalars['Boolean']>;
  planYear?: Maybe<PlanYear>;
  quoteId?: Maybe<Scalars['UUID']>;
  zip: Scalars['String'];
};

export type PlansCountByZipOutput = {
  __typename?: 'PlansCountByZipOutput';
  maPlansCount: Scalars['Long'];
  maPlansParentOrgCount: Scalars['Long'];
  maPlansTotalCount: Scalars['Long'];
  maPlansTotalParentOrgCount: Scalars['Long'];
  pdpPlansCount: Scalars['Long'];
  pdpPlansParentOrgCount: Scalars['Long'];
  pdpPlansTotalCount: Scalars['Long'];
  pdpPlansTotalParentOrgCount: Scalars['Long'];
  zip: Scalars['String'];
};

export type PlansFilterInput = {
  SNPTypes?: Maybe<Array<SnpType>>;
  companies?: Maybe<Array<Scalars['String']>>;
  countyName: Scalars['String'];
  extraBenefits?: Maybe<Array<ExtraBenefit>>;
  isMA?: Maybe<Scalars['Boolean']>;
  isMD?: Maybe<Scalars['Boolean']>;
  isMS?: Maybe<Scalars['Boolean']>;
  maxCostRangeNames?: Maybe<Array<Scalars['String']>>;
  maxOutOfPocketRangeNames?: Maybe<Array<Scalars['String']>>;
  maxPremium?: Maybe<Scalars['BigDecimal']>;
  onlyFavorite?: Maybe<Scalars['Boolean']>;
  partBPremiumReduction?: Maybe<Scalars['Boolean']>;
  planTypes?: Maybe<Array<Scalars['String']>>;
  planYear?: Maybe<PlanYear>;
  rating?: Maybe<Array<Rating>>;
  showAllPlans?: Maybe<Scalars['Boolean']>;
  showTopDoctors?: Maybe<Scalars['Boolean']>;
  showTopDrugs?: Maybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};

export type PlansSortInput = {
  direction: Sort;
  field: Field;
};

export type PlansSummaryOutput = {
  __typename?: 'PlansSummaryOutput';
  drugsCoveredPercent: Scalars['Int'];
  hmoCount: Scalars['Int'];
  ppoCount: Scalars['Int'];
  totalCount: Scalars['Int'];
  zeroPremiumPercent: Scalars['Int'];
};

export type PointInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ProductPackagePairInput = {
  packageRxcui: Scalars['String'];
  productRxcui: Scalars['String'];
};

export type ProviderAddressOutput = {
  __typename?: 'ProviderAddressOutput';
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  id: Scalars['String'];
  isPcp: Scalars['Boolean'];
  specialty: Scalars['String'];
};

export type ProviderFilterInput = {
  radius?: Maybe<Scalars['Int']>;
  searchTerm: Scalars['String'];
  zipCode: Scalars['String'];
};

export type ProviderOutput = {
  __typename?: 'ProviderOutput';
  addresses: Array<ProviderAddressOutput>;
  addressesNumber: Scalars['Int'];
  name: Scalars['String'];
  npi: Scalars['String'];
  pcpDetails: Array<ProviderPcpInfoOutput>;
};

export type ProviderPcpInfoOutput = {
  __typename?: 'ProviderPcpInfoOutput';
  id: Scalars['UUID'];
  networkDetails: Array<NetworkInfoOutput>;
  pcpId: Scalars['String'];
};

export type ProviderPlanOutput = {
  __typename?: 'ProviderPlanOutput';
  carrierName?: Maybe<Scalars['String']>;
  externalIds: Array<ExternalId>;
  id: Scalars['String'];
  lineOfCoverage: Scalars['String'];
  market?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  networks: Array<Network>;
};

export type ProviderPlansFilterInput = {
  lineOfCoverage?: Maybe<Scalars['String']>;
  npi: Scalars['String'];
  year?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  PdpPlan: PdpPlan;
  PdpPlans: PageablePdpPlanOutput;
  SNPTypes: Array<FilterItem>;
  companies: Array<FilterItem>;
  compareOffer?: Maybe<CompareOffer>;
  compareYearToYear?: Maybe<CompareYearToYearOutput>;
  compareYearToYearByClientId?: Maybe<CompareYearToYearOutput>;
  drugPackagesInfo?: Maybe<DrugInfoWithPackagesFlags>;
  drugs: Array<DrugInfoWithGeneric>;
  drugsWithPackage: Array<DrugInfoWithPack>;
  emailStats: Array<SibEvent>;
  enabledPbpContracts: Array<EnabledPbpContractOutput>;
  enabledPbpStates: Array<EnabledPbpStatesOutput>;
  enabledPdpContracts: Array<EnabledPdpContractOutput>;
  enabledPdpStates: Array<EnabledPdpStatesOutput>;
  extraBenefits: Array<FilterItem>;
  extraBenefitsWithMaxBenefitAmount: ExtraBenefitsWithMaxBenefitAmountOutput;
  findBrokenAddresses: Array<BrokenAddress>;
  findDoctorsByNpi?: Maybe<Doctor>;
  findDrugByProductId?: Maybe<DrugProductDto>;
  findDrugsDetailsByBidId?: Maybe<DrugsDetails>;
  findPharmaciesByAddress: PageablePharmacyLocationOutput;
  findPharmaciesByLocation: PageablePharmacyLocationOutput;
  findPharmaciesWithinRectangle: PageablePharmacyLocationOutput;
  findPharmacyByNpi?: Maybe<PharmacyLocationDistanceOutput>;
  findProviderAddressesByNpi: Array<ProviderAddressOutput>;
  findProvidersByFilter: Array<ProviderOutput>;
  findProvidersPlansByFilter: Array<ProviderPlanOutput>;
  findRequestAuditLogById?: Maybe<MedicareQuoteAuditLogOutput>;
  findRequestAuditLogs: PageableMedicareQuoteAuditLogOutput;
  findStarRatings: Array<FilterItem>;
  findZipLocation?: Maybe<LocationOutput>;
  generateEhealthPlanLink: Scalars['String'];
  getMyQuoteId?: Maybe<Scalars['UUID']>;
  isTherePlanWithFullDrugCoverage: Scalars['Boolean'];
  loginStats: Array<UserLogin>;
  manuallyUploadedPlanFiles: PageableManuallyUploadedPlanFileOutput;
  maxCostRanges: Array<FilterItem>;
  maxOutOfPocketRanges: Array<FilterItem>;
  medicarePlanForFlexpa: PlanForFlexpaDto;
  medicarePlansAggregateByCompany: Array<MedicarePlansAggregationByCompanyOutput>;
  medicarePlansAggregation: MedicarePlansAggregationOutput;
  medicareQuote?: Maybe<MedicareQuoteOutput>;
  medicareQuoteByPin?: Maybe<MedicareQuoteOutput>;
  medicareQuotes: PageableMedicareQuoteOutput;
  myCompareYearToYear?: Maybe<CompareYearToYearOutput>;
  packageDescriptionByNdc?: Maybe<Scalars['String']>;
  packageDescriptionByRxcui: DrugPackageOutput;
  packages: Array<PackInfoOuput>;
  partBPremiumReductionCount: Scalars['Int'];
  pdpCompanies: Array<FilterItem>;
  pdpPlanAggregation: PdpPlanAggregationOutput;
  pdpQuote: PdpQuoteOutput;
  percentOfZeroPremiumMedicarePlans: Scalars['String'];
  plan?: Maybe<Plan>;
  planTypes: Array<FilterItem>;
  plans: PageablePlanOutput;
  plansCountByZip: PlansCountByZipOutput;
  plansSummary: PlansSummaryOutput;
  regulatoryVariable: RegulatoryVariable;
};


export type QueryPdpPlanArgs = {
  bidId: Scalars['String'];
  clientId?: Maybe<Scalars['UUID']>;
  countyName: Scalars['String'];
  fullYear?: Maybe<Scalars['Boolean']>;
  planYear?: Maybe<PlanYear>;
  zip: Scalars['String'];
};


export type QueryPdpPlansArgs = {
  clientId?: Maybe<Scalars['UUID']>;
  filter: PdpPlansFilterInput;
  fullYear?: Maybe<Scalars['Boolean']>;
  page: PageInput;
  sort: Array<PdpPlansSortInput>;
};


export type QuerySnpTypesArgs = {
  filter: PlansFilterInput;
};


export type QueryCompaniesArgs = {
  filter: PlansFilterInput;
};


export type QueryCompareOfferArgs = {
  clientId?: Maybe<Scalars['UUID']>;
};


export type QueryCompareYearToYearArgs = {
  id: Scalars['UUID'];
};


export type QueryCompareYearToYearByClientIdArgs = {
  clientId: Scalars['UUID'];
};


export type QueryDrugPackagesInfoArgs = {
  rxcui: Scalars['String'];
};


export type QueryDrugsArgs = {
  name: Scalars['String'];
};


export type QueryDrugsWithPackageArgs = {
  pairs: Array<ProductPackagePairInput>;
};


export type QueryEmailStatsArgs = {
  email: Scalars['String'];
};


export type QueryExtraBenefitsArgs = {
  filter: PlansFilterInput;
};


export type QueryExtraBenefitsWithMaxBenefitAmountArgs = {
  planYear?: Maybe<PlanYear>;
  zip: Scalars['String'];
};


export type QueryFindBrokenAddressesArgs = {
  addressSearchTerm?: Maybe<Scalars['String']>;
};


export type QueryFindDoctorsByNpiArgs = {
  npi: Scalars['String'];
};


export type QueryFindDrugByProductIdArgs = {
  planYear?: Maybe<PlanYear>;
  productId: Scalars['String'];
};


export type QueryFindDrugsDetailsByBidIdArgs = {
  bidId: Scalars['String'];
  countyName: Scalars['String'];
  planYear?: Maybe<PlanYear>;
  zip: Scalars['String'];
};


export type QueryFindPharmaciesByAddressArgs = {
  filterInput: PharmacyAddressFilterInput;
  page: PageInput;
  planYear?: Maybe<PlanYear>;
};


export type QueryFindPharmaciesByLocationArgs = {
  filterInput: PharmacyLocationFilterInput;
  page: PageInput;
};


export type QueryFindPharmaciesWithinRectangleArgs = {
  filterInput: PharmacyWithinRectangleFilterInput;
  page: PageInput;
};


export type QueryFindPharmacyByNpiArgs = {
  lengthUnit?: Maybe<LengthUnit>;
  npi: Scalars['String'];
  planYear?: Maybe<PlanYear>;
  zipCode?: Maybe<Scalars['String']>;
};


export type QueryFindProviderAddressesByNpiArgs = {
  npi: Scalars['String'];
};


export type QueryFindProvidersByFilterArgs = {
  filterInput: ProviderFilterInput;
};


export type QueryFindProvidersPlansByFilterArgs = {
  filterInput: ProviderPlansFilterInput;
};


export type QueryFindRequestAuditLogByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryFindRequestAuditLogsArgs = {
  filterInput?: Maybe<MedicareQuoteFilterInput>;
  page: PageInput;
};


export type QueryFindStarRatingsArgs = {
  filter: PlansFilterInput;
};


export type QueryFindZipLocationArgs = {
  planYear?: Maybe<PlanYear>;
  zip: Scalars['String'];
};


export type QueryGenerateEhealthPlanLinkArgs = {
  bidId: Scalars['String'];
  countyName: Scalars['String'];
  planYear?: Maybe<PlanYear>;
  zip: Scalars['String'];
};


export type QueryIsTherePlanWithFullDrugCoverageArgs = {
  countyName: Scalars['String'];
  planYear?: Maybe<PlanYear>;
  quoteId?: Maybe<Scalars['UUID']>;
  zip: Scalars['String'];
};


export type QueryLoginStatsArgs = {
  email: Scalars['String'];
};


export type QueryManuallyUploadedPlanFilesArgs = {
  filter?: Maybe<PlanFileFilterInput>;
  page: PageInput;
};


export type QueryMaxCostRangesArgs = {
  filter: PlansFilterInput;
  fullYear?: Maybe<Scalars['Boolean']>;
  quoteId?: Maybe<Scalars['UUID']>;
};


export type QueryMaxOutOfPocketRangesArgs = {
  filter: PlansFilterInput;
};


export type QueryMedicarePlanForFlexpaArgs = {
  bidId: Scalars['String'];
  planYear: PlanYear;
};


export type QueryMedicarePlansAggregateByCompanyArgs = {
  county: Scalars['String'];
  planTypes: Array<Scalars['String']>;
  planYear?: Maybe<PlanYear>;
  zip: Scalars['String'];
};


export type QueryMedicarePlansAggregationArgs = {
  allowAllStates?: Maybe<Scalars['Boolean']>;
  county: Scalars['String'];
  dsnpOnly?: Maybe<Scalars['Boolean']>;
  planType?: Maybe<MedicarePlanType>;
  planYear?: Maybe<PlanYear>;
  zip: Scalars['String'];
};


export type QueryMedicareQuoteArgs = {
  id: Scalars['UUID'];
};


export type QueryMedicareQuoteByPinArgs = {
  pin: Scalars['String'];
};


export type QueryMedicareQuotesArgs = {
  filterInput?: Maybe<MedicareQuoteFilterInput>;
  page: PageInput;
};


export type QueryPackageDescriptionByNdcArgs = {
  ndc: Scalars['String'];
  planYear?: Maybe<PlanYear>;
};


export type QueryPackageDescriptionByRxcuiArgs = {
  planYear?: Maybe<PlanYear>;
  rxcui: Scalars['String'];
};


export type QueryPackagesArgs = {
  rxcui: Scalars['String'];
};


export type QueryPartBPremiumReductionCountArgs = {
  filter: PlansFilterInput;
};


export type QueryPdpCompaniesArgs = {
  filter: PdpPlansFilterInput;
};


export type QueryPdpPlanAggregationArgs = {
  county: Scalars['String'];
  dsnpOnly?: Maybe<Scalars['Boolean']>;
  includeHidden?: Maybe<Scalars['Boolean']>;
  planYear?: Maybe<PlanYear>;
  zip: Scalars['String'];
};


export type QueryPdpQuoteArgs = {
  id: Scalars['UUID'];
};


export type QueryPercentOfZeroPremiumMedicarePlansArgs = {
  allowAllStates?: Maybe<Scalars['Boolean']>;
  county: Scalars['String'];
  planTypes?: Maybe<Array<MedicarePlanType>>;
  planYear?: Maybe<PlanYear>;
  zip: Scalars['String'];
};


export type QueryPlanArgs = {
  bidId: Scalars['String'];
  countyName: Scalars['String'];
  fullYear?: Maybe<Scalars['Boolean']>;
  planYear?: Maybe<PlanYear>;
  quoteId?: Maybe<Scalars['UUID']>;
  zip: Scalars['String'];
};


export type QueryPlanTypesArgs = {
  filter: PlansFilterInput;
};


export type QueryPlansArgs = {
  filter: PlansFilterInput;
  fullYear?: Maybe<Scalars['Boolean']>;
  page: PageInput;
  quoteId?: Maybe<Scalars['UUID']>;
  sort: Array<PlansSortInput>;
};


export type QueryPlansCountByZipArgs = {
  zip: Scalars['String'];
};


export type QueryPlansSummaryArgs = {
  countyName: Scalars['String'];
  extraBenefits?: Maybe<Array<ExtraBenefit>>;
  planYear?: Maybe<PlanYear>;
  quoteId?: Maybe<Scalars['UUID']>;
  showAllPlans?: Maybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};


export type QueryRegulatoryVariableArgs = {
  year: PlanYear;
};

export type QuoteDrugTiersOutput = {
  __typename?: 'QuoteDrugTiersOutput';
  catastrophicCoverageDrugTiers: Array<DrugTierCoverageOutput>;
  coverageGapDrugTiers: Array<DrugTierCoverageOutput>;
  deliveryType: DrugDeliveryMethod;
  initialCoverageDrugTiers: Array<DrugTierCoverageOutput>;
  npi: Scalars['String'];
  pharmacyName: Scalars['String'];
};

export enum Rating {
  None = 'NONE',
  R1 = 'R1',
  R1_5 = 'R1_5',
  R2 = 'R2',
  R2_5 = 'R2_5',
  R3 = 'R3',
  R3_5 = 'R3_5',
  R4 = 'R4',
  R4_5 = 'R4_5',
  R5 = 'R5'
}

export type RectangleInput = {
  diagonalPoint: PointInput;
  firstPoint: PointInput;
};

export type RegulatoryVariable = {
  __typename?: 'RegulatoryVariable';
  catastrophicCoinsurance: Scalars['Int'];
  catastrophicCoinsuranceBranded: Scalars['Int'];
  catastrophicCoinsuranceGeneric: Scalars['Int'];
  catastrophicCopayBranded: Scalars['BigDecimal'];
  catastrophicCopayGeneric: Scalars['BigDecimal'];
  catastrophicThreshold: Scalars['BigDecimal'];
  coverageGapCoinsurance: Scalars['Int'];
  coverageGapCopayBranded: Scalars['BigDecimal'];
  coverageGapCopayGeneric: Scalars['BigDecimal'];
  defaultDrugDeductible: Scalars['BigDecimal'];
  defaultInitialCoverageThreshold: Scalars['BigDecimal'];
  initialCopayBranded: Scalars['BigDecimal'];
  initialCopayGeneric: Scalars['BigDecimal'];
  insulinPrice: Scalars['BigDecimal'];
  manufacturerTroop: Scalars['Int'];
};

export enum RequestStatusDto {
  Complete = 'COMPLETE',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS'
}

export enum SnpType {
  CSnp = 'C_SNP',
  DSnp = 'D_SNP',
  ISnp = 'I_SNP'
}

export type SaveMedicareQuoteOutput = {
  __typename?: 'SaveMedicareQuoteOutput';
  clientId: Scalars['UUID'];
  maQuoteID: Scalars['UUID'];
};

export type SibEvent = {
  __typename?: 'SibEvent';
  emailToken?: Maybe<Scalars['String']>;
  eventDate: Scalars['Instant'];
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  messageId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Int']>;
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortType {
  Distance = 'DISTANCE',
  Name = 'NAME'
}

export type UserLogin = {
  __typename?: 'UserLogin';
  emailToken: Scalars['String'];
  event: Event;
  eventDate: Scalars['Instant'];
  id: Scalars['UUID'];
};

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleNone = 'ROLE_NONE',
  RoleTechStaff = 'ROLE_TECH_STAFF',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}

export type VisionBenefitDto = {
  __typename?: 'VisionBenefitDto';
  allEyewearMaxMount?: Maybe<Scalars['BigDecimal']>;
  benefits: Array<Scalars['String']>;
  eyeExamsMaxAmount?: Maybe<Scalars['BigDecimal']>;
};
