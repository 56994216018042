import React, {PropsWithChildren} from "react";
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ScrollbarList, TrustpilotWidget } from '../..';
import { Box, Divider, Theme } from '@mui/material';
import { Terms } from '../..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 320,
      overflow: 'hidden'
    },
    container: {
      padding: '0 20px 0 30px',
      background: 'white',
      height: '100%',
      display: 'flex',
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    }
  }),
);

interface SidePanelProps {
  sideHeaderContent?: JSX.Element,
  containerClassName?: string,
}

export default function SidePanel(props: PropsWithChildren<SidePanelProps>) {
  const classes = useStyles();



  return (
    <div className={classes.root}>
      <ScrollbarList height={'100vh'}>
        <div className={clsx(classes.container, props.containerClassName)}>
          <div className={'flex-column pv-30'}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <img src={'/assets/img/Logo.svg'} width={120} height={31} className={'pointer'} onClick={() => document.location.href = document.location.origin} />
              {props.sideHeaderContent && <>{props.sideHeaderContent}</>}
            </Box>
            <Box marginTop={'16px'} width={'100%'}>
              <Divider className={classes.divider} />
            </Box>
            {props.children}
            <Box sx={{display: 'flex', flex: 1, mt: 2}} />
            <TrustpilotWidget/>

            <div className={'h-10'} />
            <a href="https://www.bbb.org/us/ny/brooklyn/profile/medicare/coverright-inc-0121-87151389/#sealclick"
               id="bbblink" className="rbhzbus" target="_blank" rel="nofollow"><img
              src="https://seal-newyork.bbb.org/logo/rbhzbus/bbb-87151389.png" style={{border: 0}}
              alt="CoverRight Inc.  BBB Business Review"/></a>
            <div className={'h-10'} />
            <Terms />
          </div>
        </div>
      </ScrollbarList>
    </div>
  )
}
/*
<div className={"trustpilot-widget"} data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32"
     data-businessunit-id="606b5eed31d5710001a9db81" data-style-height="75px" data-style-width="50%"
>
  <a href="https://www.trustpilot.com/review/coverright.com" target="_blank" rel="noopener">Trustpilot</a>
</div>*/
