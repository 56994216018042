import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { KeycloakContext, KeycloakRoles } from '@coverright/shared/keycloak';
import { resetStoredUser } from '@coverright/utils';
import PersonIcon from '@mui/icons-material/Person';
import { LoginModal } from '../login-modal/login-modal';
import { ProfileSource } from '@coverright/data-access/types/enrollment';
import { ProfileContext } from '@coverright/shared/contexts';
import * as React from 'react';
import { useLogEvent } from '@coverright/shared/analytics';

export const LoginWidget = (props: {
  view?: 'button' | 'standart';
  createModalTitle?: string;
  createModalSubtitle?: string;
  redirectUrl?: string;
  showSkipLogin?: boolean;
  source: ProfileSource;
}) => {
  const { keycloak } = React.useContext(KeycloakContext);
  const { firstName, lastName } = React.useContext(ProfileContext);
  const [open, setOpen] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const logEvent = useLogEvent();

  if (keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN)) {
    return null;
  }

  const TooltipContent = () => {
    return (
      <Box position={'relative'} p={'12px 60px 12px 21px'}>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            top: '-6px',
            right: '-10px',
            padding: '8px',
            cursor: 'pointer',
          }}
        >
          <img width={25} height={25} src={'/assets/img/close.svg'} />
        </IconButton>
        <Typography color={'textPrimary'} className={'bold fs-16'}>
          Welcome, {firstName} {lastName}!
        </Typography>
        <div className={'h-12'} />
        <Typography
          color={'textPrimary'}
          onClick={() => {
            document.location.href = process.env.NX_USER_FLOW_URL + 'private';
          }}
          className={'pointer fs-16'}
        >
          My info
        </Typography>
        <div className={'h-12'} />
        <Typography
          color={'textPrimary'}
          onClick={() => {
            setOpen(false);
            sessionStorage.removeItem('appState');
            resetStoredUser();
            keycloak.logout({ redirectUri: document.location.origin });
          }}
          className={'pointer fs-16'}
        >
          Log out
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {keycloak?.authenticated && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div data-test="authenticated">
            <LightTooltip
              onClose={() => setOpen(false)}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={<TooltipContent />}
              arrow
              placement="right"
            >
              <div>
                {props.view === 'button' && (
                  <Button
                    variant={'contained'}
                    size={'small'}
                    onClick={() => setOpen(true)}
                  >
                    My Account
                  </Button>
                )}
                {props.view !== 'button' && (
                  <>
                    {!phone && (
                      <Button
                        sx={{ fontWeight: 500, fontSize: 16, color: '#1D9C80' }}
                        startIcon={
                          <PersonIcon sx={{ width: '20px', height: '20px' }} />
                        }
                        size={'small'}
                        onClick={() => {
                          logEvent('Nav: Click on profile name')
                          setOpen(true)
                        }}
                      >
                        {firstName} {lastName}
                      </Button>
                    )}
                    {phone && (
                      <IconButton
                        sx={{ fontWeight: 500, fontSize: 16, color: '#1D9C80' }}
                        size={'small'}
                        onClick={() => {
                          logEvent('Nav: Click on profile name')
                          setOpen(true)
                        }}
                      >
                        <img src={'/assets/img/logged-in.png'}/>
                      </IconButton>
                    )}
                  </>
                )}
              </div>
            </LightTooltip>
          </div>
        </ClickAwayListener>
      )}
      {!keycloak?.authenticated && (
        <div data-test="not_authenticated">
          <LoginModal
            open={loginOpen}
            onClose={() => setLoginOpen(false)}
            onCreateAccountClick={() => {
              setLoginOpen(false);
              keycloak?.register()
            }}
          />
          {props.view === 'button' && (
            <Button
              variant={'contained'}
              size={'small'}
              onClick={() => {
                // setLoginOpen(true)
                const redirectUri =
                document.location.href = keycloak.createLoginUrl({
                  redirectUri: props.redirectUrl || document.location.href,
                }) + (props.showSkipLogin ? '&show_skip=true' : '');
              }}
            >
              Sign in
            </Button>
          )}
          {props.view !== 'button' && (
            <>
              {!phone && (
                <Button
                  sx={{ fontWeight: 500, fontSize: 16, color: '#1D9C80' }}
                  startIcon={
                    <PersonIcon sx={{ width: '20px', height: '20px' }} />
                  }
                  size={'small'}
                  onClick={() => {
                    logEvent('Login', {
                      source: 'V2',
                      page: document.location.href,
                    })
                    //setLoginOpen(true)
                    document.location.href = keycloak.createLoginUrl({
                      redirectUri: props.redirectUrl || document.location.href,
                    }) + (props.showSkipLogin ? '&show_skip=true' : '');
                  }}
                >
                  Sign in
                </Button>
              )}
              {phone && (
                <IconButton
                  sx={{ fontWeight: 500, width: '100%', fontSize: 16, color: '#1D9C80' }}
                  size={'small'}
                  onClick={() => {
                    logEvent('Login', {
                      source: 'V2',
                      page: document.location.href,
                    })
                    //setLoginOpen(true)
                    document.location.href = keycloak.createLoginUrl({
                      redirectUri: props.redirectUrl || document.location.href,
                    }) + (props.showSkipLogin ? '&show_skip=true' : '');
                  }}
                >
                  <PersonIcon sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    '& .MuiTooltip-arrow': {
      color: theme.palette.common.white,
    },
  },
}));
