import { createTheme } from '@mui/material';

interface Color {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

const colors: {
  text: {primary: string, secondary: string},
  primary: Color,
  secondary: Color,
  warning: Color,
  green: {variant1: string, variant2: string}
} = {
  text: {
    primary: '#1C434F',
    secondary: '#787885',
  },
  primary: {
    "50": "#EBF2FF",
    "100": "#D8E6FF",
    "200": "#C4DAFF",
    "300": "#9DC2FF",
    "400": "#76A9FF",
    "500": "#4F91FF",
    "600": "#2979FF",
    "700": "#2264D1",
    "800": "#1B4EA3",
    "900": "#133774",
  },
  secondary: {
    "50": "#EDF6EE",
    "100": "#DCEDDD",
    "200": "#CBE5CC",
    "300": "#A9D3AB",
    "400": "#87C289",
    "500": "#65B168",
    "600": "#07B422",
    "700": "#37833B",
    "800": "#2B662E",
    "900": "#1F4921",
  },
  warning: {
    "50": "#FFF8EA",
    "100": "#FFF1D6",
    "200": "#FFEAC1",
    "300": "#FFDC99",
    "400": "#FFCF70",
    "500": "#FFC147",
    "600": "#FFB41F",
    "700": "#D1941A",
    "800": "#A37314",
    "900": "#74520F",
  },
  green: {
    variant1: '#5ECB9E',
    variant2: '#029094'
  }
};


export const BenzingaTheme = createTheme({
  palette: {
    primary: {
      light: colors.primary["400"],
      main: colors.primary["600"],
      dark: colors.primary["900"]
    },
    secondary: {
      light: colors.secondary["400"],
      main: colors.secondary["700"],
      dark: colors.secondary["900"]
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: ({theme}) => theme.unstable_sx({
          borderRadius: 0,
          boxShadow: { xs: 'none', sm: '0px 4px 50px rgba(0, 0, 0, 0.25)' },
          padding: { xs: 0, sm: '20px 24px' },
        })
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: 0,
          fontSize: '16px!important',
          lineHeight: '20px',
          fontWeight: 500,
          backgroundColor: 'white',
          borderRadius: 0,
        },
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 500,
          padding: 0,
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#CCD8DC',
          },
          '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: `#029094!important`,
            borderWidth: '1px!important',
            backgroundColor: 'rgba(2, 144, 148, 0.05)'
          },
          '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'rgba(220, 16, 28, 0.05)',
          },
        },
        multiline: {
          padding: '11px 7px 10px',
          '& input': {
            padding: 0,
          }
        },
        input: {
          fontWeight: 500,
          fontFamily: 'Manrope, sans-serif',
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '26px',
          '& .MuiOutlinedInput-input': {
            padding: '10px 15px!important',
            fontSize: '16px!important',
            lineHeight: '20px',
            fontWeight: 500,
            fontFamily: 'Manrope, sans-serif',
            backgroundColor: 'white',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 0,
          },

        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: 'rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
          borderRadius: '0px 0px 4px 4px',
          marginTop: '4px',
          '& ul': {
            padding: '0',
          },
          '& li': {
            minHeight: 56,
            fontSize: 16,
            fontWeight: 500,
            padding: '8px 20px',
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          fontWeight: 800,
          textTransform: 'uppercase',
          fontSize: 13,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          color: 'white',
          backgroundColor: '#5ECB9E',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: 'rgba(81, 224, 165, 1)',
            boxShadow: 'none',
          },
          '&:active': {
            backgroundColor: 'rgba(81, 224, 165, 1)',
            boxShadow: 'none',
          },
        }
      },
      variants: [{
        props: {variant: 'contained', color: 'primary', size: 'large'},
        style: {
          borderRadius: 0,
          fontWeight: 800,
          fontSize: 18,
          letterSpacing: 2,
          height: 50,
          background: '#1D9C80',
          mt: 1
        }
      }]
    },
  },
  typography: {
    fontWeightBold: 700,
    fontFamily: [
      'Manrope', 'sans-serif'
    ].join(','),
    allVariants: {
      color: colors.text.primary,
    },
    h1: {
      // fontSize: 50,
      fontSize: '29px',
      fontWeight: 400
    },
    h2: {
      fontSize: '24px',
      // fontSize: 32,
      fontWeight: 400
    },
    h4: {
      fontSize: '16px',
      fontWeight: 500,
    },
    //Callout
    h5: {
      fontSize: '12px',
      fontWeight: 400,
    },
    // subtitle2: {
    //   fontSize: 10,
    //   lineHeight: '12px',
    //   color: '#A7A7A7',
    //   textTransform: "uppercase",
    //   marginTop: '4px',
    // } as any,
    body1: {
      fontSize: 13,
    },
    body2: {
      fontSize: 12,
    },
  },
});

BenzingaTheme.typography.h3 = {
  fontSize: 24,
  lineHeight: '40px',
  fontWeight: 600,
  fontFamily: [
    'Manrope', 'sans-serif'
  ].join(','),
  [BenzingaTheme.breakpoints.up('sm')]: {
    fontSize: 32,
    lineHeight: '48px',
  },
}
