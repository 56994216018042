import React from "react";
import {
  ClickAwayListener, Hidden,
  styled, Tooltip,
  tooltipClasses,
  TooltipProps
} from '@mui/material';
/*
const useStyles = makeStyles({
  tooltip: {
    border: ({variant}: {variant?: 'standard' | 'white'}) => `1px solid ${variant === 'white' ? '#CCD8DC' : colors.custom.green.variant2}!important`,
    backgroundColor: ({variant}: {variant?: 'standard' | 'white'}) => variant === 'white' ? 'white' : '#f2f9fa',
    borderRadius: 4,
    color: colors.text.primary,
    fontSize: 13,
    textAlign: 'left',
    padding: '15px 20px 11px',
    fontWeight: 400,
    '& .MuiTooltip-arrow': {
      color: ({variant}: {variant?: 'standard' | 'white'}) => variant === 'white' ? 'white' : '#f2f9fa',
    },
    '& .MuiTooltip-arrow:before': {
      border: ({variant}: {variant?: 'standard' | 'white'}) => `1px solid ${variant === 'white' ? '#CCD8DC' : colors.custom.green.variant2}!important`,
    },
  },
})

const Tooltip = (props: TooltipProps & {variant?: 'standard' | 'white'}) => {
  const {children, variant, ...rest} = props;
  const classes = useStyles({variant});
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickAway = () => setOpen(false)


  if (!phone) {
    return <Tooltip {...rest} classes={classes}>{children}</Tooltip>
  } else {
    return <ClickAwayListener onClickAway={handleClickAway}>
      <Tooltip {...rest} open={open} onClick={(event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setOpen(true)
      }} classes={classes}>{children}</Tooltip>
    </ClickAwayListener>
  }
};*/

export const CRTooltip = styled(({ className, ...props }: TooltipProps & {className?: any}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickAway = () => {
    setOpen(false)
  }
  return <>
    <Hidden smDown>
      <Tooltip {...props} classes={{ popper: className }}/>
    </Hidden>
    <Hidden smUp>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Tooltip {...props} open={open} onClick={(event) => {
          /*event.preventDefault();
          event.stopPropagation();*/
          setOpen(true)
        }} classes={{ popper: className }}/>
      </ClickAwayListener>
    </Hidden>
  </>
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: `1px solid #029094!important`,
    backgroundColor: '#f2f9fa',
    borderRadius: 4,
    color: '#1C434F',
    fontSize: 16,
    textAlign: 'left',
    padding: '15px 20px 11px',
    fontWeight: 400,
    '& .MuiTooltip-arrow': {
      color: '#f2f9fa'
    },
    '& .MuiTooltip-arrow:before': {
      border: `1px solid #029094!important`
    }
  }
}));

