import { createStyles, makeStyles } from '@mui/styles';
import { Box, Card, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Layout, PageWrapper } from '@coverright/ui/shared';
import { colors } from '@coverright/ui/themes';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      background: colors.custom.green.variant2,
      color: 'white',
      fontWeight: 700,
      textAlign: 'center',
      padding: '7px 0',
    },
  })
);

const Num = ({value}: {value: string}) => <Typography align={'center'} sx={{
  fontWeight: 700,
  color: colors.custom.green.variant2,
  fontSize: 40,
  mr: 3,
  lineHeight: 1,
  minWidth: 25,
}}>{value}</Typography>

export const Received = (props: RouteComponentProps & {helpModalComponent: any, SideComponent: FunctionComponent, sideHeaderContent?: JSX.Element}) => {
  const classes = useStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  return <Layout {...props}>
    <PageWrapper title={'Congratulations! We’ve received your enrollment application request!'}
                 maxWidth={800}
                 nextButtonSx={{maxWidth: 'auto'}}
                 onNextButtonClick={() => document.location.href = 'http://coverright.com/'}
                 nextButtonTitle={'Go to CoverRight home page'}
                 hideBackButton
                 formName={'MG request received'}>
      <Box className={'mb-40 flex-column flex-align-center'}>
        <Box display={'flex'} justifyContent={'center'} mb={'-10px'}>
          <img src={'/assets/img/congratulations.svg'} />
        </Box>

        <Card sx={{width: phone ? '100%' : '600px'}}>
          <div className={classes.header}>Here’s what to expect next:</div>
          <Box p={'20px 25px'}>
            <Typography className={'bold fs-18 mb-8'} color={'textSecondary'}>Here’s what to expect next:</Typography>
            <Box display={'flex'}>
              <Num value={'1'} />
              <div>
                <Typography className={'bold fs-18'} color={'textPrimary'}>We’ll be in touch soon - watch out for our call!</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>Your dedicated CoverRight licensed agent will be in touch to confirm your contact information and any other final questions required.</Typography>
              </div>
            </Box>
            <Box display={'flex'} className={'mt-20'}>
              <Num value={'2'} />
              <div>
                <Typography className={'bold fs-18'} color={'textPrimary'}>Your application will be submitted to the carrier</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>We’ll submit your application to your new health insurance company - no need to worry about any paperwork!</Typography>
              </div>
            </Box>
            <Box display={'flex'} className={'mt-20'}>
              <Num value={'3'} />
              <div>
                <div>
                  <Typography className={'bold fs-18'} color={'textPrimary'}>Receive confirmation from your new health insurer</Typography>
                  <Typography className={'fs-14'} color={'textPrimary'}>You’ll receive information from your new health insurance company within a few days to confirm successfull enrollment in your new plan.</Typography>
                </div>
              </div>
            </Box>
          </Box>
        </Card>
      </Box>
    </PageWrapper>
  </Layout>
}

