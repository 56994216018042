import { Box, Theme, Typography } from '@mui/material';
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { CRRadio } from '@coverright/ui/inputs';
import { SxProps } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radio: {
      border: '1px solid white',
      justifyContent: 'center',
      height: (props: CRStyledRadioProps) => props.height ? props.height : 280,
      flex: 1,
      width: '100%',
      maxWidth: 180,
      padding: '15px 22px 15px 15px!important',
      margin: '0!important',
      position: 'relative',
      backgroundColor: 'white'
    },
    mark: {
      position: 'absolute!important' as any,
      top: 0,
      left: 0
    }
  })
);

/* eslint-disable-next-line */
export interface CRStyledRadioProps {
  imgUrl?: string,
  imageClassName?: string,
  label: string | JSX.Element,
  checked: boolean,
  onClick: () => void,
  height?: number,
  sx?: SxProps,
}

export function CRStyledRadio(props: CRStyledRadioProps) {
  const classes = useStyles(props);
  return (
    <CRRadio checked={props.checked}
             sx={props.sx}
             controlClassName={classes.radio}
             className={classes.mark}
             onClick={props.onClick} label={(
      <Box p={'27px 0 0'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={{mb: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '91px'}}>
          {props.imgUrl && <img src={props.imgUrl} className={props.imageClassName} />}
        </Box>
        <Box textAlign={'center'}>
          <Typography className={'medium fs-18 lh-22'}>{props.label}</Typography>
        </Box>
      </Box>
    )} />
  );
}
