import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { HelpModal } from '@coverright/features/account-creation';
import { IntercomProvider, Layout, Preloader } from '@coverright/ui/shared';
import * as React from 'react';
import { Suspense } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AnimationContext, ProfileContext, ProfileProvider } from '@coverright/shared/contexts';
import { AppTheme } from '@coverright/ui/themes';
import { SnackbarProvider } from 'notistack';
import Main from './pages/main/Main';
import VisitInfo from './pages/visit-info/VisitInfo';
import ClientDetails from './pages/client-details/ClientDetails';
import PersonalInfo from './pages/personal-info/PersonalInfo';
import { KeycloakContext, withHashAuth, withKeycloakContext } from '@coverright/shared/keycloak';
import WantABook from './pages/want-a-book/WantABook';
import ScheduleACall from './pages/schedule-a-call/ScheduleACall';
import { AppStateActionTypes, AppStateContext, AppStateContextProvider } from './AppStateContext';
import MedicareType from './pages/medicare-type/MedicareType';
import * as _ from 'lodash';
import { useTrackAppointment } from '@coverright/shared/analytics';

function App(props: RouteComponentProps) {
  const [className, setClassName] = React.useState('question');
  const {keycloak} = React.useContext(KeycloakContext);
  const {firstName, lastName} = React.useContext(ProfileContext)
  const [state, dispatch] = React.useContext(AppStateContext);
  useTrackAppointment();

  React.useEffect(() => {
    dispatch({type: AppStateActionTypes.INIT});
    IntercomProvider.boot();
  }, [])

  const logout = () => {
    dispatch({type: AppStateActionTypes.RESET});
    keycloak.logout({ redirectUri: document.location.origin });
  };

  return (
    <Layout
      helpModalComponent={HelpModal}
      SideComponent={() => null}
      withoutDrawer
      sx={{mt: keycloak?.authenticated ? '42px' : undefined}}
      drawerOffset={keycloak?.authenticated ? '42px' : undefined}
      {...props}
    >
      {keycloak?.authenticated && <Box sx={{zIndex: 20000, p: '10px 20px', position: 'fixed', left: 0, top: 0, width: '100%', backgroundColor: '#FB0A0A'}}>
        <Typography className={'fs-18 bold white'} variant={'h4'}>{[firstName, lastName].join(' ')}'s profile. <span className={'underline pointer'} onClick={logout}>Close out</span></Typography>
      </Box>}
      <AnimationContext.Provider
        value={{ reverse: () => setClassName('question-reverse') }}
      >
        <Box
          mt={'8px'}
          minHeight={'700px'}
          id={'wrapper'}
          position={'relative'}
        >
          <TransitionGroup>
            <CSSTransition
              key={props.location.key}
              classNames={className}
              timeout={300}
              onEnter={() => {
                document.body.scrollIntoView({ behavior: 'auto' });
                document.body.style.overflowX = 'hidden';
              }}
              onExited={() => {
                document.body.style.overflowX = 'auto';
                setClassName('question');
              }}
            >
              <Switch location={props.location}>
                <Route
                  exact
                  path={'/visit-info'}
                  component={VisitInfo}
                />
                <Route
                  exact
                  path={'/client-details'}
                  component={ClientDetails}
                />
                <Route
                  exact
                  path={'/personal-info'}
                  component={PersonalInfo}
                />
                <Route
                  exact
                  path={'/want-a-book'}
                  component={WantABook}
                />
                <Route
                  exact
                  path={'/medicare-type'}
                  component={MedicareType}
                />
                <Route
                  exact
                  path={'/schedule-a-call'}
                  component={ScheduleACall}
                />
                <Route
                  exact
                  path={'/'}
                  component={Main}
                />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Box>
      </AnimationContext.Provider>
    </Layout>
  );
}

export default _.flowRight([withHashAuth, withKeycloakContext])(() => {
  return (
    <SnackbarProvider preventDuplicate classes={{
      variantSuccess: 'success-snack'
    }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3}>
      <ThemeProvider theme={AppTheme}>
        <Suspense fallback={<Preloader />}>
          <ProfileProvider>
            <AppStateContextProvider>
              <Switch>
                <Route path={'/:routeName?'} component={App} />
                <Route>
                  <Redirect to={'/'} />
                </Route>
              </Switch>
            </AppStateContextProvider>
          </ProfileProvider>
        </Suspense>
      </ThemeProvider>
    </SnackbarProvider>
  );
})
