import React, { useState } from 'react';
import {keycloak as kcGlobal, KeycloakRoles} from "./keycloak";
import { keycloakConfig } from './config';
import { Dialog, DialogTitle } from '@mui/material';
import { getClientId, resetStoredUser } from '@coverright/utils';
import Keycloak from 'keycloak-js';

interface UserToken {
  accessToken: string,
  idToken: string,
  refreshToken: string,
}

interface IKeycloakContext {
  authenticated: boolean,
  initialized: boolean,
  isAdmin: boolean,
  keycloak: Keycloak.KeycloakInstance,
  init: (data: UserToken) => Promise<any>,
  getHash: () => string,
  createLoginUrl: (redirectUri?: string) => string,
}

export const KeycloakContext = React.createContext<IKeycloakContext>({
  authenticated: false,
  initialized: false,
  isAdmin: false,
  keycloak: kcGlobal,
  init: () => new Promise<any>(resolve => null),
  getHash: () => '',
  createLoginUrl: () => '',
});

export const getIsAdmin = (keycloak: Keycloak) => keycloak.hasRealmRole(KeycloakRoles.ROLE_ADMIN) || keycloak.hasRealmRole(KeycloakRoles.ROLE_TECH_STAFF)

export function KeycloakProvider(props: React.PropsWithChildren<KeycloakProviderProps>) {
  const [keycloak, setKeycloak] = useState(props.keycloak || kcGlobal);
  const [initialized, setInitialized] = useState(false);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [authenticated, setAuthenticated] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (keycloak) {
      if (!(window as any).keycloak) {
        (window as any).keycloak = keycloak;
      }
      if (!initialized && !props.lateInit) {
        keycloak.init({
          token: sessionStorage.getItem('token'),
          refreshToken: sessionStorage.getItem('refreshToken'),
          idToken: sessionStorage.getItem('idToken'),
          onLoad: props.onLoad ? props.onLoad : 'check-sso',
          checkLoginIframe: true,
          silentCheckSsoRedirectUri: window.location.origin,
        }).then((authenticated: boolean) => {
          setInitialized(true);
          setAuthenticated(authenticated);
          setIsAdmin(getIsAdmin(keycloak))
        })
      }
    }
  }, [keycloak, initialized, props.lateInit])

  const getHash = React.useCallback(() => {
    if (keycloak?.authenticated) {
      return `#at=${keycloak?.token}&rt=${keycloak?.refreshToken}&it=${keycloak?.idToken}`
    }
    return '';
  }, [keycloak?.authenticated])

  const createLoginUrl = (redirectUri = document.location.href) => {
    return  `${keycloakConfig.url}/realms/${keycloakConfig.realm}/protocol/openid-connect/auth?client_id=${keycloakConfig.clientId}&redirect_uri=${encodeURI(redirectUri)}&response_mode=fragment&response_type=code&scope=openid`
  }

  const init = (options?: UserToken) => {
    return new Promise<void>((resolve, reject) => {
      if (keycloak) {
        keycloak.init({
          onLoad: 'check-sso',
          checkLoginIframe: false,
          token: options?.accessToken,
          refreshToken: options?.refreshToken,
          idToken: options?.idToken
        }).then((authenticated: boolean) => {
          // console.log(keycloak);
          setAuthenticated(authenticated || false);
          setIsAdmin(keycloak.hasRealmRole(KeycloakRoles.ROLE_ADMIN) || keycloak.hasRealmRole(KeycloakRoles.ROLE_TECH_STAFF))
          const xhr = new XMLHttpRequest();
          xhr.open('GET', `${keycloakConfig.url}realms/${keycloakConfig.realm}/set-cookie-by-token`, true);
          xhr.setRequestHeader('authorization', 'Bearer ' + keycloak?.token);
          xhr.withCredentials = true;
          xhr.onreadystatechange = function () {
            if(xhr.readyState === XMLHttpRequest.DONE) {
              const status = xhr.status;
              if (status === 0 || (status >= 200 && status < 400)) {
                // The request has been completed successfully
                resolve()
              } else {
                // Oh no! There has been an error with the request!
                reject(xhr.responseText)
              }
            }
          };
          xhr.send();
          /*return fetch(`${config.keycloak.url}realms/${config.keycloak.realm}/set-cookie-by-token`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers({
              authorization: keycloak?.token ? `Bearer ${keycloak?.token}` : "",
            })
          })*/
        });
      } else {
        reject()
      }
    })

  }

  if (isAdmin && !props.allowAdmin) {
    return <Dialog disableEscapeKeyDown open={true} PaperProps={{sx: {backgroundColor: 'rgb(192, 0, 0)'}}}>
      <DialogTitle sx={{color: 'white'}} className={'bold fs-18'}>You are an admin. Please <span className={'underline pointer'} onClick={() => {
        resetStoredUser();
        keycloak.logout()
      }}>log out</span>.</DialogTitle>
    </Dialog>
  }

  return  <KeycloakContext.Provider value={{initialized, authenticated, isAdmin, keycloak, init, getHash, createLoginUrl}}>
      {(props.lateInit || initialized) && props.children}
    </KeycloakContext.Provider>
}

export const withKeycloakContext = (WrappedComponent: any, allowAdmin = !!getClientId()) => (props: any) => {
  return (
    <KeycloakProvider allowAdmin={allowAdmin}>
      <WrappedComponent {...props} />
    </KeycloakProvider>
  )
}

type KeycloakProviderProps = {
  keycloak?: any,
  onLoad?: string,
  allowAdmin?: boolean,
  lateInit?: boolean
}
