import { makeStyles } from '@mui/styles';
import { colors } from '@coverright/ui/themes';
import { Box, Collapse, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { SxProps } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    background: 'rgba(200, 232, 235, 1)',
    borderRadius: 4,
    borderLeft: '11px solid #029094',
    padding: '10px 15px',
    marginTop: 8,
  },
  title: {
    fontWeight: 500,
  },
  description: {
    fontSize: 15,
    color: colors.text.primary,
  },
  lightIcon: {
    height: 20,
    width: 20,
    maxWidth: 'inherit'
  },
  icon: {
    transform: "rotate(0)",
    transition: "all 0.3s",
    marginLeft: 8,
  },
  iconOpen: {
    transform: "rotate(-180deg) !important",
  },
}));

type CRInfoProps = {
  open: boolean,
  onClick?: () => void,
  title?: string,
  hideTitle?: boolean,
  wrapperSxProps?: SxProps,
  descriptionClassName?: string,
  description: string | JSX.Element,
  variant?: 'standard' | 'contained',
  extra?: JSX.Element
}

export function CRInfo(props: CRInfoProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open);

  const onClick = () => {
    if (props.onClick) {
      props.onClick()
    } else {
      setOpen(!open)
    }
  }

  React.useEffect(() => {
      setOpen(props.open)
  }, [props.open]);

  return (
    <div>
      {!props.hideTitle && <Box sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={onClick}>
        <img className={classes.lightIcon} src={'/assets/img/light.png'} />
        <Typography className={'fs-16 medium'}>{props.title || 'Why do we need this?'}</Typography>
        <img className={clsx(classes.icon, open && classes.iconOpen)} src={"/assets/img/arrow.svg"}/>
      </Box>}
      <Collapse in={open}>
        <Box sx={props.wrapperSxProps} className={props.variant !== 'standard' ? classes.wrapper : undefined}>
          <div className={clsx(classes.description, props.descriptionClassName)}>{props.description}</div>
        </Box>
        {props.extra}
      </Collapse>
    </div>
  );
}
