import React, { FunctionComponent } from 'react';

import { createStyles, makeStyles } from '@mui/styles';
import { AppBar, Box, Button, Drawer, Hidden, Popover, Theme, Toolbar } from '@mui/material';
import SidePanel from '../side-panel/side-panel';
import { SxProps } from '@mui/material';
import { ContactsSection, Terms, TrustpilotWidget } from '../..';
import {RouteComponentProps} from "react-router";
import { ElevationScroll } from '@coverright/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      [theme.breakpoints.up('md')]: {
        marginTop: '66px',
      },
      backgroundColor: '#F5F7F6',
      flexDirection: 'column',
      padding: '16px 20px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '100vw',
      },
    },
    drawerPaper: {
      width: 320,
      backgroundColor: 'white',
    },
    mobileTitle: {
      fontWeight: 600,
      marginTop: 5,
    },
    phoneButton: {
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 49,
      height: 49,
      borderRadius: '50%',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
  }),
);

interface LayoutProps extends RouteComponentProps<any> {
  SideComponent: FunctionComponent<any>,
  sideHeaderContent?: JSX.Element,
  pin?: string,
  withoutDrawer?: boolean,
  hideBanners?: boolean,
  hideTerms?: boolean,
  drawerOffset?: string,
  helpModalComponent: any,
  hideBookACallButton?: boolean,
  agent?: {name: string, phone: string, img: string},
  sx?: SxProps
}

interface ILayoutContext {
  setDrawerOpen: (value: boolean) => void,
  setShowContacts: (value: boolean) => void,
  setTermsPositionFixed: (value: boolean) => void
  setHideTerms: (value: boolean) => void
}

export const LayoutContext = React.createContext<ILayoutContext>({setDrawerOpen: () => {}, setShowContacts: () => {}, setHideTerms: () => {}, setTermsPositionFixed: () => {}});

export function Layout({SideComponent, ...props}: React.PropsWithChildren<LayoutProps>) {
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [termsPositionFixed, setTermsPositionFixed] = React.useState(true);
  const [hideTerms, setHideTerms] = React.useState(false);
  const [showContacts, setShowContacts] = React.useState(true);
  const classes = useStyles({withoutDrawer: props.withoutDrawer || !drawerOpen});
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {!props.withoutDrawer && <Drawer
        variant="temporary"
        anchor={'left'}
        open={mobileOpen}
        sx={{display: {md: 'none'}}}
        onClose={() => setMobileOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{
          sx: {
            top: props.drawerOffset ? props.drawerOffset : undefined,
            height: props.drawerOffset ? `calc(100% - ${props.drawerOffset})` : '100%'
          }
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <SidePanel sideHeaderContent={props.sideHeaderContent}><SideComponent {...props} /></SidePanel>
      </Drawer>}
      <Box sx={{display: {xs: 'block', md: 'none'}}}>
        <ElevationScroll>
          <AppBar sx={{top: props.drawerOffset || 0}} className={classes.appBar}>
            <Toolbar style={{minHeight: '48px', padding: '3px 20px'}}>
              <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  {!props.withoutDrawer && <Button
                    sx={{ml: 0}}
                    startIcon={<img src={'/assets/img/menu.png'} />}
                    variant="text"
                    color="primary"
                    onClick={event => {
                      setAnchorEl(event.currentTarget);
                      if ((window as any).track) {
                        (window as any).track('Nav: Click main burger menu icon');
                      }
                    }}
                  >
                    MENU
                  </Button>}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    BackdropProps={{
                      sx: {
                        backgroundColor: 'transparent'
                      }
                    }}
                    PaperProps={{
                      sx: {
                        padding: '20px 24px'
                      }
                    }}
                    sx={{ml: '-15px', top: '20px', maxWidth: 1230}}
                    elevation={4}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <SideComponent {...props} />
                  </Popover>
                  <Box component={'img'} sx={{mr: 2}} src={'/assets/img/Logo.svg'} width={120} height={31} className={'pointer'} onClick={() => document.location.href = document.location.origin} />
                  {props.sideHeaderContent}
                </Box>
                <ContactsSection agent={props.agent} hideBookACallButton={props.hideBookACallButton} helpModalComponent={props.helpModalComponent} pin={props.pin} />
              </Box>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </Box>
      <Box sx={{display: {md: 'none'}, ...props.sx }} className={classes.container}>
        <Box sx={{display: {}}} display={'flex'} flex={1} justifyContent={'space-between'} alignItems={'center'} px={'10px'}>
          <Box display={'flex'} alignItems={'center'}>
            {!props.withoutDrawer && <Button
              sx={{ml: 0}}
              startIcon={<img src={'/assets/img/menu.png'} />}
              variant="text"
              color="primary"
              onClick={() => {
                setMobileOpen(mobileOpen => !mobileOpen)
                if ((window as any).track) {
                  (window as any).track('Nav: Click main burger menu icon');
                }
              }}
            >
              MENU
            </Button>}
            <img src={'/assets/img/Logo.svg'} width={91} />
          </Box>
          <a href="tel:8889697667"
             className={classes.phoneButton}
          >
            <img src={'/assets/img/phone.svg'} />
          </a>
        </Box>
        <LayoutContext.Provider value={{setDrawerOpen, setShowContacts, setTermsPositionFixed, setHideTerms}}>
          {props.children}
        </LayoutContext.Provider>
      </Box>
      {(!props.hideTerms && !hideTerms) && <Box sx={{position: {xl: termsPositionFixed ? 'fixed' : undefined}, bottom: 30, left: 20, zIndex: 200, marginLeft: '20px', marginBottom: {xs: '125px', xl: '30px'}}}>
        {!props.hideBanners && <Box sx={{display: 'flex', alignItems: 'center', gap: 2}} className={'mt-40 mb-10'}>
          <a href="https://www.bbb.org/us/ny/brooklyn/profile/medicare/coverright-inc-0121-87151389/#sealclick"
             id="bbblink" className="rbhzbus" target="_blank" rel="nofollow"><img
            src="https://seal-newyork.bbb.org/logo/rbhzbus/bbb-87151389.png" style={{border: 0}}
            alt="CoverRight Inc.  BBB Business Review"/></a>
          <TrustpilotWidget/>
        </Box>}
        <Terms />
      </Box>}
    </>
  );
}

