import { createStyles, makeStyles } from '@mui/styles';
import { Box, Button, IconButton, Modal, Theme, Typography } from '@mui/material';
import {CRModal} from '@coverright/ui/shared';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    img: {
      width: 72,
      height: 72
    }
  }),
);

interface ReceivedRequestModalProps {
  open: boolean,
  onClose: () => void
}

export const ReceivedRequestModal = (props: ReceivedRequestModalProps) => {
  const classes = useStyles();

  return <CRModal
    paperSx={{maxWidth: '590px'}}
    open={props.open}
    onClose={props.onClose}
  >
    <Box display={'flex'} flexDirection={'column'} flex={1}>
      <IconButton onClick={props.onClose} sx={{
        position: 'absolute',
        top: 8,
        right: 8,
      }}><img src={'/assets/img/close.svg'} /></IconButton>
      <Box display={'flex'} justifyContent={'center'} mb={3}>
        <Typography variant={'h1'} color={'textPrimary'} className={'text-align-centered'}>We’ve received your request!</Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Box component={'img'} sx={{
          width: 72,
          height: 72
        }} src={'/assets/img/big-green-check.svg'} />
      </Box>
      <Box display={'flex'} mt={'16px'} mb={'55px'} justifyContent={'center'}>
        <Typography variant={'h3'} color={'textSecondary'} className={'text-align-centered'}>A licensed CoverRight sales agent will be in touch soon. If it’s outside our business hours, an agent will call you back during our next business hours.</Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Button variant={'contained'} color={'primary'} size={'large'} onClick={props.onClose}>Return to plans</Button>
      </Box>
    </Box>
  </CRModal>;
}
