import {QueryHookOptions, useQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import { MedigapPlanName, PlanPriceByMonthOutput } from '@coverright/data-access/types/medigap';
import { client } from '@coverright/data-access/apollo-clients';

export function usePlanPricesByMonth(options?: QueryHookOptions<{planPricesByMonth: PlanPriceByMonthOutput[]}, {planName: MedigapPlanName}>) {
  return useQuery<{planPricesByMonth: PlanPriceByMonthOutput[]}, {planName: MedigapPlanName}>(gql(getPlan), {
    client,
    ...options,
  });
}

const getPlan = `
query($planName: MedigapPlanName!) {
  planPricesByMonth(planName: $planName) {
    month
    monthlyPremium
    parentOrgName
  }
}
`;

