import React from 'react';
import { InputProps } from '../text-input';
import { AdornmentWrapper } from '../ui/adornment-wrapper';
import { TickAdornment } from '../ui/tick-adornment';

export function useStartAdornment(props: InputProps, isValid: boolean) {
  return React.useMemo(() => {
    if (props.InputProps?.startAdornment) {
      const StAd = props.InputProps?.startAdornment;
      if (isValid && props.tickPosition !== 'start') {
        return <AdornmentWrapper>
          {StAd}
          {/*!props.hideTick && <TickAdornment sx={props.startAdornmentSx} />*/}
        </AdornmentWrapper>
      } else {
        return StAd
      }
    } else if (isValid && props.tickPosition === 'start' && !props.hideTick) {
      return <TickAdornment sx={props.startAdornmentSx} />
    }
    return undefined;
  }, [isValid, props])
}
