import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { FormControlLabel, Radio, RadioProps, Typography } from '@mui/material';
import { CheckCircle, Circle } from '@phosphor-icons/react';

const useStyles = makeStyles({
  root: {
    border: `1px solid #666666`,
    padding: '19px 16px',
    display: 'flex',
    flex: 1,
    borderRadius: 8,
    marginRight: '0!important',
    marginLeft: '0!important',
  },
  checkedRoot: {
    padding: '14px 15px',
    border: `2px solid #1C434F!important`,
    backgroundColor: '#DEEDEC!important',
  },
});

type V3RadioProps = RadioProps & {label: React.ReactNode};

export const V3Radio = (props: V3RadioProps) => {
  const classes = useStyles(props);
  const {label, ...rest} = props;
  return (
    <FormControlLabel className={clsx({[classes.root]: true, [classes.checkedRoot]: props.checked})}
                      sx={props.sx}
                      control={<Radio
                        sx={{p: '0px'}}
                        disableRipple
                        color="default"
                        checkedIcon={<CheckCircle size={32} color="#1C434F" weight="fill" />}
                        icon={<Circle size={24} color="#666666" weight="regular" />}
                        {...rest}
                      />}
                      label={<Typography variant={'body2'} sx={{color: props.checked ? '#1C434F' : '#666666', ml: '15px'}} className={'medium'}>{label}</Typography>}
    />

  );
}
