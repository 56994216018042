import { useHistory } from 'react-router';
import React from 'react';
import { CRInfo, PageWrapper } from '@coverright/ui/shared';
import { Box, Typography } from '@mui/material';
import { CreateAccountForm } from '@coverright/features/account-creation';
import { ProfileSource } from '@coverright/data-access/types/enrollment';
import { AppStateContext } from '../../AppStateContext';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { getStoredUser, setStoredUser } from '@coverright/utils';
import { AnimationContext } from '@coverright/shared/contexts';

export default function () {
  const history = useHistory();
  const [formik, setFormik] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const [state] = React.useContext(AppStateContext);
  const {keycloak} = React.useContext(KeycloakContext);
  const {reverse} = React.useContext(AnimationContext)

  const onSuccess = () => {
    if (state.visitInfo === 'Submit') {
      history.push('/want-a-book')
    } else {
      history.push('/medicare-type')
    }
  }

  return (<div>
    <PageWrapper title={'Provide your client’s contact information.'}
                 subtitle={'A licensed advisor will use this information to reach out.  Your client will also receive an email to log in to their dashboard.'}
                 formName={'personal-info'}
                 sx={{mt: {md: '70px'}}}
                 loading={loading}
                 nextButtonDisabled={loading || keycloak?.authenticated}
                 onNextButtonClick={formik?.handleSubmit}
                 onBackButtonClick={() => {
                   reverse && reverse();
                   setTimeout(() => history.push('/client-details'), 50)
                 }}
                 nextButtonExtra={(keycloak?.authenticated && !loading) ? <Typography align={'center'} className={'fs-14 red'}>You can't create new client<br />while you logged in.<br />Please <span className={'pointer underline'} onClick={() => {setStoredUser({...getStoredUser(), profileId: undefined}); keycloak.logout();}}>log out</span> to continue.</Typography> : undefined}
    >
      <div>
        <CreateAccountForm onLoadingChange={setLoading}
                           columns={undefined}
                           onSuccess={onSuccess}
                           setFormik={setFormik}
                           source={ProfileSource.UserFlowV2}
                           adviserEmail={state.adviserEmail}
                           adviserName={state.adviserFirstName + ' ' + state.adviserLastName}
                           showAll
                           showPhone />
      </div>
      <div className={'h-10'} />
      <CRInfo open={false} title={'How this information is used'} wrapperSxProps={{maxWidth: '500px'}}
              description={<>Our platform will ask you to enter preferences in order to provide personalized recommendations and options. Creating an account allows us to save your progress. You can also pick up where you left off and/or access your plan recommendations again at a later point in time.</>} />
      <div className={'h-40'} />
    </PageWrapper>
    <Box sx={{maxWidth: 700, mx: 'auto', mt: '-50px', mb: 20}}>
      <Typography className={'fs-11 lh-13 thin'} color={'textPrimary'}>
        By submitting my phone number and clicking ‘Continue’ above, I confirm that the phone number provided is my own, and I provide my express written consent to be contacted by CoverRight.com and its owner and operator CoverRight Inc., including its subsidiaries and/or affiliates at the email or phone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content,   through the use of text message and/or live, automated and pre-recorded telephone calls, even if my number is on a Do Not Call list.  I further understand and agree that my communications with CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</a>.<br /><br />
        This is a solicitation for insurance.  Your information will be provided to a licensed agent for future contact.<br /><br />

        I understand I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can revoke my consents at any time by emailing hello@coverright.com.
      </Typography>
    </Box>
  </div>)
}
