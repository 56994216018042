import * as _ from 'lodash';
import React from 'react';

export const withHashAuth = (WrappedComponent: any) => (props: any) => {

  if (isAuthInHash()) {
    const auth = _.fromPairs(document.location.hash.slice(1).split('&').map(v => v.split('=')));
    sessionStorage.setItem('token', auth.at);
    sessionStorage.setItem('refreshToken', auth.rt);
    sessionStorage.setItem('idToken', auth.it);
    window.history.replaceState(null, document.title, window.location.pathname + window.location.search);
  }

  return <WrappedComponent {...props} />
}

const isAuthInHash = (): boolean => {
  return document.location.hash.indexOf('at=') >= 0 && document.location.hash.indexOf('rt=') >= 0 && document.location.hash.indexOf('it=') >= 0
}
