import {Cookies} from "react-cookie";
const cookie = new Cookies();

export function setClientId(clientId: string) {
  const domain = '.' + Array.from(document.location.host.matchAll(/[a-z]+/g)).slice(-2).join('.');
  cookie.set('clientId', clientId, {
    domain,
    path: '/',
    expires: new Date(2030, 1, 1)
  })
}

export function getClientId(): string | undefined {
  const params = new URLSearchParams(document.location.search);

  if (params.has('clientId')) {
    sessionStorage.setItem('clientId', params.get('clientId')!)
  }

  const clientId = params.get('clientId') ?? sessionStorage.getItem('clientId') ?? cookie.get('clientId');

  return clientId || undefined;
}

export function removeClientId() {
  sessionStorage.removeItem('clientId')
}
