import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { MedigapQuoteOutput, QueryMedigapQuoteArgs } from '@coverright/data-access/types/medigap';

export  function useMedigapQuote(options?: LazyQueryHookOptions<{ medigapQuote: MedigapQuoteOutput }, QueryMedigapQuoteArgs>) {
  return useLazyQuery<{ medigapQuote: MedigapQuoteOutput }, QueryMedigapQuoteArgs>(
    gql(summaryQuery),
    {
      fetchPolicy: 'no-cache',
      ...options
    }
  );
}


const summaryQuery = `
query ($id: UUID!) {
  medigapQuote(id: $id) {
    cId
    id
    clientId
    drugDeliveryType
    preferredDrugs {
      dosageRxcui
      frequency
      productRxcui
      purchaseFrequency
      quantity
    }
    medigapFilterState {
      age
      benefits
      companies
      county
      effectiveDate
      gender
      id
      monthlyPlanPremiumRanges
      planCategory
      planNames
      tobacco
      zip
    }
    preferredPharmacies
    userId
  }
}
`;
