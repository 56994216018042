import React from "react";
import { FormControlLabel, Radio as MuiRadio, RadioProps as MuiRadioProps, Typography } from '@mui/material';
import { CheckCircle, Circle } from '@phosphor-icons/react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    border: `1px solid #B3B3B3`,
    padding: '16px',
    display: 'flex',
    flex: 1,
    borderRadius: 8,
    marginRight: '0!important',
    marginLeft: '0!important',
    alignItems: 'flex-start',
  },
  checkedRoot: {
    padding: '16px',
    border: `1px solid #1C434F!important`,
    backgroundColor: '#1E95A0!important',
  },
});

type QuizRadioProps = MuiRadioProps & {label: React.ReactNode};

export const Radio = (props: QuizRadioProps) => {
  const classes = useStyles(props);
  const {label, ...rest} = props;
  return (
    <FormControlLabel className={`${classes.root} ${props.checked ? classes.checkedRoot : ''}`}
                      sx={props.sx}
                      control={<MuiRadio
                        sx={{p: '0px'}}
                        disableRipple
                        color="default"
                        checkedIcon={<CheckCircle size={32} color="#ffffff" weight="fill" />}
                        icon={<Circle size={32} color="#1C434F" weight="regular" />}
                        {...rest}
                      />}
                      label={<Typography variant={'body2'} sx={{color: props.checked ? '#FFF' : '#000', ml: '16px'}} className={'medium'}>{label}</Typography>}
    />

  );
}

