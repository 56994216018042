import { Typography } from '@mui/material';
import * as React from 'react';

interface Props {
  showLabel?: boolean;
  countyname?: string;
  state?: string;
}

export function CountyLabel(props: Props) {
  const { showLabel, countyname, state } = props;
  return <>
    {showLabel && <Typography sx={{ml: '18px', mt: '0'}}
                              data-test={'county-label'}
                              className={'fs-18 lh-22 medium'}>
      County
    </Typography>}
    <Typography sx={{
      ml: '18px',
      mt: showLabel ? '26px' : '-18px',
      mb: showLabel ? undefined : '18px'
    }}
                data-test={'county-value'}
                className={'fs-16 lh-20 medium'}>
      {countyname + ' County, ' + state}
    </Typography>
  </>
}
