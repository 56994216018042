import React, { FunctionComponent, ReactComponentElement } from 'react';
import clsx from "clsx";
import { colors } from '@coverright/ui/themes';
import { styled } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { SxProps } from '@mui/material';
import { Box, Button, Theme, Typography } from '@mui/material';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerHelpText: {
      fontSize: 14,
      color: colors.text.primary,
      '& span': {
        marginRight: 3
      },
      marginTop: 2
    },
    phone: {
      color: colors.custom.green.variant5,
      display: 'flex',
      borderRadius: 4,
      fontSize: 20,
      lineHeight: '25px',
      fontWeight: 700,
      margin: '0 0 0 8px',
      textDecoration: 'none',
      '& img': {
        marginRight: 8
      }
    },
    phoneButton: {
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 49,
      height: 49,
      borderRadius: '50%',
    },
    alignRight: {
      textAlign: 'right'
    },
    pin: {
      letterSpacing: 2
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
    }
  }),
);

interface ContactsSectionProps {
  sx?: SxProps,
  pin?: string,
  helpModalComponent: any,
  hideBookACallButton?: boolean,
  agent?: {name: string, phone: string, img: string}
}

export function ContactsSection({helpModalComponent, sx, hideBookACallButton, agent}: ContactsSectionProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const Modal: any = helpModalComponent;

  return <Box sx={sx} className={classes.wrapper}>
    <Box mb={'4px'}>
      <div className={clsx(classes.headerHelpText, classes.alignRight)}><span className={'semi-bold'}>Need help?</span> Speak to one of our licensed insurance agents</div>
    </Box>
    <Box mb={'4px'}  display={'flex'} alignItems={'center'}>
      {!agent && <a href="tel:8889697667" className={classes.phone}>
        <img src={'/assets/img/phone.svg'} width={18} />
        +1 (888) 969-7667 | TTY: 711
      </a>}
      {!!agent && <Box sx={{display: 'flex', gap: 1, alignItems: 'center', textDecoration: 'none'}} component={'a'} href={`tel:${agent.phone}`}>
        <img src={agent.img} width={40} height={40} />
        <div>
          <Typography sx={{color: colors.custom.green.variant5, textDecoration: 'none'}} className={'fs-15 lh-20 bold ls-1'}>{agent.name}</Typography>
          <Typography className={'fs-16 lh-16'}>{agent.phone}</Typography>
        </div>
      </Box>}
      {!hideBookACallButton && <BookButton className={'gtm-button-event ml-16'}
                  data-gtm-button-name={'BOOK_A_CALL_BUTTON'}
                  color={'primary'}
                  onClick={() => setOpen(true)}
                  variant={'rounded'}>Book a call</BookButton>}
    </Box>
    <Box>
      <div className={clsx(classes.headerHelpText, classes.alignRight)}>Mon - Fri 8AM-10PM ET<span className={'mh-10'}>|</span>Sat - Sun 9AM-6PM ET</div>
    </Box>
    <Modal open={open} onClose={() => setOpen(false)} />
  </Box>
}

const BookButton = styled(Button)({
    fontSize: 12,
})
