import * as React from 'react';
import { getIsAdmin, KeycloakContext, KeycloakRoles } from '@coverright/shared/keycloak';
import {
  getCId,
  getStoredUser, getUId, resetStoredUser,
  setMedigapQuoteId, setPdpQuoteId,
  setQuoteId,
  setStoredUser
} from '@coverright/utils';
import { UserProfileInput, UserProfileOutput } from '@coverright/data-access/types/enrollment';
import { useMyClientId, useSaveUserProfile, useUserProfileLazy } from '@coverright/data-access/enrollment';

type IProfileContext = UserProfileOutput & {clientId?: string, save: (data: {variables:  {data: UserProfileInput}}) => any};

export const ProfileContext = React.createContext<IProfileContext>({save: () => null, profileId: ''});

export function ProfileProvider(props: React.PropsWithChildren<any>) {

  const {keycloak, initialized} = React.useContext(KeycloakContext);
  const [getProfile, {data: profileData, loading: getProfileLoading}] = useUserProfileLazy();
  const [saveProfile, saveProfileData] = useSaveUserProfile({
    onCompleted: data => setStoredUser({...getStoredUser(), profileId: data?.saveUserProfile?.profileId || getStoredUser()?.profileId})
  })
  const [profile, setProfile] = React.useState<UserProfileOutput>();
  const [clientId, setClientId] = React.useState<string>();
  const [getClientId] = useMyClientId();

  React.useEffect(() => {
    if (initialized) {
      if (keycloak?.authenticated) {
        if (!getIsAdmin(keycloak)) {
          getProfile().then(() => getClientId().then(res => {
            setStoredUser({...getStoredUser(), clientId: res.data?.myClientId || getStoredUser()?.clientId})
            setClientId(res.data?.myClientId)
          }));
        }
      } else if (getStoredUser()?.profileId) {
        resetStoredUser();
      }
    }
  }, [keycloak?.authenticated, initialized]);

  React.useEffect(() => {
    if (profileData?.userProfile) {
      setStoredUser({
        email: profileData?.userProfile?.email || undefined,
        firstName: profileData?.userProfile?.firstName || undefined,
        lastName: profileData?.userProfile?.lastName || undefined,
        zip: profileData?.userProfile?.zip || undefined,
        gender: profileData?.userProfile?.gender || undefined,
        tobacco: typeof profileData?.userProfile?.tobacco !== 'undefined' ? String(profileData?.userProfile?.tobacco) : undefined,
        countyName: profileData?.userProfile?.countyName || undefined,
        birthDate: profileData?.userProfile?.birthDate || undefined,
        profileId: profileData?.userProfile?.profileId || undefined,
        phoneNumber: profileData?.userProfile?.phoneNumber || undefined,
        state: profileData?.userProfile?.state || undefined,
        userId: profileData?.userProfile?.userId || undefined,
      })
      const urlParams = new URLSearchParams(window.location.search);
      if (profileData?.userProfile?.quoteId && !urlParams.has('adminQuote')) {
        setQuoteId(profileData?.userProfile?.quoteId);
      }
      if (profileData?.userProfile?.medigapQuoteId && !urlParams.has('adminQuote')) {
        setMedigapQuoteId(profileData?.userProfile?.medigapQuoteId);
      }
      if (profileData?.userProfile?.pdpQuoteId && !urlParams.has('adminQuote')) {
        setPdpQuoteId(profileData?.userProfile?.pdpQuoteId);
      }
      (window as any).hj = (window as any).hj || function(...args: any){((window as any).hj.q=(window as any).hj.q || []).push(args)};
      const identifyData = {
        email: getStoredUser()?.email,
        c_id: getCId(),
        uid: getUId(),
      };
      (window as any).hj('identify', getCId() || null, identifyData);
    }
    setProfile(profileData?.userProfile)
  }, [profileData]);

  return <ProfileContext.Provider value={{...profile, clientId, save: saveProfile} as any}>
    {props.children}
  </ProfileContext.Provider>

}

export const withProfileContext = (WrappedComponent: any) => (props: any) => {
  return (
    <ProfileProvider>
      <WrappedComponent {...{...props}} />
    </ProfileProvider>
  )
}
