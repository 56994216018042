import {useMutation, MutationHookOptions} from "@apollo/client";
import {gql} from "@apollo/client";
import { FetchResult } from '@apollo/client';
import {
  MutationSavePdpQuoteArgs, PdpQuoteInput, PdpQuoteOutput
} from '@coverright/data-access/types/medicare';
import { client } from '@coverright/data-access/apollo-clients';

export function useSavePdpQuote(options?: MutationHookOptions<{savePdpQuote: PdpQuoteOutput}, MutationSavePdpQuoteArgs>) {
  return useMutation<{savePdpQuote: PdpQuoteOutput}, MutationSavePdpQuoteArgs>(
    gql(saveRequestMutation),
    options
  );
}

export const savePdpQuote = (data: PdpQuoteInput): Promise<FetchResult<{ savePdpQuote: MutationSavePdpQuoteArgs }>> =>
  client.mutate({
    mutation: gql(saveRequestMutation),
    variables: { data }
  });

const saveRequestMutation = `
mutation ($clientId: UUID, $input: PdpQuoteInput!, $userId: UUID) {
  savePdpQuote(input: $input, clientId: $clientId, userId: $userId) {
    cId
    clientId
    countyName
    id
    insulinSavings
    zip
  }
}`;
