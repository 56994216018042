import { PhoneNumberInput } from '@coverright/ui/inputs';
import { CRModal, ReceivedRequestModal } from '@coverright/ui/shared';
import { colors } from '@coverright/ui/themes';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Button, IconButton, Theme, Typography } from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { ProfileOption, ProfileSource } from '@coverright/data-access/types/enrollment';
import { useSaveUserProfile, validatePhone } from '@coverright/data-access/enrollment';
import * as React from 'react';
import { Calendly, CalendlyCallType } from '@coverright/features/shared';
import { useLogEvent } from '@coverright/shared/analytics';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: colors.custom.green.variant1,
      textAlign: "center",
      cursor: "pointer"
    },
    phone: {
      color: colors.custom.green.variant5,
      display: "flex",
      borderRadius: 4,
      fontSize: 26,
      letterSpacing: '-1px',
      fontWeight: 700,
      textDecoration: "none",
      "& img": {
        marginRight: 10
      }
    },
    checkboxWrapper: {
      alignItems: "flex-start",
    },
    wrapper: {
      minWidth: 500,
      [theme.breakpoints.down("xs")]: {
        minWidth: "auto",
      },
    },
    nameWrapper: {
      display: "flex",
      alignItems: "flex-end",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    icon: {
      position: "absolute!important" as any,
      top: 8,
      right: 8,
    },
    textLink: {
      color: colors.text.primary,
    }
  })
);

type EmployedModalProps = {
  onClose: () => void,
  open: boolean
}

export function HelpModal(props: EmployedModalProps) {
  enum ModalMode {
    Entry,
    Calendly,
    CallRequest,
    ReceivedRequest
  }

  const classes = useStyles();
  const [modalMode, setModalMode] = React.useState<ModalMode | undefined>()
  const [calendly, setCalendly] = React.useState(false);
  const logEvent = useLogEvent();

  React.useEffect(() => {
    if (props.open) {
      setModalMode(ModalMode.Entry)
      setCalendly(false)
      logEvent('Book a call - open modal', {source: 'V2'})
    }
  }, [props.open])

  const showEntry = modalMode == ModalMode.Entry
  const showCalendly = modalMode == ModalMode.Calendly
  const showCallRequest = modalMode == ModalMode.CallRequest
  const showReceivedRequest = modalMode == ModalMode.ReceivedRequest

  return <>
    <CRModal
      width={modalMode == ModalMode.Calendly ? 690 : 390}
      paperPadding={'30px'}
      disableSidePadding
      open={props.open && (showEntry || showCalendly)}
      onClose={props.onClose}
      modalScroll
    >
      <>
        <IconButton onClick={props.onClose} className={classes.icon}><img src={'/assets/img/close.svg'} /></IconButton>
        {showEntry && <Entry onCallClick={() => setModalMode(ModalMode.CallRequest)}
                             onCalendlyClick={() => {
                               setCalendly(true)
                               setModalMode(ModalMode.Calendly)
                             }} />}
        {showCalendly && <CalendlyModal onClose={props.onClose} />}
      </>
    </CRModal>
    <CallRequest onSubmit={() => {
      if (calendly) {
        setModalMode(ModalMode.Calendly)
      } else {
        setModalMode(ModalMode.ReceivedRequest)
      }
    }} onBack={() => {setModalMode(ModalMode.Entry)}} buttonTitle={calendly ? 'Schedule a call' : 'Request call back'} onClose={props.onClose} open={props.open && showCallRequest}/>
    <ReceivedRequestModal onClose={props.onClose} open={props.open && showReceivedRequest}/>
  </>
}

const validationSchema = yup.object().shape({
  phoneNumber: yup.string()
    .required("Phone number is required")
    .test("test-phone",
      "Please enter a valid phone",
      validatePhone)
});

const CallRequest = (props: { open: boolean, buttonTitle: string, onClose: () => void, onSubmit: () => void, onBack: () => void }) => {
  const classes = useStyles();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [requestACall] = useSaveUserProfile();
  const logEvent = useLogEvent();

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      zip: '',
    },
    validationSchema,
    onSubmit: values => {
      if (formik.isValid) {
        logEvent('Book a call - book a call', {source: 'V2', phoneNumber: values.phoneNumber})
        requestACall({
          variables: {
            data: {
              phoneNumber: values.phoneNumber,
              options: [ProfileOption.RequestCall],
              cId: (window as any).gaGlobal?.vid,
              source: ProfileSource.CallRequest,
              insuranceType: 'MA'
            }
          }
        }).then((res) => {
          // enqueueSnackbar("Request submitted", {variant: "success"});
          logEvent('profile-saved', {'profile_id': res.data?.saveUserProfile.profileId, source: ProfileSource.CallRequest})
          props.onSubmit();
        }).catch(() => {
          enqueueSnackbar("Error", {variant: "error"}); // todo add text
          props.onClose();
        });
      }
    },
    validateOnChange: true,
  });

  return <CRModal
    width={390}
    disableSidePadding
    open={props.open}
    onClose={props.onClose}
    modalScroll
  >
    <>
      <Box display={"flex"} flexDirection={"column"} flex={1}>
        <IconButton onClick={props.onClose} className={classes.icon}><img src={'/assets/img/close.svg'} /></IconButton>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography align={'center'} className={'fs-26 lh-32 medium'}>Need some help?</Typography>
        </Box>
        <Box>
          <Button
            sx={{color: colors.text.primary}}
            startIcon={<img src={"/assets/img/arrow-left.svg"}/>}
            onClick={props.onBack}>
            Back
          </Button>
        </Box>
        <Box display={"flex"} mb={'20px'} justifyContent={"center"}>
          <Typography className={'fs-24 lh-30'} align={'center'}>Enter your number</Typography>
        </Box>
        <form onSubmit={formik.handleSubmit} autoComplete={"off"}>
          <Box flex={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box className={`${classes.wrapper} w-300`}>
              <PhoneNumberInput onChange={value => formik.setFieldValue('phoneNumber', value)}
                                name="phone"
                                label={''}
                                placeholder="Phone number"
                                error={formik.errors.phoneNumber}
                                isValid={() => !formik.values.phoneNumber || formik.values.phoneNumber !== '1' && !formik.errors.phoneNumber}
              />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Button variant={"contained"}
                    sx={{width: '310px', mt:'5px'}}
                    size={'large'}
                    color={'primary'}
                    className={'gtm-button-event'}
                    data-gtm-button-name={'CREATE_PROFILE_BUTTON'}
                    disabled={!formik.isValid}
                    type={"submit"}>
              Submit
            </Button>
          </Box>
        </form>
        <Typography className={"mt-16 fs-11 lh-14 thin"}>
          By submitting my phone number and clicking ‘Submit’ above, I confirm that the email and phone number provided is my own, and I provide my express written consent to be contacted by CoverRight.com and its owner and operator CoverRight Inc., including its subsidiaries and/or affiliates at the email or phone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content, through the use of text message and/or live, automated and pre-recorded telephone calls, even if my number is on a Do Not Call list. I further understand and agree that my communications with CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</a>.<br /><br />
          This is a solicitation for insurance.  Your information will be provided to a licensed agent for future contact.<br /><br />
          I understand I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can revoke my consents at any time by emailing hello@coverright.com.
        </Typography>
      </Box>
    </>
  </CRModal>
}

const CalendlyModal = (props: {onClose: () => void}) => {
  const classes = useStyles();

  return <Box display={"flex"} flexDirection={"column"} flex={1}>
    <IconButton onClick={props.onClose} className={classes.icon}><img src={'/assets/img/close.svg'} /></IconButton>
    <Box display={"flex"} justifyContent={"center"}>
      <Typography align={'center'} className={'fs-26 lh-32 medium'}>Select a time that works for you</Typography>
    </Box>
    <Box display={"flex"} mt={"8px"} justifyContent={"center"}>
      <Typography className={'fs-24 lh-30'} align={'center'}>You’ll be emailed a confirmation with call<br />details once you submit.</Typography>
    </Box>
    <Box display={"flex"} mt={"11px"} justifyContent={"center"} position={'relative'}>
       <Calendly type={CalendlyCallType.consultation} onEventScheduled={props.onClose} />
    </Box>

  </Box>
}

const Entry = ({onCalendlyClick, onCallClick}: { onCalendlyClick: () => void, onCallClick: () => void }) => {
  const classes = useStyles();
  return <Box display={"flex"} flexDirection={"column"} flex={1}>
    <Box display={"flex"} justifyContent={"center"}>
      <Typography align={'center'} className={'fs-26 lh-32 medium'}>Need some help?</Typography>
    </Box>
    <Box display={"flex"} mt={"24px"} justifyContent={"center"}>
      <Typography className={'fs-24 lh-30'} align={'center'}>Speak to a licensed <br/>CoverRight advisor</Typography>
    </Box>
    <Box display={"none"} mt={4} justifyContent={"center"}>
      <Button fullWidth size={'large'} variant={"contained"} color={'primary'} onClick={onCallClick}>Request a call back now</Button>
    </Box>
    <Box display={"flex"} mt={4} justifyContent={"center"}>
      <Button fullWidth size={'large'} variant={"contained"} color={'primary'} onClick={onCalendlyClick}>Schedule a call for later</Button>
    </Box>
    <Box mt={4}>
      <Typography  className={'fs-18 lh-22'} align={'center'}>Or you can reach us by calling:</Typography>
    </Box>
    <Box display={"flex"} mt={"16px"} justifyContent={"center"}>
      <a href="tel:8889697667" className={classes.phone}>
        <img src={"/assets/img/phone.svg"} width={24} height={24}/>
        888-969-7667 | TTY: 711
      </a>
    </Box>
  </Box>
}
