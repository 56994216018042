import { MutationHookOptions, useMutation } from '@apollo/client';
import {gql} from "@apollo/client";
import { MutationPatchClientPersonalInfoArgs } from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';

export function usePatchClientPersonalInfo(options?: MutationHookOptions<{patchClientPersonalInfo: boolean}, MutationPatchClientPersonalInfoArgs>) {
  return useMutation<{patchClientPersonalInfo: boolean}, MutationPatchClientPersonalInfoArgs>(
    gql(saveEnrollmentMutation),
    {
      client: enrollmentClient,
      ...options
    }
  );
}

const saveEnrollmentMutation = `
mutation ($clientId: UUID!, $preferredDrugs: [PreferredDrugInput!], $preferredPharmacies: [String!], $drugDeliveryType: ClientDrugDeliveryType) {
  patchClientPersonalInfo(clientId: $clientId, preferredDrugs: $preferredDrugs, preferredPharmacies: $preferredPharmacies, drugDeliveryType: $drugDeliveryType)
}
`;
