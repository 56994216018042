import * as React from 'react';
import * as _ from 'lodash';
import { KeycloakContext } from '@coverright/shared/keycloak';
import {
  getCId,
  getMedigapQuoteId,
  getStoredUser, listenMedigapQuoteChange,
  setMedigapQuoteId,
  setStoredUser
} from '@coverright/utils';
import { ProfileContext, withProfileContext } from './ProfileContext';
import { FetchResult, MutationHookOptions } from '@apollo/client';
import {
  Gender,
  MedigapQuoteOutput,
  MutationSaveMedigapQuoteArgs,
  SaveMedigapQuoteOutput
} from '@coverright/data-access/types/medigap';
import { usePatchClientPersonalInfo } from '@coverright/data-access/enrollment';
import { useMedigapQuote, useSaveMedigapQuote } from '@coverright/data-access/medigap';
import { MutationPatchClientPersonalInfoArgs } from '@coverright/data-access/types/enrollment';
import { AdminContext } from './AdminContext';


type IMedigapQuoteContext = MedigapQuoteOutput & {
  save: (data: MutationHookOptions<{ saveMedigapQuote: SaveMedigapQuoteOutput }, MutationSaveMedigapQuoteArgs>) => Promise<FetchResult<{ saveMedigapQuote: SaveMedigapQuoteOutput }>>,
  refresh: () => void,
  saveClientInfo: (data: MutationHookOptions<{ patchClientPersonalInfo: boolean }, MutationPatchClientPersonalInfoArgs>) => Promise<FetchResult<{patchClientPersonalInfo: boolean}>>,
};

const defaultValue: IMedigapQuoteContext = {
  save: () => new Promise<FetchResult<{saveMedigapQuote: SaveMedigapQuoteOutput}>>(() => null),
  saveClientInfo: () => new Promise<FetchResult<{patchClientPersonalInfo: boolean}>>(() => null),
  refresh: () => null,
  id: '',
  clientId: '',
  preferredDrugs: [],
  preferredPharmacies: [],
  medigapFilterState: {
    id: '',
    zip: '',
    age: 65,
    gender: Gender.F,
    tobacco: false,
    companies: []
  }
}


export const MedigapQuoteContext = React.createContext<IMedigapQuoteContext>(defaultValue);

function Provider(props: React.PropsWithChildren<QuoteProviderProps>) {

  const adminContext = React.useContext(AdminContext);
  const {keycloak} = React.useContext(KeycloakContext);
  const profile = React.useContext(ProfileContext);

  const [saveClientInfo, {loading}] = usePatchClientPersonalInfo({
    onCompleted: () => getMedigapQuote({variables: {id: getMedigapQuoteId()}})
  })

  const [save] = useSaveMedigapQuote({
    onCompleted: (data: any) => {
      setMedigapQuoteId(data.saveMedigapQuote.mgQuoteId);
      if (profile?.profileId && !profile.medigapQuoteId) {
        profile.save({variables: {
            data: {
              medigapQuoteId: data.saveMedigapQuote.mgQuoteId,
              cId: getCId(),
              profileId: profile.profileId,
            }
          }})
      }
      return getMedigapQuote({variables: {id: data.saveMedigapQuote.mgQuoteId}});
    }
  });

  React.useEffect(() => {
    if (adminContext?.clientView?.medigapQuote?.id) {
      setMedigapQuoteId(adminContext?.clientView?.medigapQuote?.id)
    }
  }, [adminContext?.clientView?.medigapQuote?.id]);

  const quoteId = React.useMemo(() => {
    return adminContext?.clientView?.medigapQuote?.id || props.quoteId;
  }, [props.quoteId, adminContext?.clientView?.medigapQuote?.id]);

  const [quote, setQuote] = React.useState<MedigapQuoteOutput>();

  const [getMedigapQuote] = useMedigapQuote({
    onCompleted: data => {
      if (!_.isEqual(quote, data?.medigapQuote)) {
        setStoredUser({
          ...getStoredUser(),
          gender: data?.medigapQuote?.medigapFilterState?.gender,
          zip: data?.medigapQuote?.medigapFilterState?.zip,
          countyName: data?.medigapQuote?.medigapFilterState?.county || undefined,
          tobacco: data?.medigapQuote?.medigapFilterState?.tobacco === true ? 'true' : 'false',
        })
        setQuote(data?.medigapQuote);
      }
    }
  });

  React.useEffect(() => {
    listenMedigapQuoteChange(callback)
  }, []);

  React.useEffect(() => {
    callback(quoteId);
  }, [quoteId, keycloak?.authenticated]);

  const callback = (quoteId?: string) => {
    if (quoteId) {
      getMedigapQuote({variables: {id: quoteId}});
    }
  };

  const refresh = () => callback(getMedigapQuoteId());

  const result = React.useMemo(() => {
      return {...quote, save, saveClientInfo, refresh}
  }, [save, quote, saveClientInfo]);

  return <MedigapQuoteContext.Provider value={result as any}>
    {props.children}
  </MedigapQuoteContext.Provider>

}

export const MedigapQuoteProvider = withProfileContext(Provider);

type QuoteProviderProps = {
  quoteId?: string,
  private?: boolean,
}

export const withMedigapQuoteContext = (WrappedComponent: any) => (props: any) => {
  return (
    <MedigapQuoteProvider quoteId={getMedigapQuoteId()}>
      <WrappedComponent {...{...props}} />
    </MedigapQuoteProvider>
  )
}
