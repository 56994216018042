import { HttpLink, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import {getToken} from "./ApolloAuthClient";
import { errorTracker, graphQLErrorHandler } from './GraphQLErrorHandler';

const httpLink = new HttpLink({
  uri: process.env.NX_CONTENT_ADMIN_GRAPHQL,
  headers: {
    "content-type": "application/json",
     // version: config.buildVersion,
  }
});


const elink = onError((errResponse: ErrorResponse) => {
  try {
    getToken().then(token => errorTracker(errResponse, token)).catch(() => errorTracker(errResponse));
  } catch (e) {
    errorTracker(errResponse);
  }
  if (graphQLErrorHandler) {
    graphQLErrorHandler(errResponse);
  }
});

const link =
  from([
    elink,
    httpLink,
  ])
;

export const contentAdminApolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});
