import { Box, Collapse, Grid, GridDirection } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getCId, getMedigapQuoteId, getStoredUser, setStoredUser, getQuoteId, getPdpQuoteId } from '@coverright/utils';
import * as _ from 'lodash';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { PhoneNumberInput, TextInput } from '@coverright/ui/inputs';
import { ProfileOption, ProfileSource } from '@coverright/data-access/types/enrollment';
import { useSaveUserProfile, validatePhone } from '@coverright/data-access/enrollment';
import { GraphQLErrorType } from '@coverright/data-access/apollo-clients';
import * as React from 'react';
import { useLogEvent } from '@coverright/shared/analytics';

const validationSchema = {
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
};
const phoneField = yup.string()
  .required("Phone number is required")
  .test("test-phone",
    "Please enter a valid phone",
    validatePhone)


const promise = (res: any) => {
  return new Promise((resolve, reject) => {
    const ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", "https://trkdis.com/l/con?cbiframe=1&oid=77108");
    ifrm.style.width = "1px";
    ifrm.style.height = "1px";
    document.body.appendChild(ifrm);
    setTimeout(() => {
      resolve(res)
    }, 3000);
  });
}

type CreateAccountFormProps = {
  setFormik: (formik: any) => void,
  onSuccess: () => void,
  onError?: () => void,
  columns?: number,
  showPhone: boolean,
  source: ProfileSource,
  onLoadingChange: (value: boolean) => void,
  adviserName?: string,
  adviserEmail?: string,
  showAll?: boolean
}

export const CreateAccountForm = (props: CreateAccountFormProps) => {
  const {init, keycloak, createLoginUrl} = React.useContext(KeycloakContext);
  const [loading, setLoading] = React.useState(false);
  const logEvent = useLogEvent();

  const [saveProfile] = useSaveUserProfile()

  React.useEffect(() => {
    props.onLoadingChange(loading)
  }, [loading]);

  const formik = useFormik({
    initialValues: {
      email: getStoredUser()?.email || '',
      firstName: getStoredUser()?.firstName || '',
      lastName: getStoredUser()?.lastName || '',
      phoneNumber: getStoredUser()?.phoneNumber || '',
    },
    validationSchema: yup.object(props.showPhone ? {...validationSchema, phoneNumber: phoneField} : validationSchema),
    onSubmit: values => {
      if (formik.isValid) {
        setLoading(true);
        setStoredUser({...getStoredUser(), ...values})
        saveProfile({
          variables: {
            data: {
              email: _.trim(values.email),
              firstName: _.trim(values.firstName),
              lastName: _.trim(values.lastName),
              phoneNumber: _.trim(values.phoneNumber),
              cId: getCId(),
              profileId: getStoredUser()?.profileId,
              source: props.source,
              options: [ProfileOption.RegisterUser],
              quoteId: getQuoteId(),
              medigapQuoteId: getMedigapQuoteId(),
              pdpQuoteId: getPdpQuoteId(),
              gender: getStoredUser()?.gender,
              birthDate: getStoredUser()?.birthDate,
              tobacco: typeof getStoredUser()?.tobacco !== 'undefined' ? getStoredUser()?.tobacco === 'true' : undefined,
              zip: getStoredUser()?.zip,
              countyName: getStoredUser()?.countyName,
              state: getStoredUser()?.state,
              adviserEmail: props.adviserEmail,
              enrolled: getStoredUser()?.enrolled,
              adviserName: props.adviserName
            }
          }
        }).then(promise).then((res: any) => {
          (window as any).gtm_profile_id = res.data?.saveUserProfile?.profileId;
          if (!getStoredUser()?.profileId) {

            /*(window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push('js', new Date());
            (window as any).dataLayer.push('config', 'AW-324504598');
            (window as any).dataLayer.push('event', 'conversion', {
              'send_to': 'AW-324504598/RGy6CNi4j-QCEJaY3poB',
              'event_callback': () => {
                if ((window as any).track) {
                  (window as any).track('GoogleAds conversion sent');
                } else {
                  console.warn('No track on the page')
                }
              }
            });*/

            logEvent('profile-saved', {'profile_id': res.data?.saveUserProfile?.profileId, source: props.source})

            if ((window as any).hj) {
              (window as any).hj('identify', getCId(), {
                email: getStoredUser()?.email,
                c_id: getCId(),
              })
            } else {
              logEvent('error', { message: 'No hotjar on the page' })
              console.warn('No hotjar on the page')
            }
          }

          setStoredUser({...getStoredUser(), profileId: res.data?.saveUserProfile.profileId})
          if (res.data?.saveUserProfile?.userToken) {
            init({
              idToken: res.data.saveUserProfile.userToken.idToken,
              accessToken: res.data.saveUserProfile.userToken.accessToken,
              refreshToken: res.data.saveUserProfile.userToken.refreshToken,
            }).then(() => {
              setLoading(false)
              props.onSuccess()
            }).catch(() => props.onError && props.onError())
          } else if (res.data?.saveUserProfile?.profileId) {
            setLoading(false)
            props.onSuccess()
          } else {
            props.onError && props.onError()
          }

        })
          .catch(error => {
            if (error?.graphQLErrors) {
              switch (error.graphQLErrors[0]?.extensions?.type) {
                case "INVALID_EMAIL":
                case GraphQLErrorType.ALREADY_EXISTS: {
                  formik.setFieldError('email', 'IN_USE');
                  logEvent('Error - user already exists', {email: getStoredUser()?.email})
                  break;
                }
                case GraphQLErrorType.NO_CONNECTION:
                  // setSaveError("Service is not available");
                  break;
                default:
                // setSaveError("Unknown error")
              }
            } else if (error?.message) {
              // setSaveError(error?.message)
            }
            setLoading(false);
            props.onError && props.onError()
          })
      }
    },
    validateOnChange: true
  });

  React.useEffect(() => {
    if (formik) {
      props.setFormik(formik);
    }
  }, [formik.handleSubmit]);


  return <Grid container spacing={2} columns={props.columns === 1.5 ? undefined : props.columns} >
    <Grid item xs={12} md={props.columns === 1.5 ? 6 : 12}>
      <TextInput
        fullWidth
        name="firstName"
        data-test="first_name"
        label="First Name"
        placeholder={'First name'}
        autoFocus={true}
        value={formik.values.firstName}
        onChange={formik.handleChange}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={formik.touched.firstName && formik.errors.firstName}
      />
    </Grid>
    <Grid item xs={12} md={props.columns === 1.5 ? 6 : 12}>
      <TextInput
        fullWidth
        name="lastName"
        data-test="last_name"
        label="Last Name"
        placeholder={'Last name'}
        value={formik.values.lastName}
        onChange={formik.handleChange}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={formik.touched.lastName && formik.errors.lastName}
      />
    </Grid>
    <Grid item xs={12} md={props.columns === 1.5 ? 12 : 6}>
      <Collapse in={props.showAll || !!formik.values.lastName && !!formik.values.firstName}>
        <TextInput
          fullWidth
          name="email"
          label="Email"
          data-test="email"
          placeholder={'Enter your email address'}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={(formik.touched.email && formik.errors.email) ? formik.errors.email === 'IN_USE' ? <>Looks like that email is already in use. Please <a href={createLoginUrl(document.location.href)} style={{color: "red"}}>sign in</a></> : formik.errors.email : undefined}
        />
      </Collapse>
    </Grid>
    {props.showPhone && <Grid item xs={12} md={props.columns === 1.5 ? 12 : 6}>
      <Collapse in={props.showAll || !!formik.values.email}>
        <PhoneNumberInput onChange={value => formik.setFieldValue('phoneNumber', value)}
                          name="phoneNumber"
                          defaultValue={getStoredUser()?.phoneNumber}
                          label={'Phone number'}
                          placeholder="+1 (123) 456-7890"
                          error={formik.errors.phoneNumber as string}
                          isValid={() => !formik.submitCount || formik.values.phoneNumber !== '1' && !formik.errors.phoneNumber}
        />
      </Collapse>
    </Grid>}
  </Grid>
}




