import {useCallback, useEffect, useState} from "react";

export const useDebouncedEffect = (effect: any, delay:number , deps: any[], skip?: number) => {
  const callback = useCallback(effect, deps);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let handler: any;
    if (!skip || count >= skip) {
      handler = setTimeout(() => {
        callback();
      }, delay);
    }
    setCount(prev => prev + 1)

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
}
