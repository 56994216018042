import { useHistory } from 'react-router';
import React from 'react';
import { CRInfo, PageWrapper } from '@coverright/ui/shared';
import { Box} from '@mui/material';
import CollectInfoForm from '../../components/collect-info-form/CollectInfoForm';
import { getStoredUser, setStoredUser } from '@coverright/utils';
import { AnimationContext } from '@coverright/shared/contexts';

export default function () {
  const history = useHistory();
  const [formik, setFormik] = React.useState<any>();
  const [isValid, setIsValid] = React.useState(false);
  const {reverse} = React.useContext(AnimationContext)


  const onSubmit = (values: any) => {
    if (isValid) {
      setStoredUser({...getStoredUser(), ...values})
      history.push('/personal-info')
    }
  }

  return (
    <PageWrapper title={`Enter your client’s details below`}
                 subtitle={'CoverRight will be use this information to provide your client personalized quotes.'}
                 formName={'client-details'}
                 onNextButtonClick={formik?.submitForm}
                 sx={{mt: {md: '70px'}}}
                 onBackButtonClick={() => {
                   reverse && reverse();
                   setTimeout(() => history.push('/visit-info'), 50)
                 }}
    >
      <div>
        <CollectInfoForm setFormik={setFormik} onValidChange={setIsValid} onSubmit={onSubmit} />
      </div>
      <div className={'h-40'} />
    </PageWrapper>
  )
}
