import React from "react";
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import { CheckCircle, XCircle } from '@phosphor-icons/react';

export const Benefit = (props: BenefitProps) => {

  return <Box className={`flex flex-align-start ${props.className}`} sx={props.sx}>
    {props.inactive && <>
      {props.value && <CheckCircle className={`op-5 ${props.imageClass}`} size={props.imageSize || 16} color={"rgba(28,67,79,0.5)"} weight="fill" />}
      {!props.value && <XCircle className={`op-5 ${props.imageClass}`} size={props.imageSize || 16} color={"rgba(28,67,79,0.5)"} weight="fill" />}
      <Typography className={'fs-12 lh-15 ml-5 op-5 medium ' + props.labelClass}>{props.label}</Typography>
    </>}
    {!props.inactive && <>
      {props.value && <CheckCircle className={`${props.imageClass}`} size={props.imageSize || 16} color={props.overrideCheckIconColor || "#029094"} weight="fill" />}
      {!props.value && <XCircle className={`${props.imageClass}`} size={props.imageSize || 16} color={props.overrideCrossIconColor || "#DD3D3D"} weight="fill" />}
      {typeof props.label === 'string' && <Typography sx={{color: props.value ? '#029094' : props.overrideCrossIconColor ? '#1C434F' : '#DD3D3D'}} className={`fs-12 lh-15 ml-5 medium ${props.value ? 'check' : 'cross'} ${props.labelClass}`}>{props.label}</Typography>}
      {typeof props.label !== 'string' && <>{props.label}</>}
    </>}
  </Box>
};

export interface BenefitProps {
  value: boolean,
  inactive?: boolean,
  className?: string,
  labelClass?: string,
  imageClass?: string,
  imageSize?: number,
  sx?: SxProps,
  overrideCheckIconColor?: string,
  overrideCrossIconColor?: string,
  label: string | JSX.Element,
}
