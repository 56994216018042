import React from "react";
import clsx from "clsx";
import { Box, Fade, FadeProps, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { CRPreloader } from './CRPreloader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      zIndex: 1000,
      background: '#fff',
      borderRadius: 10,
      position: 'fixed',
      height: '100%',
      maxHeight: '100vh',
      width: '100%',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  }),
);

export function Preloader({className, ...props}: {children?: React.ReactNode, className?: string} & Omit<FadeProps, 'children'> ) {
  const classes = useStyles();

  return (
    <Fade {...props} appear={false}>
      <div className={clsx(classes.wrapper, className)}>
        {props.children}
        <CRPreloader/>
      </div>
    </Fade>
  );
}



