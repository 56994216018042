import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import { useLogEvent } from '@coverright/shared/analytics';
import {Box, styled} from '@mui/material';
import {initPlayer, loadYouTubeScript, youTubeScriptLoaded} from './lib';

interface VideoPlayerProps {
  videoUrl?: string,
  videoTitle?: string,
  onFinished?: () => void,
  maxWidth?: any,
}

export const VideoPlayer = forwardRef((props: VideoPlayerProps, ref) => {
  const {videoUrl, videoTitle, onFinished, maxWidth} = props;
  const playerRef = React.useRef<any>();
  const [playerReady, setPlayerReady] = React.useState(false);
  const playEventSent = useRef(false)

  const logEvent = useLogEvent();

  useImperativeHandle(ref, () => ({
    getCurrentTime: (): number => {
      return playerRef.current?.getCurrentTime() || 0;
    }
  }));

  React.useEffect(() => {
    if (!youTubeScriptLoaded()) {
      loadYouTubeScript(() => initPlayer(onPlayerReady, onPlayerStateChange));
    } else {
      initPlayer(onPlayerReady, onPlayerStateChange);
    }

    return () => {
      playerRef.current?.destroy();
      playerRef.current = null;
    }
  }, [])


  const onPlayerReady = (event: any) => {
    playerRef.current = event.target;
    setPlayerReady(true);
  }

  const onPlayerStateChange = (event: any) => {
    if (!playEventSent.current && event.data === 1) {
      logEvent('Play video', {name: videoTitle, url: videoUrl, page: document.location.href})
        .then(() => playEventSent.current = true);
    }
    if (event.data === 0) {
      onFinished && onFinished()
    }
  };

  React.useEffect(() => {
    if (playerReady && videoUrl) {
      playerRef.current.loadVideoByUrl(videoUrl);
    }
  }, [videoUrl, playerReady]);

  return <Box sx={{maxWidth, width: 1}}>
    <Box sx={{position: 'relative', margin: 'auto'}}>
      <Container>
        <div id="player"></div>
      </Container>
    </Box>
  </Box>
})

const Container = styled(Box)({
  position: 'relative',
  width: '100%',
  height: 0,
  paddingBottom: '56.25%',
  overflow: 'hidden',
  '& iframe': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  }
})


