import * as React from "react";
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import { withErrorBoundary } from '@coverright/ui/shared';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationContainer: {
      height: '50px'
    },
    notification: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      zIndex: 10000
    }
  }),
);

interface LayoutWithNotificationProps {
  children: JSX.Element,
  NotificationComponent: JSX.Element,
  showNotification: boolean
}

function Layout({children, NotificationComponent, showNotification}: LayoutWithNotificationProps) {
  const classes = useStyles();

  return <Box display={'flex'} flex={1} flexDirection={'column'}>
    {showNotification && <div className={classes.notificationContainer}>
      <div className={classes.notification}>
        {NotificationComponent}
      </div>
    </div>}
    <Box display={'flex'} flexDirection={'column'}>
      {children}
    </Box>
  </Box>;
}

export const LayoutWithNotification = withErrorBoundary(Layout, true);
