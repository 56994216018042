import * as React from 'react';
import {getClientId, getStoredUser} from '@coverright/utils';
import { Box,  IconButton, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import {CRModal, CRPreloader, V3Preloader} from '@coverright/ui/shared';
import { useClientAgent } from '../..';
import { X } from '@phosphor-icons/react';
import { createRoot } from 'react-dom/client';
import { useLogEvent } from '@coverright/shared/analytics';
import { useRef, useState} from "react";
import {enrollmentClient} from "@coverright/data-access/apollo-clients";
import {gql} from "@apollo/client";

interface CalendlyProps {
  onEventScheduled: (data: any) => void,
  dataUrl?: string,
  height?: string | number,
  maxWidth?: string | number,
  sx?: SxProps,
  type: CalendlyCallType
}

export const Calendly = (props: CalendlyProps) => {
  const [showDisclamer, setShowDisclamer] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const agent = useClientAgent();

  function isCalendlyEvent(e: MessageEvent<any>) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  };

  const url = React.useMemo(() => {
    if (props.dataUrl) {
      return props.dataUrl
    }
    switch (props.type) {
      case CalendlyCallType.enrollment: return agent?.enrollmentMeetingUrl;
      case CalendlyCallType.planReview: return agent?.planReviewUrl;
      default: return agent?.consultationUrl;
    }
  }, [props.dataUrl, agent, props.type])

  React.useEffect(() => {
    if (agent && !url) {
      setLoading(false);
    }
  }, [agent, url])

  React.useEffect(() => {
    if (url) {
      const script = document.createElement("script");
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);

      const listener = (message: MessageEvent<any>) => {
        if (isCalendlyEvent(message)) {
          if (message.data.event === 'calendly.event_type_viewed') {
            setLoading(false);
          }
          if (message.data.event === 'calendly.event_scheduled') {
            setShowDisclamer(true);
            props.onEventScheduled(message.data);
          }
        }
      };
      window.addEventListener('message', listener);
      return () => window.removeEventListener('message', listener);
    }
    return
  }, [url])

  if (!url) {
    return <CRPreloader />;
  }

  return <div style={{width: "100%", maxWidth: props.maxWidth || "700px", position: 'relative'}}>
    {loading && <Box sx={{py: 3}}><V3Preloader /></Box>}
    <Box className="calendly-inline-widget" data-url={url + `?primary_color=1C434F&name=${(getStoredUser()?.firstName || '') + ' ' + (getStoredUser()?.lastName || '')}&email=${getStoredUser()?.email || ''}&a1=${getStoredUser()?.phoneNumber || ''}`}
         sx={{width: "100%", maxWidth: props.maxWidth || "700px", height: loading ? 0 : props.height || 750, ...props.sx}}>

    </Box>
    {(agent && !url) && <Typography className={'fs-24 lh-30 bold'} align={'center'}>We can't schedule your call</Typography>}
    <Typography align={'center'} sx={{
      display: showDisclamer ? 'block' : 'none',
      background: 'white',
      width: '100%',
      maxWidth: 654,
      zIndex: '1',
      margin: 'auto',
      paddingBottom: '20px',
      paddingTop: '10px',
      color: '#778E95',
      boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 8%)',
    }} className={'fs-18 lh-22'}>For accommodations for persons with special needs at<br />meetings, please call (888) 969-7667 | (TTY 711) at the time of<br />your meeting.</Typography>
    <div className={'h-15'} />
  </div>
}

export enum CalendlyCallType {
  enrollment,
  planReview,
  consultation
}

export function useCalendlyModal() {

  return (props?: CalendlyProps & {width?: number | string}) => {
    return new Promise<boolean>((resolve) => {
      let div: any;
      if (!document.getElementById('calendly-modal-container')) {
        div = document.createElement("div");
        div.id = 'calendly-modal-container';
        document.body.appendChild(div);
      }
      const root = createRoot(document.getElementById('calendly-modal-container') as HTMLElement)
      document.body.style.setProperty('overflow', 'hidden');
      document.body.style.setProperty('padding-right', '15px');
      root.render(<CalendlyModal {...props} onClose={(value: boolean) => {
        resolve(value);
        if (div) {
          document.body.removeChild(div);
          div = undefined
        }
        if (document.getElementById('calendly-modal')) {
          document.body.removeChild(document.getElementById('calendly-modal')!);
        }
        document.body.style.setProperty('overflow', null);
        document.body.style.setProperty('padding-right', null);

      }} />)
    })
  }
}

interface ModalProps extends Partial<CalendlyProps> {
  onClose: (value: boolean) => void,
  width?: number | string,
  open?: boolean,
}


export const CalendlyModal = (props: ModalProps) => {
  const {width, ...rest} = props;
  const logEvent = useLogEvent();

  React.useEffect(() => {
    if (props.open !== false) {
      logEvent('Calendly modal opened');
    }
  }, [props.open])

  const onClose = (val: boolean) => {
    logEvent('Calendly modal closed', {scheduled: val});
    props.onClose(val)
  }

  return <CRModal BackdropProps={{
    sx: {
      background: 'rgba(0, 0, 0, 0.25)',
    }
  }} width={width || 1000} id={'calendly-modal'} paperPadding={0} open={props.open === undefined ? true : props.open} onClose={() => onClose(false)}>
    <Box sx={{position: 'relative'}}>
      <IconButton sx={{position: 'absolute', top: 5, right: 5, zIndex: 1}} data-test="modal-close-button" onClick={() => onClose(false)}>
        <X size={32} color="#000" />
      </IconButton>
      <Calendly {...rest} type={rest.type || CalendlyCallType.consultation} maxWidth={1000} onEventScheduled={data => {
        rest.onEventScheduled && rest.onEventScheduled(data);
        onClose(true)
      }} />
      <Box sx={{ backgroundColor: 'white', boxSizing: 'border-box', width: 1, borderRadius: '8px', p: 2, mb: 1, overflowY: 'auto', height: 106 }}>
        <Typography sx={{maxWidth: 800, m: 'auto', fontFamily: 'Inter, Sailec, Roboto'}} className={'fs-15'}>
          By submitting my phone number and personal information above and clicking ‘Schedule Event’ above, I confirm that
          the phone number provided is my own, and I provide my express written consent to be contacted by CoverRight Inc.
          and its subsidiaries at the phone number I have provided above regarding various Medicare products including
          Medicare Advantage Plans, Medicare Supplement (Medigap) Plans, Prescription Drug Plans and other marketing and
          informational content, through the use of text message and/or live, automated and pre-recorded telephone calls,
          even if my number is on a Do Not Call list. I further understand and agree that my communications with
          CoverRight Inc. are subject to CoverRight Inc.'s <a href={'https://coverright.com/privacy/'} target={'_blank'}>Privacy Policy</a> and <a href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</a>.
          <br /><br />
          This is a solicitation for insurance. Your information will be provided to a licensed agent for future contact.
          <br /><br />
          I understand my consents to receive text messages and telephone calls are not required to receive services from
          CoverRight Inc.; I may instead reach CoverRight Inc. directly at +1 (888) 969-7667 (TTY: 711) and I can revoke
          my consents at any time by emailing hello@coverright.com.
          <br /><br />
          CoverRight Insurance Services Inc. represents Medicare Advantage HMO, PPO and PFFS organizations that have a
          Medicare contract. Enrollment depends on the plan’s contract renewal.
          <br /><br />
          We do not offer every plan available in your area. Any information we provide is limited to those plans we do
          offer in your area. Please contact Medicare.gov or 1–800–MEDICARE 24 hours a day/7 days a week to get
          information on all of your options.
        </Typography>
      </Box>
    </Box>
  </CRModal>
}

export function useCalendlyUrl(isProduction?: boolean, initialUrl = '') {
  const [calendlyUrl, setCalendlyUrl] = useState(initialUrl);
  const called = useRef(false)
  const clientId = getClientId();

  const decorate = (url: string) => (
    `${url}?primary_color=1C434F${(getStoredUser()?.firstName ? '&name=' + getStoredUser()?.firstName + ' ' + (getStoredUser()?.lastName || '') : '')}${getStoredUser()?.email ? '&email=' + getStoredUser()?.email : ''}${getStoredUser()?.phoneNumber ? '&a1=' + getStoredUser()?.phoneNumber : ''}`
  )


  if (!isProduction) {
    return decorate(initialUrl ? initialUrl : 'https://calendly.com/cracraddollaufru-8503/30min');
  }

  if (!clientId) {
    return decorate(initialUrl ? initialUrl : `https://calendly.com/coverright-advisors/medicare-consultation-client`)
  } else {
    if (!called.current) {
      getAgent(clientId).then(url => {
        if (url) {
          setCalendlyUrl(decorate(url));
        }
      })
    }
    called.current = true;
  }

  return calendlyUrl

/*
  useEffect(() => {
    (async function() {

      if (cookie.get('clientId')) {
        const url = await getAgent(cookie.get('clientId'));
        if (url) {
          setCalendlyUrl(decorate(url));
          return
        }
      }
      setCalendlyUrl(decorate(initialUrl ? initialUrl : `https://calendly.com/coverright-advisors/medicare-consultation-client`))
    })()
  }, [isProduction, initialUrl]);
*/
  return calendlyUrl;
}

const getAgent = async (clientId: string) => (
  enrollmentClient.query({
    query: gql("query agentAssignedToClient($clientId: UUID!) {\n  agentAssignedToClient(clientId: $clientId) {\n    consultationUrl\n  }\n}\n"),
    variables: {clientId}
  }).then(res => res.data?.agentAssignedToClient?.consultationUrl)
)
