import React, {PropsWithChildren} from "react";
import clsx from "clsx";
import { Box, Collapse, IconButton, Theme } from '@mui/material';
import { SxProps } from '@mui/material';
import { createStyles , makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      transform: 'rotate(0)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important',
    },
    button: {
      backgroundColor: 'rgba(164, 180, 185, 0.1)',
      padding: 10,
    }
  }),
);

export const CollapseSection = (props: {title: JSX.Element, defaultOpen?: boolean, buttonSx: SxProps, sx?: SxProps} & PropsWithChildren<any>) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(typeof props.defaultOpen !== 'undefined' ? props.defaultOpen : true);
  return <>
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
      {props.title}
      <IconButton sx={{
        backgroundColor: 'rgba(164, 180, 185, 0.1)',
        padding: '10px',
        ...props.buttonSx
      }} onClick={() => setExpanded(!expanded)}>
        <img src={'/assets/img/arrow.svg'} className={clsx(classes.icon, expanded && classes.iconOpen)} />
      </IconButton>
    </Box>
    <Collapse in={expanded}>
      <Box sx={{mt: '3px', pl: '13px', ...props.sx}}>
        {props.children}
      </Box>
    </Collapse>
  </>
}

