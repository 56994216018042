import React, {forwardRef} from "react";
import { createStyles, makeStyles } from '@mui/styles';
import {
  Box,
  OutlinedTextFieldProps, styled,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import { SxProps } from '@mui/material';
import { useEndAdornment } from './lib/use-end-adornment';
import { useStartAdornment } from './lib/use-start-adornment';
import * as _ from 'lodash';

const useStylesHelperText = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: '100%',
    },
    contained: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      marginTop: 6,
      whiteSpace: 'nowrap',
    },
  }),
);

export interface InputProps extends Partial<OutlinedTextFieldProps> {
  inputLabelClass?: string,
  inputClassName?: string,
  wrapperSx?: SxProps,
  tickPosition?: 'start' | 'end',
  startAdornmentSx?: SxProps,
  endAdornmentSx?: SxProps,
  validator?: (value: unknown) => boolean,
  hideTick?: boolean,
  loading?: boolean,
}

const Input = (props: InputProps, ref: any) => {
  const {
    inputLabelClass,
    wrapperSx,
    validator} = props;

  const textFieldProps = _.omit(props, [
    'inputLabelClass',
    'inputClassName',
    'endAdornmentSx',
    'tickPosition',
    'hideTick',
    'wrapperSx',
    'validator',
    'loading'
  ])

  const helperTextClasses = useStylesHelperText();

  const isValid: boolean = React.useMemo(() => {
    return (validator ? validator(props.value) : !!props.value) && !props.error;
  }, [props.value, props.error, validator]);

  const filledClass = React.useMemo(() => {
    return isValid ? 'MuiOutlinedInput-filled' : '';
  }, [isValid]);

  const endAdornment = useEndAdornment(props, isValid);
  const startAdornment = useStartAdornment(props, isValid);


  return (
    <Box sx={{width: 1, ...wrapperSx}} className={props.className}>
      {
        props.label && (
          <Label className={[inputLabelClass, 'text-input-label'].filter(Boolean).join(' ')}>
            {props.label}
          </Label>
        )
      }
      <TextField
        {...textFieldProps}
        fullWidth={typeof props.fullWidth === 'undefined' ? true : props.fullWidth}
        ref={ref}
        variant="outlined"
        label={undefined}
        inputProps={{
          ...props.inputProps,
          className: 'data-hj-allow'
        }}
        InputProps={{
          ...props.InputProps,
          endAdornment,
          startAdornment,
          classes: {
            ...props?.InputProps?.classes
          },
          className: filledClass
        }}
        FormHelperTextProps={{
          ...props.FormHelperTextProps,
          classes: {
            ...helperTextClasses,
            ...props?.FormHelperTextProps?.classes
          },
        }}
      />
    </Box>
  );
}

export const TextInput = forwardRef(Input);

const Label = styled(Typography)(({theme}) => ({
  fontSize: '16px',
  lineHeight: '19px',
  marginBottom: '8px',
  fontWeight: 600,
  color: theme.palette.text.primary,
}))






