import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import * as _ from 'lodash';

export function useCheckUserExistenceByEmail(options?: LazyQueryHookOptions<{checkUserExistenceByEmail: boolean}, {email: string}>) {
  return useLazyQuery<{checkUserExistenceByEmail: boolean}, {email: string}>(gql(query), {
    client: enrollmentClient,
    fetchPolicy: "no-cache",
    ...options
  });
}

export const validateEmail = _.debounce(validate, 500)

function validate(value?: string) {
  if (!!value && isValid(value.toString())) {
    return new Promise((resolve, reject) => {
      enrollmentClient.query({
        query: gql(query),
        variables: { email: value || '' }
      }).then(res => {
        resolve(res.data.checkUserExistenceByEmail === false)
      })
    }) as any
  } else {
    return false
  }
}

const isValid = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


const query = `
query ($email: String!) {
  checkUserExistenceByEmail(email: $email)
}
`
