export const environmentLocal = {
  production: false,

  medigapUrl: 'http://localhost:3000/',
  medicareAdvantageUrl: 'http://localhost:3001/',
  dashboardUrl: 'http://localhost:3003/',
  learningUrl: 'http://localhost:3004/',
  advisorUrl: 'http://localhost:3005/',
  pdpUrl: 'http://localhost:3006/',
  flexpaUrl: 'http://localhost:3008/',
  offersUrl: 'http://localhost:3009/',
  profileUrl: 'http://localhost:3007/',
  planFinderUrl: 'http://localhost:3006/plan-finder/',


  filesUrl: 'https://plan-files.ascendmedicare.com',

  clientFilesApiUrl: 'https://api.staging.ascendinsure.com',
  userFlowRestUrl: 'https://api.staging.ascendinsure.com/user-flow/api',

  cdnUrl: 'https://cdn.staging.ascendinsure.com/',

  strapiUrl: 'https://content-api.coverright.com',

  flexpaApiPublishableKey: 'pk_test_fUUKcP4fVWf1JITZ9-LGAX_kfXhkcbe6kbrtGmGo4TU'
};
