import React from "react";
import {colors} from "@coverright/ui/themes";
import { Box, Typography } from '@mui/material';

const link = {
  fontSize: 12,
  color: colors.text.primary,
  textDecoration: 'underline',
  cursor: 'pointer',
  marginBottom: 2,
  fontWeight: 500,
}

export const Terms = () => {
  return <Box display={'flex'}flexDirection={'column'} justifyContent={'flex-end'} alignItems={'flex-start'}>
    <Box display={'flex'}>
      <Typography sx={link} className={'fs-14 lh-17 medium'} color={'textPrimary'}
                  onClick={() => window.open('https://coverright.com/coverright-terms-of-use/')}>Terms of Use</Typography>
      <Box mx={1}>|</Box>
      <Typography sx={link} className={'fs-14 lh-17 medium'} color={'textPrimary'}
                  onClick={() => window.open('https://coverright.com/privacy/')}>Privacy Policy</Typography>
    </Box>
    <Box display={'flex'}>
      <img src={'/assets/img/security_shield_black.svg'} />
      <Box ml={'12px'}>
        <Typography className={'fs-12 lh-15'} sx={{color: 'rgba(28, 67, 79, 0.5)'}} >HIPAA-compliant data storage<br />256-bit encryption</Typography>
      </Box>
    </Box>
  </Box>
}
