import * as React from 'react';
import * as _ from 'lodash';
import { KeycloakContext } from '@coverright/shared/keycloak';
import {
  getCId,
  getPdpQuoteId,
  getStoredUser, listenPdpQuoteChange, setPdpQuoteId,
  setStoredUser
} from '@coverright/utils';
import { ProfileContext, withProfileContext } from './ProfileContext';
import { FetchResult, MutationHookOptions } from '@apollo/client';
import { usePatchClientPersonalInfo } from '@coverright/data-access/enrollment';
import { usePdpQuote, useSavePdpQuote } from '@coverright/data-access/medicare';
import { MutationPatchClientPersonalInfoArgs } from '@coverright/data-access/types/enrollment';
import { AdminContext } from './AdminContext';
import { MutationSavePdpQuoteArgs, PdpQuoteOutput } from '@coverright/data-access/types/medicare';


type IPdpQuoteContext = PdpQuoteOutput & {
  save: (data: MutationHookOptions<{ savePdpQuote: PdpQuoteOutput }, MutationSavePdpQuoteArgs>) => Promise<FetchResult<{savePdpQuote: PdpQuoteOutput}>>,
  refresh: () => void,
  saveClientInfo: (data: MutationHookOptions<{ patchClientPersonalInfo: boolean }, MutationPatchClientPersonalInfoArgs>) => Promise<FetchResult<{patchClientPersonalInfo: boolean}>>,
  saveLoading: boolean,
  saveClientInfoLoading: boolean,
};

const defaultValue: IPdpQuoteContext = {
  save: () => new Promise<FetchResult<{savePdpQuote: PdpQuoteOutput}>>(() => null),
  saveClientInfo: () => new Promise<FetchResult<{patchClientPersonalInfo: boolean}>>(() => null),
  refresh: () => null,
  id: '',
  clientId: '',
  insulinSavings: false,
  saveLoading: false,
  saveClientInfoLoading: false,
  preferredDrugs: [],
  preferredPharmacies: [],
}


export const PdpQuoteContext = React.createContext<IPdpQuoteContext>(defaultValue);

function Provider(props: React.PropsWithChildren<QuoteProviderProps>) {

  const adminContext = React.useContext(AdminContext);
  const {keycloak} = React.useContext(KeycloakContext);
  const profile = React.useContext(ProfileContext);

  const [saveClientInfo, {loading: saveClientInfoLoading}] = usePatchClientPersonalInfo({
    onCompleted: () => getPdpQuote({variables: {id: getPdpQuoteId()}})
  })

  const [save, {loading: saveLoading}] = useSavePdpQuote({
    onCompleted: (data: any) => {
      setPdpQuoteId(data.savePdpQuote.id);
      if (profile?.profileId && !profile.pdpQuoteId) {
        profile.save({variables: {
            data: {
              pdpQuoteId: data.savePdpQuote.id,
              cId: getCId(),
              profileId: profile.profileId,
            }
          }})
      }
      return getPdpQuote({variables: {id: data.savePdpQuote.id}});
    }
  });

  React.useEffect(() => {
    if (adminContext?.clientView?.pdpQuote?.id) {
      setPdpQuoteId(adminContext?.clientView?.pdpQuote?.id)
    }
  }, [adminContext?.clientView?.pdpQuote?.id]);

  const quoteId = React.useMemo(() => {
    return adminContext?.clientView?.pdpQuote?.id || props.quoteId;
  }, [props.quoteId, adminContext?.clientView?.pdpQuote?.id]);

  const [quote, setQuote] = React.useState<PdpQuoteOutput>();

  const [getPdpQuote] = usePdpQuote({
    onCompleted: data => {
      if (!_.isEqual(quote, data?.pdpQuote)) {
        setStoredUser({
          ...getStoredUser(),
          zip: data?.pdpQuote?.zip || undefined,
          countyName: data?.pdpQuote?.countyName || undefined,
        })
        setQuote(data?.pdpQuote);
      }
    }
  });

  React.useEffect(() => {
    listenPdpQuoteChange(callback)
  }, []);

  React.useEffect(() => {
    callback(quoteId);
  }, [quoteId, keycloak?.authenticated]);

  const callback = (quoteId?: string) => {
    if (quoteId) {
      getPdpQuote({variables: {id: quoteId}});
    }
  };

  const refresh = () => callback(getPdpQuoteId());

  const result = React.useMemo(() => {
      return {...quote, save, saveClientInfo, refresh, saveLoading, saveClientInfoLoading}
  }, [save, quote, saveClientInfo, saveLoading, saveClientInfoLoading]);

  return <PdpQuoteContext.Provider value={result as any}>
    {props.children}
  </PdpQuoteContext.Provider>

}

export const PdpQuoteProvider = withProfileContext(Provider);

type QuoteProviderProps = {
  quoteId?: string,
  private?: boolean,
}

export const withPdpQuoteContext = (WrappedComponent: any) => (props: any) => {
  return (
    <PdpQuoteProvider quoteId={getPdpQuoteId()}>
      <WrappedComponent {...{...props}} />
    </PdpQuoteProvider>
  )
}
