import React from "react";
import { resetStoredUser } from '@coverright/utils';

export const AppStateContext = React.createContext<[IAppState, (action: IAppStateAction) => void]>([] as any);

export const AppStateContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer<(state: IAppState, action: IAppStateAction) => IAppState>
  (reducer, {});

  return (
    <AppStateContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppStateContext.Provider>
  );
};

const reducer = (state: IAppState, action: IAppStateAction) => {
  let newState: IAppState = {...state};
  const stored = sessionStorage.getItem('state');
  switch (action.type) {
    case AppStateActionTypes.INIT:
      if (stored) {
        newState = JSON.parse(stored);
      }
      break;
    case AppStateActionTypes.UPDATE:
      newState = {
        ...state,
        ...action.payload
      };
      break;
    case AppStateActionTypes.RESET:
      newState = {};
      resetStoredUser();
      sessionStorage.removeItem('state');
      break;
    default:
      throw new Error();
  }

  sessionStorage.setItem('state', JSON.stringify(newState));

  return newState;
};

interface IAppState {
  adviserFirstName?: string,
  adviserLastName?: string,
  adviserEmail?: string,
  visitInfo?: 'Quote' | 'Submit',
}

export enum AppStateActionTypes {
  INIT,
  UPDATE,
  RESET
}

interface IAppStateAction {
  payload?: IAppState,
  type: AppStateActionTypes
}
