import * as React from "react";
import { getCId } from '@coverright/utils';
import { setUserId, Analytics } from '@firebase/analytics';

import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";

export const AnalyticsContext = React.createContext<Analytics | undefined>(undefined);

export const AnalyticsContextProvider = (props: any) => {
  const [analytics, setAnalytics] = React.useState<Analytics>();

  React.useEffect(() => {
    if (!document.location.hostname.includes('staging') && !document.location.hostname.includes('localhost')) {
      const instance = initFirebase();
      setAnalytics(instance);
      if (getCId()) {
        setUserId(instance, getCId()!, { global: true });
      }
    }
  }, [])

  return (
    <AnalyticsContext.Provider value={analytics}>
      {props.children}
    </AnalyticsContext.Provider>
  );
};

export const withAnalyticsContext = (WrappedComponent: any) => (props: any) => {
  return (
    <AnalyticsContextProvider>
      <WrappedComponent {...props} />
    </AnalyticsContextProvider>
  )
}

const firebaseConfig = {
  apiKey: "AIzaSyDDmC6GCBMxC1l6JYJrb9Unf_lEEF_SF88",
  authDomain: "coverright-behavior.firebaseapp.com",
  projectId: "coverright-behavior",
  storageBucket: "coverright-behavior.appspot.com",
  messagingSenderId: "754735162536",
  appId: "1:754735162536:web:dc20d7e36171f9be664e9d",
  measurementId: "G-Y6FVV478SL"
};

export function initFirebase() {
  const app = initializeApp(firebaseConfig);
  return initializeAnalytics(app, {config: {
      send_page_view: true,
      page_location: document.location.href,
      page_title: document.title
    }});
}
