import React from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/material'

interface CRPreloaderProps {
  sx?: SxProps;
  fullScreen?: boolean,
  fill?: boolean
}

const fullScreenStyles: SxProps = {
  zIndex: 1000,
  position: 'fixed',
  /*minHeight: '100vh',
  maxHeight: '100vh',
  minWidth: '100vw',
  maxWidth: '100vw',*/
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}
const fillStyles: SxProps = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center'
}

export function CRPreloader(props: CRPreloaderProps) {
  let sx: any = props.sx;
  if (props.fill) {
    sx = sx ? {...fillStyles, ...sx} : fillStyles;
  }
  if (props.fullScreen) {
    sx = sx ? {...fullScreenStyles, ...sx} : fullScreenStyles;
  }
  return <Box sx={sx} textAlign={"center"} my={2}>
    <Box mt={'39px'} height={'100px'} width={'100px'} position={'relative'} mx={'auto'}>
      <div className="cr-load">
        <hr/>
        <hr/>
        <hr/>
        <hr/>
      </div>
    </Box>
  </Box>
}
