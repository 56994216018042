import {ProviderAddressOutput, ProviderOutput} from "@coverright/data-access/types/medicare";
import {Box, Divider, Fade, IconButton, Paper, Popper, SxProps, Typography} from "@mui/material";
import * as React from "react";
import {colors} from "@coverright/ui/themes";
import {XCircle} from "@phosphor-icons/react";

type Props = {
  doctor: ProviderOutput,
  hideAddress?: boolean,
  showChangeLocation?: boolean,
  onClick?: () => void,
  onChangeLocationClick?: () => void,
  onDeleteClick?: () => void,
}

export function DoctorCard(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  return <Fade in>
    <Box data-test={'doctor_card'} data-test-value={props.doctor.npi} key={props.doctor.npi} onClick={props.onClick} sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 2,
      backgroundColor: '#F5F5F5',
      borderRadius: '8px',
      width: 1
    }}>
      <Box>
        <Typography className={`bold lh-24 doctor-name mb-4`}>{props.doctor.name}</Typography>
        <Typography className={'lh-24 mb-24'}>{props.doctor.addresses[0]?.specialty}</Typography>
        <Typography className={'lh-24'}>{props.doctor.addresses[0]?.addressLine1}</Typography>
        <Typography className={'lh-24'}>{props.doctor.addresses[0]?.addressLine2}</Typography>
        {props.doctor.addresses.length > 1 && <>
          <Typography sx={{ color: '#1C434F' }}
                      onMouseEnter={(event: any) => {
                        setAnchorEl(event.currentTarget);
                      }}
                      onMouseLeave={() => {
                        setAnchorEl(null);
                      }}
                      className={'underline lh-24 mv-8 medium'}>+ {props.doctor.addresses.length - 1} more</Typography>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement={'bottom-end'}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ px: 2, py: 1 }} >
                  {props.doctor.addresses.slice(1).map((a, i, arr) => <div key={i}>
                    <Divider
                      sx={{ fontSize: 9, color: colors.text.secondary, pb: '4px', pt: i > 0 ? '8px' : undefined }}
                      textAlign={'left'}>{a.specialty}</Divider>
                    <Address address={a}/>
                  </div>)}
                </Paper>
              </Fade>
            )}
          </Popper>
        </>}
        {props.showChangeLocation && <Typography sx={{ color: '#1C434F' }}
                                                 onClick={props.onChangeLocationClick}
                                                 data-test={'change-location-button'}
                                                 className={'underline lh-24 pointer medium'}>Change
          locations</Typography>}
      </Box>
      <IconButton onClick={props.onDeleteClick} data-test={'delete-button'}>
        <XCircle size={24} color="#460C1D" weight="fill"/>
      </IconButton>
    </Box>
  </Fade>;
};

const Address = ({ address, sx }: { address?: ProviderAddressOutput, sx?: SxProps }) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'flex-start', sm: 'flex-end' },
    mt: { xs: 0, sm: 0 }, ...sx
  }} data-test={'address'}>
    <Typography className={'lh-24'}>{address?.addressLine1}</Typography>
    <Typography className={'lh-24'}>{address?.addressLine2}</Typography>
  </Box>
);
