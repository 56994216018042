import { getStoredUser } from '@coverright/utils';

const appId = 'td9lybk9';

export const IntercomProvider = {
  boot: async (data?: IntercomData) => {
    if (!data) {
      let user_hash = '';
      if (getStoredUser()?.userId) {
        user_hash = await fetch(
          'https://api2022.staging.ascendinsure.com/api/intercom/' + getStoredUser()?.userId
        ).then(res => res.text());
      }
      data = {
        email: getStoredUser()?.email,
        name: [getStoredUser()?.firstName, getStoredUser()?.lastName].join(' '),
        user_id: getStoredUser()?.userId,
      }
      if (user_hash) {
        data['user_hash'] = user_hash;
      }
    }
    const init = () => {
      if ((window as any).Intercom) {
        (window as any).Intercom('boot', {
          app_id: appId,
          ...data
        })
      }
    }
    if ((window as any).Intercom) {
      init();
    } else {
      const onDomReady = (onSuccess: () => void) => new Promise(function(resolve, reject) {
        const checkReadyState = function () {
          if (document.readyState === 'interactive' || document.readyState === 'complete') {
            document.removeEventListener('readystatechange', checkReadyState);
            resolve(null);
          }
        }
        document.addEventListener('readystatechange', checkReadyState);
        checkReadyState(); // just in case the event was already fired and we missed it (in case the init is done later than at the load time, i.e. it's done from code)
      });
      await onDomReady(init);
    }
  },
  update: (data?: IntercomData) => {
    if (!data) {
      data = {
        email: getStoredUser()?.email,
        name: [getStoredUser()?.firstName, getStoredUser()?.lastName].join(' '),
        user_id: getStoredUser()?.userId,
      }
    }
    if ((window as any).Intercom) {
      (window as any).Intercom('update', {
        app_id: appId,
        ...data
      })
    }
  },
  shutdown: () => {
    if ((window as any).Intercom) {
      (window as any).Intercom('shutdown')
    }
  },

}

interface IntercomData {
  email?: string;
  created_at?: number;
  name?: string;
  user_id?: string;
  user_hash?: string;
}
