import {PageWrapper, CRInfo} from '@coverright/ui/shared';
import React from 'react';
import { Box} from '@mui/material';
import {
  getStoredUser
} from '@coverright/utils';
import { environment } from '../../../environments/environment';
import { AnimationContext } from '@coverright/shared/contexts';
import { useHistory } from 'react-router';
import { KeycloakContext } from '@coverright/shared/keycloak';
import {CRStyledRadio} from '@coverright/ui/inputs';


export default function () {
  const history = useHistory();
  const [value, setValue] = React.useState<string>();
  const {reverse} = React.useContext(AnimationContext)
  const { getHash } = React.useContext(KeycloakContext);

  const onSubmit = () => {
    if (value === 'MA') {
      document.location.href = `${environment.medicareAdvantageUrl}plans/${getStoredUser()?.zip}/${getStoredUser()?.countyName}?advisor` + getHash();
    } else if (value === 'MG') {
      document.location.href = `${environment.medigapUrl}plans/${getStoredUser()?.zip}/${getStoredUser()?.countyName}?advisor` + getHash();
    }
  };

  return (
    <PageWrapper title={'What coverage is your client is looking for.'}
                 subtitle={'A licensed agent will reach out the next business day.  You can also ‘Book a Call’ at any time if your client wants a specific appointment date.'}
                 formName={'medicare-type'}
                 sx={{mt: {md: '70px'}}}
                 nextButtonDisabled={!value}
                 onNextButtonClick={onSubmit}
                 onBackButtonClick={() => {
                   reverse && reverse();
                   setTimeout(() => history.push('/want-a-book'), 50)
                 }}
    >
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: {xs: 'column', md: 'row'}, gap: '12px', marginBottom: '20px'}}>
        <div data-test='select_mg_coverage'>
          <CRStyledRadio checked={value === 'MG'}
                       imageClassName={'w-95'}
                       height={200}
                       onClick={() => setValue('MG')}
                       label={'Medicare Supplement'}
                       imgUrl={'/assets/img/medigap.svg'} />
        </div>
        <div data-test='select_ma_coverage'>
          <CRStyledRadio checked={value === 'MA'}
                       imageClassName={'w-95'}
                       height={200}
                       onClick={() => setValue('MA')}
                       label={'Medicare Advantage'}
                       imgUrl={'/assets/img/medicare-advantage.svg'} />
        </div>
      </Box>
      <CRInfo open={false} wrapperSxProps={{maxWidth: '500px'}} description={<>If you are looking for standalone Prescription Drug Plan (PDP) only, give us a call directly at <span className={'medium'}>+1 (888) 969-7667</span>. If you are shopping Medicare Supplement and need a drug plan as well, our licensed agents will help you find a plan once you have chosen your Medicare Supplement plan.</>} title={'Looking for a standalone drug plan?'} />
      <div className={'h-40'} />
    </PageWrapper>
  );
}
