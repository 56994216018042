import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A type representing BigDecimal */
  BigDecimal: any;
  /** A type representing local date */
  LocalDate: any;
  /** A type representing a string identifier */
  UUID: any;
};

export enum BenefitNetworkType {
  InNetwork = 'IN_NETWORK',
  OutOfNetwork = 'OUT_OF_NETWORK'
}

export type BenefitOutput = {
  __typename?: 'BenefitOutput';
  benefitPayments: Array<BenefitPayment>;
  claimDate: Scalars['LocalDate'];
  drugUsage?: Maybe<DrugUsage>;
  flexpaId: Scalars['String'];
  id: Scalars['String'];
  networkType?: Maybe<BenefitNetworkType>;
  outcome: Scalars['String'];
  paidAmount?: Maybe<Scalars['BigDecimal']>;
  providerNPI?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
};

export type BenefitPayment = {
  __typename?: 'BenefitPayment';
  amount: Scalars['BigDecimal'];
  code: Scalars['String'];
  payerType: PayerType;
};

export type BenefitUsageOutput = {
  __typename?: 'BenefitUsageOutput';
  currentDeductible?: Maybe<Deductible>;
  deductibleLimit?: Maybe<Deductible>;
  plan: EnrolledPlan;
};

export type ClaimDetail = {
  __typename?: 'ClaimDetail';
  amount: Scalars['BigDecimal'];
  type: ClaimDetailType;
};

export enum ClaimDetailType {
  Coinsurance = 'COINSURANCE',
  Copay = 'COPAY',
  OutOfPocket = 'OUT_OF_POCKET'
}

export type ClaimSummary = {
  __typename?: 'ClaimSummary';
  claimDate: Scalars['LocalDate'];
  claimPaymentDetail: Array<ClaimDetail>;
  paidByProvider: Scalars['BigDecimal'];
  paidByYou: Scalars['BigDecimal'];
  totalClaim: Scalars['BigDecimal'];
  type: Scalars['String'];
};

export type Deductible = {
  __typename?: 'Deductible';
  drugDeductible?: Maybe<Scalars['BigDecimal']>;
  drugOutOfPocket?: Maybe<Scalars['BigDecimal']>;
  medicalInnDeductible?: Maybe<Scalars['BigDecimal']>;
  medicalOonDeductible?: Maybe<Scalars['BigDecimal']>;
  medicalOutOfPocket?: Maybe<Scalars['BigDecimal']>;
};

export type DrugUsage = {
  __typename?: 'DrugUsage';
  drugName: Scalars['String'];
  ndc: Scalars['String'];
  spent?: Maybe<Scalars['BigDecimal']>;
};

export type EnrolledPlan = {
  __typename?: 'EnrolledPlan';
  bidId: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['LocalDate'];
  enrollmentId: Scalars['UUID'];
  flexpaIntegrationError?: Maybe<Scalars['String']>;
  isFlexpaIntegrated: Scalars['Boolean'];
  orgName: Scalars['String'];
  parentOrgName: Scalars['String'];
  planName: Scalars['String'];
  premium: Scalars['String'];
  renewalDate: Scalars['LocalDate'];
  type: PlanType;
  year: PlanYear;
  zip?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  clearFlexpaCache: Scalars['Boolean'];
  disconnect: Scalars['Boolean'];
  saveFlexpaToken: Scalars['Boolean'];
  sendGreeting: Scalars['String'];
};


export type MutationSaveFlexpaTokenArgs = {
  token: Scalars['String'];
};


export type MutationSendGreetingArgs = {
  message: Scalars['String'];
};

export enum PayerType {
  Client = 'CLIENT',
  Insurer = 'INSURER',
  Unknown = 'UNKNOWN'
}

export enum PlanType {
  Ma = 'MA',
  Mg = 'MG',
  Pdp = 'PDP'
}

export enum PlanYear {
  Year2021 = 'Year2021',
  Year2022 = 'Year2022',
  Year2023 = 'Year2023',
  Year2024 = 'Year2024'
}

export type Query = {
  __typename?: 'Query';
  benefits: Array<BenefitOutput>;
  benefitsUsage?: Maybe<BenefitUsageOutput>;
  claimSummaries: Array<ClaimSummary>;
  drugsUsage: Array<DrugUsage>;
  flexpaFHIRResource: Scalars['String'];
  hasEnrollment: Scalars['Boolean'];
  isFlexpaConnected: Scalars['Boolean'];
};


export type QueryFlexpaFhirResourceArgs = {
  resourceName: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  greetings: Scalars['String'];
};

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleAgentManager = 'ROLE_AGENT_MANAGER',
  RoleCsr = 'ROLE_CSR',
  RoleNone = 'ROLE_NONE',
  RoleTechStaff = 'ROLE_TECH_STAFF',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}

export type FlexpaFhirResourceQueryVariables = Exact<{
  resourceName: Scalars['String'];
}>;


export type FlexpaFhirResourceQuery = { __typename?: 'Query', flexpaFHIRResource: string };

export type DisconnectMutationVariables = Exact<{ [key: string]: never; }>;


export type DisconnectMutation = { __typename?: 'Mutation', disconnect: boolean };

export type SaveFlexpaTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SaveFlexpaTokenMutation = { __typename?: 'Mutation', saveFlexpaToken: boolean };

export type ClaimSummariesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClaimSummariesQuery = { __typename?: 'Query', claimSummaries: Array<{ __typename?: 'ClaimSummary', claimDate: any, paidByProvider: any, paidByYou: any, totalClaim: any, type: string, claimPaymentDetail: Array<{ __typename?: 'ClaimDetail', amount: any, type: ClaimDetailType }> }> };

export type IsFlexpaConnectedQueryVariables = Exact<{ [key: string]: never; }>;


export type IsFlexpaConnectedQuery = { __typename?: 'Query', isFlexpaConnected: boolean };

export type DrugsUsageQueryVariables = Exact<{ [key: string]: never; }>;


export type DrugsUsageQuery = { __typename?: 'Query', drugsUsage: Array<{ __typename?: 'DrugUsage', drugName: string, ndc: string, spent?: any | null | undefined }> };

export type HasEnrollmentQueryVariables = Exact<{ [key: string]: never; }>;


export type HasEnrollmentQuery = { __typename?: 'Query', hasEnrollment: boolean };

export type BenefitsUsageQueryVariables = Exact<{ [key: string]: never; }>;


export type BenefitsUsageQuery = { __typename?: 'Query', benefitsUsage?: { __typename?: 'BenefitUsageOutput', currentDeductible?: { __typename?: 'Deductible', drugDeductible?: any | null | undefined, drugOutOfPocket?: any | null | undefined, medicalInnDeductible?: any | null | undefined, medicalOonDeductible?: any | null | undefined, medicalOutOfPocket?: any | null | undefined } | null | undefined, deductibleLimit?: { __typename?: 'Deductible', drugDeductible?: any | null | undefined, drugOutOfPocket?: any | null | undefined, medicalInnDeductible?: any | null | undefined, medicalOonDeductible?: any | null | undefined, medicalOutOfPocket?: any | null | undefined } | null | undefined, plan: { __typename?: 'EnrolledPlan', isFlexpaIntegrated: boolean, bidId: string, effectiveDate: any, enrollmentId: any, orgName: string, parentOrgName: string, planName: string, premium: string, renewalDate: any, type: PlanType, year: PlanYear, county?: string | null | undefined, zip?: string | null | undefined } } | null | undefined };


export const FlexpaFhirResourceDocument = gql`
    query flexpaFHIRResource($resourceName: String!) {
  flexpaFHIRResource(resourceName: $resourceName)
}
    `;

/**
 * __useFlexpaFhirResourceQuery__
 *
 * To run a query within a React component, call `useFlexpaFhirResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlexpaFhirResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlexpaFhirResourceQuery({
 *   variables: {
 *      resourceName: // value for 'resourceName'
 *   },
 * });
 */
export function useFlexpaFhirResourceQuery(baseOptions: Apollo.QueryHookOptions<FlexpaFhirResourceQuery, FlexpaFhirResourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlexpaFhirResourceQuery, FlexpaFhirResourceQueryVariables>(FlexpaFhirResourceDocument, options);
      }
export function useFlexpaFhirResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlexpaFhirResourceQuery, FlexpaFhirResourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlexpaFhirResourceQuery, FlexpaFhirResourceQueryVariables>(FlexpaFhirResourceDocument, options);
        }
export type FlexpaFhirResourceQueryHookResult = ReturnType<typeof useFlexpaFhirResourceQuery>;
export type FlexpaFhirResourceLazyQueryHookResult = ReturnType<typeof useFlexpaFhirResourceLazyQuery>;
export type FlexpaFhirResourceQueryResult = Apollo.QueryResult<FlexpaFhirResourceQuery, FlexpaFhirResourceQueryVariables>;
export const DisconnectDocument = gql`
    mutation disconnect {
  disconnect
}
    `;
export type DisconnectMutationFn = Apollo.MutationFunction<DisconnectMutation, DisconnectMutationVariables>;

/**
 * __useDisconnectMutation__
 *
 * To run a mutation, you first call `useDisconnectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectMutation, { data, loading, error }] = useDisconnectMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectMutation, DisconnectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectMutation, DisconnectMutationVariables>(DisconnectDocument, options);
      }
export type DisconnectMutationHookResult = ReturnType<typeof useDisconnectMutation>;
export type DisconnectMutationResult = Apollo.MutationResult<DisconnectMutation>;
export type DisconnectMutationOptions = Apollo.BaseMutationOptions<DisconnectMutation, DisconnectMutationVariables>;
export const SaveFlexpaTokenDocument = gql`
    mutation saveFlexpaToken($token: String!) {
  saveFlexpaToken(token: $token)
}
    `;
export type SaveFlexpaTokenMutationFn = Apollo.MutationFunction<SaveFlexpaTokenMutation, SaveFlexpaTokenMutationVariables>;

/**
 * __useSaveFlexpaTokenMutation__
 *
 * To run a mutation, you first call `useSaveFlexpaTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFlexpaTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFlexpaTokenMutation, { data, loading, error }] = useSaveFlexpaTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSaveFlexpaTokenMutation(baseOptions?: Apollo.MutationHookOptions<SaveFlexpaTokenMutation, SaveFlexpaTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveFlexpaTokenMutation, SaveFlexpaTokenMutationVariables>(SaveFlexpaTokenDocument, options);
      }
export type SaveFlexpaTokenMutationHookResult = ReturnType<typeof useSaveFlexpaTokenMutation>;
export type SaveFlexpaTokenMutationResult = Apollo.MutationResult<SaveFlexpaTokenMutation>;
export type SaveFlexpaTokenMutationOptions = Apollo.BaseMutationOptions<SaveFlexpaTokenMutation, SaveFlexpaTokenMutationVariables>;
export const ClaimSummariesDocument = gql`
    query claimSummaries {
  claimSummaries {
    claimDate
    paidByProvider
    paidByYou
    totalClaim
    type
    claimPaymentDetail {
      amount
      type
    }
  }
}
    `;

/**
 * __useClaimSummariesQuery__
 *
 * To run a query within a React component, call `useClaimSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimSummariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClaimSummariesQuery(baseOptions?: Apollo.QueryHookOptions<ClaimSummariesQuery, ClaimSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClaimSummariesQuery, ClaimSummariesQueryVariables>(ClaimSummariesDocument, options);
      }
export function useClaimSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClaimSummariesQuery, ClaimSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClaimSummariesQuery, ClaimSummariesQueryVariables>(ClaimSummariesDocument, options);
        }
export type ClaimSummariesQueryHookResult = ReturnType<typeof useClaimSummariesQuery>;
export type ClaimSummariesLazyQueryHookResult = ReturnType<typeof useClaimSummariesLazyQuery>;
export type ClaimSummariesQueryResult = Apollo.QueryResult<ClaimSummariesQuery, ClaimSummariesQueryVariables>;
export const IsFlexpaConnectedDocument = gql`
    query isFlexpaConnected {
  isFlexpaConnected
}
    `;

/**
 * __useIsFlexpaConnectedQuery__
 *
 * To run a query within a React component, call `useIsFlexpaConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFlexpaConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFlexpaConnectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsFlexpaConnectedQuery(baseOptions?: Apollo.QueryHookOptions<IsFlexpaConnectedQuery, IsFlexpaConnectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsFlexpaConnectedQuery, IsFlexpaConnectedQueryVariables>(IsFlexpaConnectedDocument, options);
      }
export function useIsFlexpaConnectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsFlexpaConnectedQuery, IsFlexpaConnectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsFlexpaConnectedQuery, IsFlexpaConnectedQueryVariables>(IsFlexpaConnectedDocument, options);
        }
export type IsFlexpaConnectedQueryHookResult = ReturnType<typeof useIsFlexpaConnectedQuery>;
export type IsFlexpaConnectedLazyQueryHookResult = ReturnType<typeof useIsFlexpaConnectedLazyQuery>;
export type IsFlexpaConnectedQueryResult = Apollo.QueryResult<IsFlexpaConnectedQuery, IsFlexpaConnectedQueryVariables>;
export const DrugsUsageDocument = gql`
    query drugsUsage {
  drugsUsage {
    drugName
    ndc
    spent
  }
}
    `;

/**
 * __useDrugsUsageQuery__
 *
 * To run a query within a React component, call `useDrugsUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrugsUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrugsUsageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDrugsUsageQuery(baseOptions?: Apollo.QueryHookOptions<DrugsUsageQuery, DrugsUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DrugsUsageQuery, DrugsUsageQueryVariables>(DrugsUsageDocument, options);
      }
export function useDrugsUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DrugsUsageQuery, DrugsUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DrugsUsageQuery, DrugsUsageQueryVariables>(DrugsUsageDocument, options);
        }
export type DrugsUsageQueryHookResult = ReturnType<typeof useDrugsUsageQuery>;
export type DrugsUsageLazyQueryHookResult = ReturnType<typeof useDrugsUsageLazyQuery>;
export type DrugsUsageQueryResult = Apollo.QueryResult<DrugsUsageQuery, DrugsUsageQueryVariables>;
export const HasEnrollmentDocument = gql`
    query hasEnrollment {
  hasEnrollment
}
    `;

/**
 * __useHasEnrollmentQuery__
 *
 * To run a query within a React component, call `useHasEnrollmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasEnrollmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasEnrollmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasEnrollmentQuery(baseOptions?: Apollo.QueryHookOptions<HasEnrollmentQuery, HasEnrollmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasEnrollmentQuery, HasEnrollmentQueryVariables>(HasEnrollmentDocument, options);
      }
export function useHasEnrollmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasEnrollmentQuery, HasEnrollmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasEnrollmentQuery, HasEnrollmentQueryVariables>(HasEnrollmentDocument, options);
        }
export type HasEnrollmentQueryHookResult = ReturnType<typeof useHasEnrollmentQuery>;
export type HasEnrollmentLazyQueryHookResult = ReturnType<typeof useHasEnrollmentLazyQuery>;
export type HasEnrollmentQueryResult = Apollo.QueryResult<HasEnrollmentQuery, HasEnrollmentQueryVariables>;
export const BenefitsUsageDocument = gql`
    query benefitsUsage {
  benefitsUsage {
    currentDeductible {
      drugDeductible
      drugOutOfPocket
      medicalInnDeductible
      medicalOonDeductible
      medicalOutOfPocket
    }
    deductibleLimit {
      drugDeductible
      drugOutOfPocket
      medicalInnDeductible
      medicalOonDeductible
      medicalOutOfPocket
    }
    plan {
      isFlexpaIntegrated
      bidId
      effectiveDate
      enrollmentId
      orgName
      parentOrgName
      planName
      premium
      renewalDate
      type
      year
      county
      zip
    }
  }
}
    `;

/**
 * __useBenefitsUsageQuery__
 *
 * To run a query within a React component, call `useBenefitsUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefitsUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefitsUsageQuery({
 *   variables: {
 *   },
 * });
 */
export function useBenefitsUsageQuery(baseOptions?: Apollo.QueryHookOptions<BenefitsUsageQuery, BenefitsUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefitsUsageQuery, BenefitsUsageQueryVariables>(BenefitsUsageDocument, options);
      }
export function useBenefitsUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefitsUsageQuery, BenefitsUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefitsUsageQuery, BenefitsUsageQueryVariables>(BenefitsUsageDocument, options);
        }
export type BenefitsUsageQueryHookResult = ReturnType<typeof useBenefitsUsageQuery>;
export type BenefitsUsageLazyQueryHookResult = ReturnType<typeof useBenefitsUsageLazyQuery>;
export type BenefitsUsageQueryResult = Apollo.QueryResult<BenefitsUsageQuery, BenefitsUsageQueryVariables>;