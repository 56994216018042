import { ReceivedRequestModal } from '@coverright/ui/shared';
import * as React from 'react';
import { useState } from 'react';
import { NotServed } from '../components/not-served';
import { addZipToSentRequests, isRequestSent } from '../lib';

export function NotServedModal ({zip, onClose, open}: {open: boolean, onClose: () => void, zip: string}) {
  const [mode, setMode] = useState<'NotServed' | 'Received'>();

  React.useEffect(() => {
    if (open && zip) {
      setMode(isRequestSent(zip) ? 'Received' : 'NotServed');
    } else {
      setMode(undefined);
    }
  }, [open, zip])

  const handleNotServedSubmit = () => {
    setMode('Received');
    if (zip) {
      addZipToSentRequests(zip);
    }
  }

  return <>
    <NotServed onClose={onClose}
               open={mode === 'NotServed'}
               onSubmit={handleNotServedSubmit}/>
    <ReceivedRequestModal onClose={onClose} open={mode === 'Received'}/>
  </>
}
