import { DrugInfoWithGeneric, DrugOutput } from '@coverright/data-access/types/medicare';
import { Box, Button, Fade, IconButton, styled, Typography } from '@mui/material';
import * as _ from 'lodash';
import { XCircle } from '@phosphor-icons/react';
import * as React from 'react';

type Props = {
  drug: DrugInfoWithGeneric | DrugOutput,
  onEditDrugClick: (drug: DrugInfoWithGeneric | DrugOutput) => void,
  onDeleteClick: (drug: DrugInfoWithGeneric | DrugOutput) => void,
}

export const DrugCard = React.memo((props: Props) => {
  return <Wrapper data-test={'drug_card_' + props.drug.name}>
    <Content>
      <Box>
        <Typography className={`bold lh-24 drug-name mb-8`}>{props.drug.name}</Typography>
        <Typography sx={{ maxWidth: 500 }} className={'lh-24'}>{(props.drug as DrugOutput).packName}</Typography>
      </Box>
      {(props.drug as DrugOutput).quantity && <AdditionalContent>
        <Typography className={`lh-24 mb-8`}>
          {props.drug.isGeneric ? 'Generic' : props.drug.genericName || 'Branded'}
        </Typography>

        <Box sx={{display: 'flex', alignItems: { xs: 'center', md: 'flex-end' }, gap: {xs: 1, md: 0.5}, flexDirection: {md: 'column'}}}>
          <Typography className={`lh-24`}>
            {(props.drug as DrugOutput).quantity} {_.upperFirst(_.lowerCase((props.drug as DrugOutput).frequency))}
          </Typography>

          <Button variant={'text'}
                  size={'small'}
                  onClick={() => props.onEditDrugClick(props.drug)}
                  id={'edit-drug-button'}
                  data-test={'edit-drug-button'} >
            Edit drug
          </Button>
        </Box>

      </AdditionalContent>}
    </Content>
    <IconButton onClick={() => props.onDeleteClick(props.drug)} data-test={'delete-button'}>
      <XCircle size={24} color="#460C1D" weight="fill"/>
    </IconButton>
  </Wrapper>;
})

const Wrapper = styled(Box, {
  name: 'DrugSelector',
  slot: 'drugCard',
})({
  display: 'flex',
  padding: '16px 16px 12px',
  borderRadius: '8px',
  alignItems: 'center',
  gap: 16
})

const Content = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flex: 8,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  },
}))

const AdditionalContent = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 24,
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-end',
    textAlign: 'right',
    marginTop: 0,
  },
}))
