import { setClientId } from './ClientId';

const SID_KEY = 'USER';

export function isStoredUserFilled(fields?: string[]): boolean {
  const stored = sessionStorage.getItem(SID_KEY);
  if (!stored) {
    return false
  }
  const user = JSON.parse(stored);
  if (fields) {
    return fields.every(val => !!user[val]);
  } else {
    return user.zip && user.countyName && user.birthDate && user.gender && user.tobacco
  }
}

export function getStoredUser(): IStoredUser | undefined {
  const stored = sessionStorage.getItem(SID_KEY);
  if (!stored) {
    return undefined
  }
  return JSON.parse(stored);
}

export function setStoredUser(user: IStoredUser) {
  sessionStorage.setItem(SID_KEY, JSON.stringify(user));
  if (user.clientId) {
    setClientId(user.clientId)
  }
}

export function resetStoredUser(): void {
  sessionStorage.removeItem(SID_KEY);
}


export interface IStoredUser {
  userId?: string,
  profileId?: string,
  enrollmentId?: string,
  medigapEnrollmentId?: string,
  pdpEnrollmentId?: string,
  clientId?: string,
  quoteId?: string,
  medigapQuoteId?: string,
  pdpQuoteId?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  enrolled?: string,
  phoneNumber?: string,
  birthDate?: string,
  age?: string,
  zip?: string,
  gender?: string,
  tobacco?: string,
  countyName?: string,
  state?: string,
  plansFound?: number,
  profileSource?: string,
}

