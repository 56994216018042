import { TextInput } from '@coverright/ui/inputs';
import { CRModal } from '@coverright/ui/shared';
import * as React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, Typography } from '@mui/material';
import { KeycloakContext, keycloak } from '@coverright/shared/keycloak';
import { useSnackbar } from 'notistack';
import { useLogin } from '@coverright/data-access/enrollment';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

type LoginModalProps = {
  open: boolean;
  onClose: () => void;
  onCreateAccountClick: () => void;
};

export const LoginModal = (props: LoginModalProps) => {
  const { init, keycloak } = React.useContext(KeycloakContext);
  const [login] = useLogin();
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    () => {
      formik.resetForm();
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (formik.isValid) {
        setLoading(true);
        login({
          variables: { password: values.password, username: values.email },
        })
          .then((data) => {
            if (data?.data?.login?.accessToken) {
              init({
                idToken: data?.data?.login?.idToken,
                accessToken: data?.data?.login?.accessToken,
                refreshToken: data?.data?.login?.refreshToken,
              })
                .then(() => {
                  props.onClose();
                })
                .catch(() => {
                  enqueueSnackbar('Something wrong', { variant: 'error' });
                })
                .finally(() => setLoading(false));
            }
          })
          .catch(() => {
            enqueueSnackbar('Something wrong', { variant: 'error' });
            setLoading(false);
          });
      }
    },
    validateOnChange: true,
  });

  return (
    <CRModal
      disableBackdropClick={loading}
      paperSx={{ background: '#029094', p: 3 }}
      width={308}
      open={props.open}
      onClose={props.onClose}
      paperPadding={0}
    >
      <>
        <Typography
          className={'fs-24 lh-30 mb-16 bold'}
          sx={{ color: 'white' }}
        >
          Log in to CoverRight
        </Typography>
        <form onSubmit={formik.handleSubmit} className={'fill-width'}>
          <TextInput
            fullWidth
            name="email"
            label=""
            autoFocus={true}
            placeholder={'Email'}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <div className={'h-8'} />
          <TextInput
            fullWidth
            name="password"
            label=""
            type={'password'}
            placeholder={'Password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button
            disabled={loading}
            sx={{ borderRadius: '4px' }}
            size={'large'}
            color={'warning'}
            variant={'contained'}
            type={'submit'}
            fullWidth
          >
            Sign in
          </Button>
        </form>
        <Typography className={'fs-14 mt-40'} sx={{ color: 'white' }}>
          Don’t have an account?{' '}
          <b className={'pointer'} onClick={props.onCreateAccountClick}>
            Sign up
          </b>
        </Typography>
      </>
    </CRModal>
  );
};
