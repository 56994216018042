export const keycloakConfig = {
  url: process.env.NX_KEYCLOAK_SERVER as string,
  realm: process.env.NX_KEYCLOAK_REALM as string,
  protocol: 'openid-connect',
  clientId: process.env.NX_KEYCLOAK_CLIENT as string,
}

export const keycloakCRMConfig = {
  url: process.env.NX_KEYCLOAK_SERVER as string,
  realm: process.env.NX_KEYCLOAK_CRM_REALM as string,
  protocol: 'openid-connect',
  clientId: process.env.NX_KEYCLOAK_CRM_CLIENT as string,
}
