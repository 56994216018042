import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import { InviteePersonalInfoOutput, QueryCalendlyInviteeArgs } from '@coverright/data-access/types/enrollment';

export function useCalendlyInvitee(options?: LazyQueryHookOptions<{calendlyInvitee: InviteePersonalInfoOutput}, QueryCalendlyInviteeArgs>) {
  return useLazyQuery<{calendlyInvitee: InviteePersonalInfoOutput}, QueryCalendlyInviteeArgs>(
    gql(query),
    {
      ...options,
      client: enrollmentClient
    }
  );
}

export const getCalendlyInvitee = (eventUrl: string, inviteeUrl: string) => {
  return enrollmentClient.query<{calendlyInvitee: InviteePersonalInfoOutput}>({
    query: gql(query),
    variables: {eventUrl, inviteeUrl}
  }).then(r => r.data?.calendlyInvitee)
}

const query = `
query($eventUrl: String!, $inviteeUrl: String!) {
  calendlyInvitee(eventUrl: $eventUrl, inviteeUrl: $inviteeUrl) {
    email
    firstName
    lastName
    phone
  }
}
`;
