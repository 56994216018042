import { split, HttpLink, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import {getMainDefinition} from "@apollo/client/utilities";
import { errorTracker, graphQLErrorHandler } from './GraphQLErrorHandler';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const httpLink = new HttpLink({
  uri: process.env.NX_CMS_GRAPHQL,
  headers: {
    "content-type": "application/json",
    // version: config.buildVersion,
  }
});

const authLink = setContext(async (_, { headers }: any) => {
  return {
    headers: {
      authorization: `Bearer e3c9113acc4017c4345c6535926ecb2029d22877bdb0fbecac6719cd067bec4b1f2185f299ba75ec725c0edd74a8222cd6385329ba57ac916494ea439b57644e52ea01dddaf4f6ff86beca3a792c338063a2c41041610ee9e14baa72106b639506bc58edc2d6831841671306964dc025355b4e2b7e61a442cac308e60bfadd59`,
      ...headers,
    }
  };
});

const wsLink = new GraphQLWsLink(createClient({
  url: process.env.NX_FLEXPA_SUBSCRIPTIONS as string,
  connectionParams: async () => {
    return {
      Authorization: `Bearer d6803766776a353f15c34cc602049c79575e3e6abe9126fdab5c7d83fff3f914795da1186e6276a251e3bfe435fb299e8da50b4ff56f7970f646b07ee34dbc801a41cbab9a93db74b010437dabdcc6de4528f0d4b952a21940fda608aa3aea27264a3e959247f6554489b06b29ea18e2f92d56d053ba1af8d03f002bb4ee2e7c`,
    }
  },
  lazy: true,
}));

const elink = onError((errResponse: ErrorResponse) => {
  errorTracker(errResponse, `Bearer d6803766776a353f15c34cc602049c79575e3e6abe9126fdab5c7d83fff3f914795da1186e6276a251e3bfe435fb299e8da50b4ff56f7970f646b07ee34dbc801a41cbab9a93db74b010437dabdcc6de4528f0d4b952a21940fda608aa3aea27264a3e959247f6554489b06b29ea18e2f92d56d053ba1af8d03f002bb4ee2e7c`,);
  if (graphQLErrorHandler) {
    graphQLErrorHandler(errResponse);
  }
});

const requestLink =
  from([
    authLink,
    elink,
    httpLink,
  ])
;

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  requestLink
);

export const CMSClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});
