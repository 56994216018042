import { CRModal, PageWrapper } from '@coverright/ui/shared';
import React from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AppStateActionTypes, AppStateContext } from '../../AppStateContext';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { AnimationContext } from '@coverright/shared/contexts';
import { CRStyledRadio } from '@coverright/ui/inputs';

export default function () {
  const history = useHistory();
  const [value, setValue] = React.useState<string>('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [state, dispatch] = React.useContext(AppStateContext);
  const {keycloak} = React.useContext(KeycloakContext);
  const {reverse} = React.useContext(AnimationContext)

  const onSubmit = () => {
    if (value === 'Book') {
      history.push('/schedule-a-call')
    } else if (value === 'ReachOut') {
      setModalOpen(true);
    }
  };

  const onModalSubmit = () => {
    setModalOpen(false)
    dispatch({type: AppStateActionTypes.RESET});
    keycloak.logout({ redirectUri: document.location.origin });
  }

  return (
    <PageWrapper title={'Thank you! Does your client want to book an appointment?'}
                 subtitle={'If there is no appointment, a licensed advisor will reach out the next business day.'}
                 formName={'want-a-book'}
                 sx={{mt: {md: '70px'}}}
                 nextButtonDisabled={!value}
                 onNextButtonClick={onSubmit}
                 onBackButtonClick={() => {
                   reverse && reverse();
                   setTimeout(() => history.push('/personal-info'), 50)
                 }}
    >
      <CRModal open={modalOpen} width={500}>
        <>
          <IconButton sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }} onClick={() => setModalOpen(false)}><img src={'/assets/img/close.svg'} /></IconButton>
          <Typography align={'center'} className={'fs-32 medium mb-20'}>
            Thanks for submitting your ticket.
          </Typography>
          <Typography align={'center'} className={'fs-24 dark-green mb-20'}>
            A licensed Medicare expert will reach out to your client within the next business day
          </Typography>
          <Box display={'flex'} justifyContent={'center'}>
            <Button variant={'contained'} size={'large'} color={'primary'} onClick={onModalSubmit}>Return to Start</Button>
          </Box>
        </>
      </CRModal>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: {xs: 'column', md: 'row'}, gap: '12px', marginBottom: '20px'}}>
        <div data-test='select_mg_coverage'>
          <CRStyledRadio checked={value === 'ReachOut'}
                       imageClassName={'w-130'}
                       height={230}
                       onClick={() => setValue('ReachOut')}
                       label={'No, reach out as soon as possible'}
                       imgUrl={'/assets/img/speack-to-advisor-arrow.svg'} />
        </div>
        <div data-test='select_ma_coverage'>
          <CRStyledRadio checked={value === 'Book'}
                       imageClassName={'w-130'}
                       height={230}
                       onClick={() => setValue('Book')}
                       label={'Yes, book an appointment now'}
                       imgUrl={'/assets/img/calendar.svg'} />
        </div>
      </Box>
      <div className={'h-40'} />
    </PageWrapper>
  );
}

