import * as React from "react";
import { getQuoteId, getStoredUser, listenQuoteIdChange, resetStoredUser } from '@coverright/utils';
import useFetch from 'use-http'
import { KeycloakContext } from '@coverright/shared/keycloak';
import { PdpPlan, Plan } from '@coverright/data-access/types/medicare';

export const AppContext = React.createContext<[IAppState, (action: IAppAction) => void, boolean]>([] as any);

let initialState: IAppState = {
  state: {}
}

const stored = sessionStorage.getItem('appState');
if (stored) {
  initialState = {
    ...initialState,
    ...JSON.parse(stored)
  }
}

export const AppContextProvider = (props: any) => {
  const {keycloak, initialized} = React.useContext(KeycloakContext);
  const { get, post, response, loading, error } = useFetch();
  const [loaded, setLoaded] = React.useState(false);

  const reducer = React.useCallback((state: IAppState, action: IAppAction) => {
    let newState: IAppState = {...state};
    switch (action.type) {
      case AppActionTypes.INIT: {
        if (typeof action.payload !== 'undefined') {
          Object.assign(newState, action.payload)
        }
        break;
      }
      case AppActionTypes.RESET: {
        newState = {...initialState};
        break;
      }
      case AppActionTypes.SAVE_STATE: {
        if (typeof action.payload !== 'undefined') {
          const apply = (key: keyof IAppStateValue, value: any) => {
            // @ts-ignore
            newState.state[key] = value;
          }
          if (Array.isArray(action.payload)) {
            action.payload.forEach(v => apply(v.stateKey, v.value));
          } else {
            const { stateKey, value } = action.payload;
            apply(stateKey, value);
          }
        }
        const body: any = {...newState};

        if (!action.local) {
          if (getQuoteId()) {
            body.quoteId = getQuoteId();
            post('/saveState', body);          } else {
            sessionStorage.setItem('unsavedAppState', JSON.stringify(newState))
          }
        }
        break;
      }

      default:
        throw new Error();
    }

    sessionStorage.setItem('appState', JSON.stringify(newState))

    return newState;
  }, []);

  const [state, dispatch] = React.useReducer<(state: IAppState, action: IAppAction) => IAppState>(reducer, initialState);

  React.useEffect(() => {
    listenQuoteIdChange(callback)
    if (getQuoteId()) {
      callback()
    }
  }, []);

  const callback = (quoteId?: string) => {
    if (quoteId || getQuoteId()) {
      get('/getState/' + (quoteId || getQuoteId())).then(res => {
        if (response.status === 200) {
          dispatch({
            type: AppActionTypes.INIT,
            payload: res
          })
        }
      }).finally(() => {
        setLoaded(true)
        if (sessionStorage.getItem('unsavedAppState')) {
          const unsaved = JSON.parse(sessionStorage.getItem('unsavedAppState')!);
          const body: any = {...unsaved};
          body.quoteId = quoteId || getQuoteId();
          dispatch({
            type: AppActionTypes.SAVE_STATE,
            payload: Object.keys(unsaved).map(key => ({stateKey: key as any, value: unsaved[key]}))
          })
          sessionStorage.removeItem('unsavedAppState')
        }
      });
    }
  }

  React.useEffect(() => {
    if (initialized) {
      if (!keycloak?.authenticated && getStoredUser()?.profileId && dispatch) {
        resetStoredUser();
        dispatch({type: AppActionTypes.RESET})
      }
    }
  }, [keycloak?.authenticated, initialized, dispatch]);

  return (
    <AppContext.Provider value={[state, dispatch, loaded]}>
      {props.children}
    </AppContext.Provider>
  );
};

export const withAppContextProvider = (WrappedComponent: any) => (props: any) => {
  return (
    <AppContextProvider>
      <WrappedComponent {...props} />
    </AppContextProvider>
  )
}

export enum AppActionTypes {
  SAVE_STATE,
  INIT,
  RESET
}

type IAppAction = ISaveStateAppAction | IInitAppAction | IResetQuoteAppAction;

interface IInitAppAction {
  payload?: IAppState,
  type: AppActionTypes.INIT
}

interface ISaveStateAppAction {
  payload?: Many<IAppStateActionPayload>,
  type: AppActionTypes.SAVE_STATE,
  local?: boolean
}

interface IResetQuoteAppAction {
  type: AppActionTypes.RESET
}

interface IAppStateActionPayload {
  stateKey: keyof IAppStateValue,
  value: any
}

interface IAppStateValue {
  returnToDashboard?: boolean,
  videosApplied?: boolean,
  marketplaceRedirect?: 'MA' | 'MG' | 'PDP',
  plansShown?: boolean,
  advisorCall?: boolean,
  appointmentType?: number,
  selectedCoverage?: 'MA' | 'MG' | 'PDP',
  existingCoverage?: 'MA' | 'PDP',
  maCarrier?: string,
  mgCarrier?: string,
  pdpCarrier?: string,
  maCurrentPlan?: string,
  pdpCurrentPlan?: string,
  quizSelected?: number,
  quizSuggested?: string,
  quizSuggestedText?: string,
  quizCompleted?: boolean,
  quizSelectedText?: string,

  videosCompleted?: string[],
  callScheduled?: boolean,
  greetingShown?: boolean,
  forbesHealthLogo?: boolean,
  logoOverride?: {
    forbesHealth: boolean,
    benzinga: boolean,
    assetInsure: boolean,
    bellInsurance: boolean,
    rightsure: boolean,
  },
  quizAnswers?: boolean[],
  maSavedPlans?: string[],
  mgSavedPlans?: string[],
  overrideMAEnrollment?: Plan,
  overridePDPEnrollment?: PdpPlan,
  abTesting?: {
    [key: string]: 'a' | 'b' | 'ab'
  },
  additionalInfo?: string[]
}

export interface IAppState {
  state: IAppStateValue
}

type Many<T> = T | T[];
