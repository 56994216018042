import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Scrollbar from 'react-scrollbars-custom';
import clsx from 'clsx';

const useListStyles = makeStyles({
  scrollbar: {
    height: (props: ScrollbarProps) => typeof props.height === 'undefined' ?
      '250px!important' :
      typeof props.height === 'number' ? props.height + 'px!important' : props.height + '!important',
    '& .ScrollbarsCustom-Wrapper': {
      right: '8px!important'
    },
    '& .ScrollbarsCustom-Track': {
      top: '0px!important',
      height: '100%!important',
      width: '8px!important',
      background: '#1C434F1F!important',
    },
    '& .ScrollbarsCustom-TrackX': {
      display: 'none!important'
    },
    '& .ScrollbarsCustom-Thumb': {
      background: '#1C434F!important',
    },
    '& .ScrollbarsCustom-Content': {
      height: '1px',
    },
    '& .ScrollbarsCustom-Content li:hover': {
      background: '#F5F7F6!important',
    }
  }
});

type ScrollbarProps = {
  scrollTo?: number,
  children: any,
  className?: string,
  height?: number | string,
}

export const ScrollbarList = (props: ScrollbarProps) => {
  const classes = useListStyles(props);
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(undefined, props.scrollTo);
    }
  }, [ref, props.scrollTo])

  return <Scrollbar ref={ref} className={clsx(classes.scrollbar, props.className)}>
    {props.children}
  </Scrollbar>
}
