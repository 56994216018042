import { AppBar, Box, Button, IconButton, SwipeableDrawer, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import { List, Phone, X } from '@phosphor-icons/react';
import { styled } from '@mui/material/styles';

interface Props {
  logo?: JSX.Element,
  onLogoClick?: () => void,
  onScheduleClick?: () => void,
  onSignInClick?: () => void,
}

export default function({ logo, onLogoClick, onScheduleClick, onSignInClick }: Props) {
  const [open, setOpen] = React.useState(false);

  const Logo = React.useMemo(() => {
    return logo ? logo : <Box component={'img'}
                              sx={{ mr: 2 }}
                              src={'/assets/img/Logo.svg'}
                              width={132}
                              height={36}
                              className={'pointer'}
                              onClick={onLogoClick}/>;
  }, [logo, onLogoClick]);

  return <>
    <SwipeableDrawer
      anchor={'left'}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Toolbar sx={{ p: '29px 40px', m: 0 }}>
        <Box sx={{ display: 'flex', width: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          {Logo}
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { lg: 'none' }, ml: '20px' }}
            onClick={() => setOpen(false)}
          >
            <X size={32} color="000"/>
          </IconButton>
        </Box>
      </Toolbar>
      <Box sx={{ p: '23px 32px' }}>
        {onSignInClick && <Button sx={{ mb: 2 }}
                 fullWidth
                 variant={'outlined'}
                 onClick={onSignInClick}
                 color={'primary'}>
          Sign in
        </Button>}

        <Button onClick={onScheduleClick}
                fullWidth
                data-test={'schedule-a-call-button'}
                variant={'contained'}
                color={'primary'}>
          Schedule A Call
        </Button>
      </Box>
    </SwipeableDrawer>


    <AppBar>
      <Toolbar sx={{ p: { xs: '29px 40px', md: '24px 72px', xl: 3 } }}>
        <Box sx={{ display: 'flex', width: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {Logo}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: { xs: 'none', lg: 'flex' }, alignItems: 'center', gap: { xs: 2, xl: 3 } }}>
              <Box sx={{
                display: { xs: 'none', lg: 'flex' },
                ml: { xs: '43px', xl: '100px' },
                gap: { xs: '32px', xl: '48px' },
                alignItems: 'center'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mr: { xs: '43px', xl: '88px' }, gap: 1 }}>
                  <Phone size={24} color="1C434F" weight="fill"/>
                  <Box component={'a'} href={'tel:+18882915580'}
                       sx={{
                         fontSize: 16,
                         lineHeight: '19px',
                         fontWeight: 500,
                         cursor: 'pointer',
                         whiteSpace: 'nowrap',
                         textDecoration: 'none',
                         color: '#1C434F',
                       }}>(888) 969-7667 | TTY: 711</Box>
                </Box>
              </Box>

              {onSignInClick && <SignInLink onClick={onSignInClick}>Sign in</SignInLink>}

              <Button variant={'contained'}
                      color={'primary'}
                      onClick={onScheduleClick}>
                Schedule A Call
              </Button>
            </Box>


            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: { lg: 'none' }, ml: '20px' }}
              onClick={() => setOpen(true)}
            >
              <List size={32} color="1C434F" weight="fill"/>
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  </>;
}

const SignInLink = styled(Typography)({
  fontSize: 18,
  lineHeight: '20px',
  fontWeight: 600,
  color: '#1C434F',
  cursor: 'pointer',
  borderBottom: '1px solid white',
  '&:hover': {
    borderBottom: '1px solid #19605D'
  },
  whiteSpace: 'nowrap',
  mr: { xs: 2, xl: 3 }
});
