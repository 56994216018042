import * as React from 'react';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { AdminContext, QuoteContext } from '@coverright/shared/contexts';
import { AgentViewOutput } from '@coverright/data-access/types/enrollment';
import { useAgentAssignedToClient } from '@coverright/data-access/enrollment';
import { getStoredUser } from '@coverright/utils';

const defaultAgent: AgentViewOutput = {
  id: '',
  email: 'richardc@coverright.com',
  firstName: 'Richard',
  lastName: 'Chan',
  phone: '+1 (646) 315-6206',
  picUrl: 'https://cdn.coverright.com/agent-avatar/2426decb-7529-4648-a4ce-42bb4f1d35e9.png',
  enrollmentMeetingUrl: 'https://calendly.com/coverright-advisors/enrollment',
  planReviewUrl: 'https://calendly.com/coverright-advisors/medicare-consultation-client',
  consultationUrl: 'https://calendly.com/coverright-advisors/medicare-consultation-client',
}

export function useClientAgent(cdnUrl = '') {
  const {keycloak} = React.useContext(KeycloakContext);
  const {clientId} = React.useContext(QuoteContext);
  const adminContext = React.useContext(AdminContext);
  const [agent, setAgent] = React.useState<AgentViewOutput>();
  const [getAgent] = useAgentAssignedToClient();

  React.useEffect(() => {
    if (keycloak.authenticated) {
      const clId = adminContext?.clientId || clientId || getStoredUser()?.clientId;
      if (clId) {
        getAgent({variables: {clientId: clId}}).then(data => {
          if (data.data?.agentAssignedToClient) {
            const picUrl = data.data?.agentAssignedToClient.picUrl ? cdnUrl + data.data?.agentAssignedToClient.picUrl : undefined;
            setAgent({
              ...data.data?.agentAssignedToClient,
              consultationUrl: data.data?.agentAssignedToClient.consultationUrl || defaultAgent.consultationUrl,
              picUrl
            })
          } else {
            setAgent(defaultAgent);
          }
        });
      }
    } else {
      setAgent(defaultAgent);
    }
  }, [keycloak.authenticated, adminContext, clientId]);

  return agent
}
