import {PageWrapper, CRInfo} from '@coverright/ui/shared';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AppStateActionTypes, AppStateContext } from '../../AppStateContext';
import { AnimationContext } from '@coverright/shared/contexts';
import { CRStyledRadio } from '@coverright/ui/inputs';

export default function () {
  const history = useHistory();
  const [state, dispatch] = React.useContext(AppStateContext);
  const [value, setValue] = React.useState<'Quote' | 'Submit'>();
  const {reverse} = React.useContext(AnimationContext)

  React.useEffect(() => {
    setValue(state.visitInfo)
  }, [state.visitInfo])

  const onSubmit = () => {
    if (value) {
      dispatch({
        type: AppStateActionTypes.UPDATE,
        payload: {
          visitInfo: value
        }
      });
      history.push('/client-details')
    }
  };

  return (
    <PageWrapper title={'Welcome! Tell us about your visit today so we can better assist you...'}
                 formName={'visit-info'}
                 nextButtonDisabled={!value}
                 onBackButtonClick={() => {
                   reverse && reverse();
                   setTimeout(() => history.push('/'), 50)
                 }}
                 sx={{mt: {md: '70px'}}}
                 onNextButtonClick={onSubmit}
    >
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: {xs: 'column', md: 'row'}, gap: '12px', marginBottom: '20px'}}>
        <div data-test='select_mg_coverage'>
          <CRStyledRadio checked={value === 'Submit'}
                       imageClassName={'w-130'}
                       onClick={() => setValue('Submit')}
                       label={<div>
                         <Typography align={'center'} className={'fs-18 lh-22 ls-1 medium mb-8'}>Submit a<br />Request</Typography>
                         <Typography align={'center'} className={'fs-16 lh-20 ls-1 light'}>Submit your client’s basic info and CoverRight handles the rest</Typography>
                       </div>}
                       imgUrl={'/assets/img/submit-new-ticket.svg'} />
        </div>
        <div data-test='select_ma_coverage'>
          <CRStyledRadio checked={value === 'Quote'}
                       imageClassName={'w-130'}
                       onClick={() => setValue('Quote')}
                       label={<div>
                         <Typography align={'center'} className={'fs-18 lh-22 ls-1 medium mb-8'}>Quote and Book<br />a Call</Typography>
                         <Typography align={'center'} className={'fs-16 lh-20 ls-1 light'}>Compare your client’s options and book a call for an advisor to reach out.</Typography>
                       </div>}
                       imgUrl={'/assets/img/quote-a-call.svg'} />
        </div>
      </Box>
      <div className={'h-40'} />
    </PageWrapper>
  );
}

