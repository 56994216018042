import React, { forwardRef } from 'react';
import { Box, OutlinedInputProps, OutlinedTextFieldProps, Theme, Typography, useTheme } from '@mui/material';
import { TextInput } from '../..';
import moment from 'moment';
import { useDebouncedEffect } from '@coverright/utils';
import { Calendar } from '@phosphor-icons/react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { createStyles, makeStyles } from '@mui/styles';
import { SxProps } from '@mui/material';

const format = 'YYYY-MM-DD';

const useStylesHelperText = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: '100%',
    },
    contained: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      marginTop: 6,
      whiteSpace: 'nowrap',
    },
  }),
);

export interface DateInputProps {
  onChange?: (value: string | null) => void,
  onError?: (reason: any, value: any) => void,
  value?: string | null,
  format?: string,
  disabled?: boolean,
  autoFocus?: boolean,
  disablePast?: boolean,
  disableFuture?: boolean,
  minDate?: Date,
  maxDate?: Date,
  id?: string,
  sx?: SxProps,
  wrapperSx?: SxProps,
  open?: boolean,
  clearOnFocus?: boolean,
  InputProps?: Partial<OutlinedInputProps>,
  inputProps?: Partial<OutlinedTextFieldProps & {inputClassName?: string, inputLabelClass?: string}>,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void,
}

const Input = (props: DateInputProps, ref: any)  => {
  const theme = useTheme();
  const [value, setValue] = React.useState<Date | null>(null);
  const [stringValue, setStringValue] = React.useState('');
  const [error, setError] = React.useState('');
  const helperTextClasses = useStylesHelperText();

  React.useEffect(() => {
    if (typeof props.value !== 'undefined') {
      if (props.value && moment(props.value).isValid()) {
        setStringValue(props.value || '');
        setValue(moment(props.value).toDate())
      } else if (props.value === null) {
        setStringValue('');
        setValue(null)
      }
    }
  }, [props.value]);

  useDebouncedEffect(() => {
    if (moment(value).isValid()) {
      const date = value ? moment(value).format(props.format || format) : '';
      if (date != stringValue) {
        setStringValue(date)
        if (props.onChange) {
          props.onChange(date);
        }
      }
    } else if (value === null) {
      if (props.onChange) {
        props.onChange(value);
      }
    }
  }, 200,[value, stringValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{width: 1, ...props.wrapperSx}}>
        {
          props.inputProps?.label && (
            <Typography sx={{
              fontSize: '16px',
              lineHeight: '19px',
              marginBottom: '8px',
              fontWeight: 600,
              color: theme.palette.text.primary,
            }} className={[props.inputProps?.inputLabelClass, 'text-input-label'].filter(v => !!v).join(' ')}>
              {props.inputProps?.label}
            </Typography>
          )
        }
        <DatePicker
          disabled={props.disabled}
          ref={ref}
          sx={{
            width: 1,
            ...props.sx
          }}
          autoFocus={props.autoFocus}
          slotProps={{
            textField: {
              value: value,
              error: props.inputProps?.error,
              helperText: props.inputProps?.helperText,
              onFocus: props.onFocus,
              onClick: props.onClick,
              InputProps: {
                ...props.InputProps
              },
              inputProps: {
                'data-test': 'birth-date',
                onClick: props.clearOnFocus ? e => (e.target as any).value = '' : undefined,
                placeholder: typeof props.inputProps?.placeholder === 'undefined' ? props.inputProps?.placeholder : 'MM / DD / YYYY'
              },
              FormHelperTextProps: {
                classes: {
                  ...helperTextClasses,
                }
              }
            }
          }}
          slots={{
            openPickerIcon: Icon,
            /*textField: ({variant, ...params}: any) => <TextInput
              {...params}
              value={value}
              endAdornmentSx={{mr: '-16px'}}
              inputLabelClass={props.inputProps?.inputLabelClass}
              className={props.inputProps?.className}
              inputClassName={props.inputProps?.inputClassName}
              InputProps={{
                ...params.InputProps,
                ...props.InputProps
              }}
              id={props.id}
              onFocus={props.onFocus}
              inputProps={{
                ...params.inputProps,
                onClick: props.clearOnFocus ? e => (e.target as any).value = '' : undefined,
                placeholder: typeof props.inputProps?.placeholder === 'undefined' ? props.inputProps?.placeholder : 'MM / DD / YYYY'
              }}
              error={props.inputProps?.error || params.error}
              helperText={params.error ? error : props.inputProps?.helperText}
            />*/
          }}
          label={undefined}
          value={value}
          onChange={(newValue: any) => {
            setValue(newValue);
          }}
          open={props.open}
          disablePast={props.disablePast}
          disableFuture={props.disableFuture}
          minDate={props.minDate || new Date(1900, 0, 1)}
          maxDate={props.maxDate}
          onError={(reason: any, value: any) => {
            let err: string;
            switch (reason) {
              case "invalidDate":
                err = "Please enter a valid date";
                break;

              case "disablePast":
                err = "Values in the past are not allowed";
                break;

              case "maxDate":
                err = `Date should not be after ${moment(props.maxDate).format("L")}`;
                break;

              case "minDate":
                err = `Date should not be before ${moment(props.minDate || new Date(1900, 0, 1)).format("L")}`;
                break;

              case "shouldDisableDate":
                err = 'Please enter a valid date';
                break;

              default:
                err = '';
            }
            setError(err);
            if (props.onError) {
              props.onError(err, moment(value).format(props.format || format));
            }
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}

export const DateInput = forwardRef(Input);

const Icon = (props: any) => <Calendar className={'mr-4'} size={24} color="#1C434F" weight="fill" />
