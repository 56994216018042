import * as React from 'react';

interface IAdvisorContext {
  isAdvisor: boolean,
  reset: () => void,
}

export const AdvisorContext = React.createContext<IAdvisorContext>({isAdvisor: sessionStorage.getItem('advisor') === 'true', reset: () => {}});

export function AdvisorContextProvider(props: React.PropsWithChildren<{}>) {
  const [isAdvisor, setIsAdvisor] = React.useState(sessionStorage.getItem('advisor') === 'true');

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('advisor')) {
      sessionStorage.setItem('advisor', 'true');
      setIsAdvisor(true);
    }
  }, []);

  const reset = () => {
    sessionStorage.removeItem('advisor');
    setIsAdvisor(false);
  }

  return <AdvisorContext.Provider value={{isAdvisor, reset}}>
    {props.children}
  </AdvisorContext.Provider>
}

export const withAdvisorContext = (WrappedComponent: any) => (props: any) => {
  return (
    <AdvisorContextProvider>
      <WrappedComponent {...{...props}} />
    </AdvisorContextProvider>
  )
}
