import { MutationHookOptions, useMutation } from '@apollo/client';
import {gql} from "@apollo/client";
import { MutationSaveMedigapQuoteArgs, SaveMedigapQuoteOutput } from '@coverright/data-access/types/medigap';

export function useSaveMedigapQuote(options?: MutationHookOptions<{ saveMedigapQuote: SaveMedigapQuoteOutput }, MutationSaveMedigapQuoteArgs>) {
  return useMutation<{ saveMedigapQuote: SaveMedigapQuoteOutput }, MutationSaveMedigapQuoteArgs>(
    gql(summaryQuery),
    {
      fetchPolicy: 'no-cache',
      ...options
    }
  );
}


const summaryQuery = `
mutation ($input: MedigapQuoteInput!) {
  saveMedigapQuote(input: $input) {
    clientId
    mgQuoteId
  }
}
`;
