import {Cookies} from "react-cookie";
const cookie = new Cookies();

export function getCId(): string | undefined {
  return getVid() || getUId();
}

export function getVid(): string | undefined {
  return (window as any)?.gaGlobal?.vid;
}

export function getUId(): string | undefined {
  return cookie.get('__opix_uid') || localStorage.getItem('__opix_uid');
}
