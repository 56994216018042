import { LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { UserProfileOutput } from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import * as React from 'react';

export function useUserProfile(options?: QueryHookOptions<{userProfile: UserProfileOutput}, {profileId?: string}>) {
  const [userProfile, setUserProfile] = React.useState<UserProfileOutput>();
  const request = useQuery<{userProfile: UserProfileOutput}>(gql(query), {
    client: enrollmentClient,
    fetchPolicy: "no-cache",
    ...options
  });

  React.useEffect(() => {
    setUserProfile(request?.data?.userProfile)
  }, [request.data])

  return {userProfile, request};
}

export function useUserProfileLazy(options?: LazyQueryHookOptions<{userProfile: UserProfileOutput}, {profileId?: string}>) {
  return useLazyQuery<{userProfile: UserProfileOutput}, {profileId?: string}>(gql(query), {
    client: enrollmentClient,
    fetchPolicy: "no-cache",
    ...options
  });
}

const query = `
{
  userProfile {
    tobacco
    birthDate
    email
    firstName
    gender
    lastName
    phoneNumber
    quoteId
    pdpQuoteId
    medigapQuoteId
    state
    userId
    zip
    countyName
    profileId
  }
}
`
