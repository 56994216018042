import * as React from 'react';
import { Box, Typography } from '@mui/material';

export const getGapTooltipByTier = (tier: any): string => {
  switch (tier) {
    case 'GENERIC': return 'Chemically equivalent versions of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case 'NON_PREFERRED_BRAND': return 'A drug sold by a drug company under a specific name or trademark and that is protected by a patent. ';
  }
  return '';
}

export const getInitialTooltipByTier = (tier: any): string => {
  switch (tier) {
    case 'PREFERRED_GENERIC': return 'Typically includes generic drugs that have been available for a long period of time. Chemically equivalent variants of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case 'GENERIC': return 'Chemically equivalent variants of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case 'PREFERRED_BRAND': return 'Typically the brand-name drugs that have been available for a long period of time and are widely accepted. Typically lower-cost among all brand-name drugs.';
    case 'NON_PREFERRED_BRAND': return 'Typically includes higher cost than Tier 3, non-preferred generic and brand-name drugs.';
    case 'SPECIALTY': return 'Typically higher cost than Tier 4, non-preferred generic and brand-name drugs.';
    case 'SELECT_CARE_DRUGS': return 'Typically highest cost, non-preferred generic and brand-name drugs.';
  }
  return '';
}

export const tooltips = {
  pdpTotalCost: 'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered). See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.',
  starRating: 'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.',
  lateEnrollmentPenalties: 'Late Enrollment Penalties can apply if you delay enrollment in Medicare after you first become eligible at 65 and do not have any qualifying exception that allows you to delay.  Late Enrollment Penalties exist to make certain that there is not a ‘free-rider’ problem where eligible Medicare beneficiaries delay enrollment and only sign up for Medicare when they are sick or require Medicare-covered services to skip paying premiums while they were healthy.  Penalties exist for Part A (Hospital Insurance), Part B (Medical Insurance), and Part D (Prescription Drug Coverage).',
  medicaid: 'Medicaid is a joint federal and state program that helps people with limited income and resources with medical costs.  You can be eligible for both Medicaid and Medicare at the same time.  Each state has different rules about eligibility and applying for Medicaid. You can contact your State Medical Assistance (Medicaid) office for more information and to see if you qualify.',
  extraHelp: 'Medicare Extra Help, also known as Part D \'Low Income Subsidy\' (LIS), assists Medicare beneficiaries in paying for their prescription drugs, particularly if they have low income and resources.  This federal program, run by the Social Security Administration (SSA), pays for a Medicare beneficiary’s Part D expenses (premiums, deductibles, copayments, and coinsurance) through full and partial subsidies.',
  medicareAdvantage: 'Medicare Advantage plans are an all-in-one bundled private "alternative" to Original Medicare that includes Part A, Part B, and usually Part D. Most plans offer extra benefits — like vision, hearing, dental and more.',
  pdp: 'You can access Medicare drug coverage (Part D) by either purchasing a standalone Prescription Drug Plan (PDP) or have it bundled via a Medicare Advantage plan.  If you choose to stay in Original Medicare (versus enrolling in a Medicare Advantage plan), you will typically need to purchase standalone PDP to avoid penalties - this also applies if you are in Original Medicare and have purchased a Medicare Supplement plan.',
  medicareSupplement: 'Medicare Supplement (also known as "Medigap") plans are supplemental insurance plans that are sold by health private insurance companies. Medicare Supplement policies charge a monthly premium and help to cover out of pocket costs (such as deductibles, copays and coinsurance) that you otherwise may be responsible to pay under Original Medicare.',
  outOfPocketCosts: 'Original Medicare does not cover all your medical/hospital costs. Out-of-pocket costs include any costs you have to pay for covered healthcare services and includes deductibles, copays and/or coinsurance amounts for doctor/hospital visits and other healthcare-related services.  With Original Medicare you typically have to pay 20% out-of-pocket for outpatient services, such as seeing your doctor and there is no limit on out-of-pocket costs.  Purchasing a Medicare Supplement plan can help contain these costs.',
  maxOutOfPocket: 'All Medicare Advantage plans have a maximum out-of-pocket cost limit each year.  This limit applies to any costs that you have to pay for covered healthcare services and includes deductibles, copays and/or coinsurance amounts for doctor/hospital visits and other healthcare-related services. Once you reach the maximum out-of-pocket limit, the health plan pays 100% of your healthcare costs.  The limit does not apply to premiums, balance-billed charges from out-of-network providers (unless your plan covers out-of-network providers), or other services that are not covered by the plan.',
  planPremium: 'This is the premium you have to pay in addition to your standard Part B premium for your private coverage.',
  partAOriginal: <div>
    Part A (Hospital insurance) coverage includes:
    <ul>
      <li>Inpatient care in a hospital</li>
      <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
      <li>Hospice care</li>
      <li>Home health care</li>
      <li>Inpatient care in a religious non-medical health care institution</li>
    </ul>
  </div>,
  partBOriginal: <div>
    Part B (Medical insurance) coverage includes:
    <ul>
      <li>Doctor visits</li>
      <li>Medically necessary outpatient health services</li>
      <li>Home health services</li>
      <li>Durable medical equipment</li>
      <li>Mental health services</li>
      <li>Preventive services</li>
    </ul>
  </div>,
  pdpOriginal: 'Part D (Prescription drug coverage) is coverage for your prescription drugs. If you are in Original Medicare, you can access Medicare drug coverage (Part D) by purchasing a standalone Prescription Drug Plan (PDP).  Late enrollment penalties can apply if you do not enroll in any sort of Part D coverage after you first become eligible for Medicare - exceptions apply if you have other qualifying (or \'creditable\') drug coverage such as coverage from an employer, TRICARE or VA that provides drug coverage that is as good as Medicare’s.  This applies even if you are in Original Medicare and have a Medicare Supplement plan.',
  extraBenefitsOriginal: 'Original Medicare will not cover any services that are not \'medically necessary\' and therefore does not cover extra benefits such as routine dental, vision, hearing and other benefits.',
  useAnyDoctorOriginal: 'With Original Medicare you can see visit any doctor or hospital that accepts Medicare in the U.S.',

  doctorVisitsOriginal: 'This is the cost you will pay to see your doctor.',
  doctorVisitsMA: 'This is the cost you will pay to see your doctor(s).  This range covers both primary care and specialists.  Primary care doctor vists can cost as low as $0 while specialist visits are typically at the higher end of this range.',
  doctorVisitsMG: 'This is the cost you will pay to see your doctor.',

  STHospitalOriginal: 'Also known as the \'Part A Deductible\'. This is a fixed dollar cost you will have to pay to if you are admitted into a hospital.  This fixed payment covers your first 60 days of stay.',
  STHospitalMA: 'This is the cost you will have to pay to if you are admitted into a hospital.  ',
  STHospitalMG: 'This is the cost you will have to pay to if you are admitted into a hospital.  When you purchase a Medicare Supplement plan, you are choosing to stay in Original Medicare and supplementing it with \'gap\' coverage.  Your final out-of-pockets will be determined by how much your plan will pay for the \'gaps\' in Original Medicare.',

  LTHospitalOriginal: 'Also known as the \'Part A Copays/Coinsurances\'.  This is the cost you will pay for days 61-90 if you are admitted into a hospital.  If you stay beyond 90 days, you may be responsible for up to 100% of the cost.',
  LTHospitalMA: 'Medicare Advantage plans typically require you to pay daily copays for the first few days of a hospital stay. After this you are typically covered at no additional cost up until 90 days of stay.  Some plans may cover an unlimited number of days at no additional cost beyond the initial 90-day coverage.',
  LTHospitalMG:  'This is the cost you will have to pay to if you are admitted into a hospital for more than 60 days.  When you purchase a Medicare Supplement plan, you are choosing to stay in Original Medicare and supplementing it with \'gap\' coverage.  Your final out-of-pockets will be determined by how much your plan will pay for the \'gaps\' in Original Medicare.',

  deductibleOriginal: 'Also known as the \'Part B Deductible\'.  This is the amount you must pay out of pocket each year for healthcare services received before your coverage begins to pay. ',
  deductibleMA: 'This is the amount you must pay out of pocket each year for healthcare services received before your coverage begins to pay. ',
  deductibleMG:  'This is the amount you must pay out of pocket each year for healthcare services received before your coverage begins to pay. ',


  maxOOPOriginal: 'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each year.  This includes copays, coinsurance amounts for doctor/hospital visits and other covered healthcare services. ',

  maxOOPMG:  'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each year.  This includes copays, coinsurance amounts for doctor/hospital visits and other covered healthcare services. ',


  MADays: 'Medicare Advantage plans typically require you to pay daily copays for the first few days of a hospital stay.  The amount and the number of days you need to pay the copay will vary by plan.   This is different to Original Medicare where you have to pay the full fixed copay which covers the first 60 days regardless of how many days you are in hospital.',

  partAMA: <div>
    Medicare Advantage plans are required to cover the same services that are covered under Original Medicare Hospital (Part A) coverage such as:
    <ul>
      <li>Doctor visits</li>
      <li>Medically necessary outpatient health services</li>
      <li>Home health services</li>
      <li>Durable medical equipment</li>
      <li>Mental health services</li>
      <li>Preventive services</li>
    </ul>
  </div>,
  partBMA: <div>
    Medicare Advantage plans are required to cover the same services that are covered under Original Medicare Medical (Part B) coverage such as:
    <ul>
      <li>Inpatient care in a hospital</li>
      <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
      <li>Hospice care</li>
      <li>Home health care</li>
      <li>Inpatient care in a religious non-medical health care institution</li>
    </ul>
  </div>,
  pdpMA: 'Part D (Prescription drug coverage) is coverage for your prescription drugs. The vast majority (89%) of Medicare Advantage plans bundle Part D prescription drug coverage within the plan at no additional cost.  Late enrollment penalties can apply if you do not enroll in any sort of Part D coverage after you first become eligible for Medicare - exceptions apply if you have other qualifying (or \'creditable\') drug coverage such as coverage from an employer, TRICARE or VA that provides drug coverage that is as good as Medicare\'s.',
  extraBenefitsMA: 'Medicare Advantage plans will typically bundle in extra benefits not covered by Original Medicare such as routine dental, vision, hearing and other benefits.  These benefits are often provided with no additional premium.',
  useAnyDoctorMA: <div>
    There are two major types of Medicare Advantage plans:
    <ul>
      <li>Health Maintenance Organization (HMO):  With an HMO plan you generally have to use doctors and hospitals within a plan’s specific network to receive services.  You will have to select a primary care doctor who will help coordinate your care and specialists will typically require a referral.  Except for emergency circumstances, HMO plans will not cover any care outside of the plan's network.</li>
      <li>Preferred Provider Organization (PPO): With a PPO plan you flexibility to see doctors outside of the plan's network (which generally includes from any provider in the U.S. who accepts Medicare).  PPOs also have in-network providers (known as 'preferred' providers).  If you choose to use a doctor/hospital outside a plan's network you will typically pay a higher copay or coinsurance than in-network.  You do not need to choose a primary care provider and are generally are not required to get a referral prior to seeing a specialist.</li>
    </ul>
  </div>,

  partAMG: <div>
    When you purchase a Medicare Supplement plan, you are choosing to stay in Original Medicare and supplementing it with 'gap' coverage.  You are therefore covered for the services available under Original Medicare Part A (Hospital insurance) coverage such as:
    <ul>
      <li>Inpatient care in a hospital</li>
      <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
      <li>Hospice care</li>
      <li>Home health care</li>
      <li>Inpatient care in a religious non-medical health care institution</li>
    </ul>
  </div>,
  partBMG: <div>
    When you purchase a Medicare Supplement plan, you are choosing to stay in Original Medicare and supplementing it with 'gap' coverage.  You are therefore covered for the services available under Original Medicare Part B (Medical insurance) coverage such as:
    <ul>
      <li>Doctor visits</li>
      <li>Medically necessary outpatient health services</li>
      <li>Home health services</li>
      <li>Durable medical equipment</li>
      <li>Mental health services</li>
      <li>Preventive services</li>
    </ul>
  </div>,
  pdpMG: 'When you purchase a Medicare Supplement plan, you are choosing to stay in Original Medicare and supplementing it with \'gap\' coverage.  If you are on Original Medicare, you can access Medicare drug coverage (Part D) by purchasing a standalone Prescription Drug Plan (PDP).  Late enrollment penalties can apply if you do not enroll in any sort of Part D coverage after you first become eligible for Medicare - exceptions apply if you have other qualifying (or \'creditable\') drug coverage such as coverage from an employer, TRICARE or VA.',
  extraBenefitsMG: 'When you purchase a Medicare Supplement plan, you are choosing to stay in Original Medicare and supplementing it with \'gap\' coverage.  Original Medicare will not cover any services that are not \'medically necessary\' and therefore does not cover extra benefits such as routine dental, vision, hearing and other benefits.',
  useAnyDoctorMG: 'When you purchase a Medicare Supplement plan, you are choosing to stay in Original Medicare and supplementing it with \'gap\' coverage.  With Original Medicare you can see visit any doctor or hospital that accepts Medicare in the U.S.',

  standardPartBPremium23: <Box sx={{
    '& table': {
      mt: 2,
      '& td': {
        fontSize: 11,
        padding: '3px'
      },
      '& td:not(:last-child)': {
        width: '50%'
      },
      '& td:last-child': {
        maxWidth: '70px',
        minWidth: '70px',
      },
      '& thead td': {
        fontWeight: 700,
        background: '#1e95a0',
        color: 'white',
      },
      '& tbody': {
        '& tr': {
          '& td': {
            borderSpacing: 0,
            '&:last-child': {
              fontWeight: 700
            }
          },
          '&:not(:last-child) td': {
            borderBottom: '1px solid #778E95',
          }
        }
      }
    }
  }}>
    Most Medicare beneficiaries will have to pay the standard Part B premium, regardless of the type of Medicare coverage they have.  Exceptions apply if you are eligible for Medicaid or other government programs that support those with limited income/resources.  If your income is above certain thresholds (based on your tax return from 2 years ago), you may pay have to pay a higher premium:
    <table>
      <thead>
      <tr>
        <td colSpan={2}>
          If your yearly income in 2021 was:
        </td>
        <td rowSpan={2}>
          2023 monthly premium
        </td>
      </tr>
      <tr>
        <td>
          File individual tax return
        </td>
        <td>
          File joint tax return
        </td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>$97,000 or less</td>
        <td>$194,000 or less	</td>
        <td>$164.90 </td>
      </tr>
      <tr>
        <td>up to $123,000</td>
        <td>up to $246,000	</td>
        <td>$230.80 </td>
      </tr>
      <tr>
        <td>up to $153,000</td>
        <td>up to $306,000	</td>
        <td>$329.70 </td>
      </tr>
      <tr>
        <td>up to $183,000</td>
        <td>up to $366,000	</td>
        <td>$428.60 </td>
      </tr>
      <tr>
        <td>up to $500,000</td>
        <td>up to $750,000	</td>
        <td>$527.50 </td>
      </tr>
      <tr>
        <td>$500,000 +</td>
        <td>$750,000 +	</td>
        <td>$560.50 </td>
      </tr>
      </tbody>
    </table>
  </Box>,

  standardPartBPremium: <Box sx={{
    '& table': {
      mt: 2,
      '& td': {
        fontSize: 11,
        padding: '3px'
      },
      '& td:not(:last-child)': {
        width: '50%'
      },
      '& td:last-child': {
        maxWidth: '70px',
        minWidth: '70px',
      },
      '& thead td': {
        fontWeight: 700,
        background: '#1e95a0',
        color: 'white',
      },
      '& tbody': {
        '& tr': {
          '& td': {
            borderSpacing: 0,
            '&:last-child': {
              fontWeight: 700
            }
          },
          '&:not(:last-child) td': {
            borderBottom: '1px solid #778E95',
          }
        }
      }
    }
  }}>
    Most Medicare beneficiaries will have to pay the standard Part B premium, regardless of the type of Medicare coverage they have.  Exceptions apply if you are eligible for Medicaid or other government programs that support those with limited income/resources.  If your income is above certain thresholds (based on your tax return from 2 years ago), you may pay have to pay a higher premium:
    <table>
      <thead>
      <tr>
        <td colSpan={2}>
          If your yearly income in 2022 was:
        </td>
        <td rowSpan={2}>
          2024 monthly premium
        </td>
      </tr>
      <tr>
        <td>
          File individual tax return
        </td>
        <td>
          File joint tax return
        </td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>$103,000 or less</td>
        <td>$206,000 or less	</td>
        <td>$174.70 </td>
      </tr>
      <tr>
        <td>up to $129,000</td>
        <td>up to $258,000	</td>
        <td>$244.60 </td>
      </tr>
      <tr>
        <td>up to $161,000</td>
        <td>up to $322,000	</td>
        <td>$349.40 </td>
      </tr>
      <tr>
        <td>up to $193,000</td>
        <td>up to $386,000	</td>
        <td>$454.20 </td>
      </tr>
      <tr>
        <td>up to $500,000</td>
        <td>up to $750,000	</td>
        <td>$559.00 </td>
      </tr>
      <tr>
        <td>$500,000 +</td>
        <td>$750,000 +	</td>
        <td>$594.00 </td>
      </tr>
      </tbody>
    </table>
  </Box>,

  IEP: <div>
    <Typography><b>The 7-month period around your 65th birthday</b></Typography>
    <Typography>If you are turning 65, and already receiving Social Security or Railroad Retirement Board (RRB) benefits at least 4 months prior to turning 65, you will automatically be enrolled in Medicare Parts A and B.</Typography>
    <Typography>If you are not automatically enrolled in Medicare, you will have to enroll in Medicare during your Initial Enrollment Period (IEP).</Typography>
    <Typography>Your Initial Enrollment Period is the 7-month period that begins 3 months before the month you turn 65.</Typography>
    <Typography>During this same period you may also choose to:</Typography>
    <ul>
      <li><Typography>Enroll in a Medicare Advantage (Part C) plan (you will need to be enrolled in both Part A and B in order to be eligible for part C)</Typography></li>
      <li><Typography>Enroll in a standalone Part D (Prescription Drug Coverage) plan (If you intend on keeping Original Medicare Part A and B)</Typography></li>
    </ul>
    <Typography>You may choose to defer your enrollment if you are working past 65. You should only delay enrollment if you are eligible to delay penalty-free.</Typography>
  </div>,

  GEP: <div>
    <Typography><b>January 1 – March 31 each year</b></Typography>
    <Typography>If you happen to miss your Initial Enrollment Period (and are not delaying enrollment in Medicare because you are still working) you can sign up for Part A and/or Part B during the General Enrollment Period (GEP) between January and March in any year after the year you are first eligible.</Typography>
    <Typography>If you sign up during GEP, your coverage will start the 1st of the month after you apply. However, you may have to pay a higher premium for late enrollment in Part A and/or Part B. You can find out more about Late Enrollment Penalties here.</Typography>
  </div>,

  MAOEP: <div>
    <Typography><b>January 1 – March 31 each year</b></Typography>
    <Typography>The Medicare Advantage Open Enrollment Period (MA-OEP) is often confused with the Annual Election Period (which is also sometimes called the Annual Open Enrollment Period), however, the two are not the same.</Typography>
    <Typography>The MA-OEP is only for individuals who are enrolled in the Medicare Advantage plan as of January 1. This could be someone who has renewed (or in other words did not make a change during AEP), who is newly enrolled, or switched Medicare Advantage plans during AEP.</Typography>
    <Typography>During the MA-OEP, anyone enrolled in a Medicare Advantage plan may:</Typography>
    <ul>
      <li><Typography>Change to a different Medicare Advantage plan</Typography></li>
      <li><Typography>Disenroll from Medicare Advantage and return to Original Medicare</Typography></li>
    </ul>
    <Typography>You may only change plans once during the MA-OEP.</Typography>
  </div>,

  MSOEP: <div>
    <Typography><b>6-months after you enroll in Part B</b></Typography>
    <Typography>The Medicare Supplement Open Enrollment Period (MS-OEP) is the 6-month period after you enroll in Part B. For most people this will happen when you turn 65, however, some people may delay enrollment into Part B while they are still working.</Typography>
    <Typography>Medigap is provided by private insurance carriers and the federal law does not require Medigap insurance carriers to accept you except in certain situations such as the MS-OEP.</Typography>
    <Typography>During the MS-OEP, Medigap companies: (i) must sell you a policy without medical questions, (ii) cannot deny you coverage, and (iii) cannot charge you an additional premium for coverage because of your medical history.  This is known as a ‘guaranteed issue’.</Typography>
    <Typography>The MS-OEP only happens once and does not renew once it has lapsed. This is the best time to purchase a Medigap policy if you want one. If you apply for Medigap coverage after your MS-OEP there is typically no guarantee that an insurance company will sell you a Medigap policy if you don’t meet the medical underwriting requirements. Some states may have other certain guaranteed issue periods outside of MS-OEP.</Typography>
  </div>,

  AEP: <div>
    <Typography><b>October 15 – December 7 each year</b></Typography>
    <Typography>The Annual Election Period (AEP) (also known as the Annual Open Enrollment Period) takes place on October 15 and December 7 each year and is available to all Medicare beneficiaries.</Typography>
    <Typography>During the AEP, beneficiaries may:</Typography>
    <ul>
      <li><Typography>Enroll into, disenroll (and return to Original Medicare) or switch Medicare Advantage (Part C) plans</Typography></li>
      <li><Typography>Enroll in, disenroll or switch Prescription Drug Plans (PDP)</Typography></li>
    </ul>
    <Typography>No action is required if you are choosing to keep your current plan, although it is always best practice to evaluate your options each year as both drug and plan benefits can change from year-to-year. The changes can include whether your doctor is covered (which is relevant if you are enrolled in the Medicare Advantage plan).</Typography>
    <Typography>During AEP, you may choose to make more than one enrollment choice but the last choice made during AEP will be the election that takes effect on January 1st of the following year.</Typography>
  </div>,

  SEP: <div>
    <Typography><b>Variable periods depending on your situation</b></Typography>
    <Typography>Medicare beneficiaries who experience a certain ‘qualifying event’ are provided a special period to enroll or change their Medicare coverage. These special periods are known as a Special Enrollment Period (SEP).</Typography>
    <Typography>The time frames for SEPs vary, however, most will typically begin on the first of the month in which you experience the ‘qualifying event’.</Typography>
    <Typography>Some of the more common situations (but not all) that result in a SEP are:</Typography>
    <ul>
      <li><Typography><b>Losing employer coverage:</b> if you had previously deferred enrollment into Medicare you have an 8-month SEP to enroll in Original Medicare or Medicare Advantage after you lose your employer coverage (or coverage from your spouse).</Typography></li>
      <li><Typography><b>Change in residence:</b> Medicare Advantage or Part D enrollees who move out of their plan’s service area have a 4-month SEP beginning 1 month prior to the month you move.</Typography></li>
      <li><Typography><b>Gaining or losing Medicaid eligibility / Low Income Subsidy (LIS):</b> If you become eligible for Medicaid or LIS, you will be eligible for a SEP every calendar quarter between January and September (and can therefore change plans once per calendar quarter).</Typography></li>
      <li><Typography><b>Chronic conditions:</b> If you gain or have a severe or disabling chronic condition, you have a continuous SEP to make one enrollment into a Medicare Advantage plan that specifically covers your condition (known as C-SNP (or Chronic condition-Special Needs Plan)).</Typography></li>
      <li><Typography><b>Medigap SEP (Trial Right):</b> If you have previously dropped a Medigap policy to enroll in Medicare Advantage for the very first time, you have a 12-month SEP to disenroll from and return to Original Medicare with a Medigap plan</Typography></li>
    </ul>
  </div>,

}



