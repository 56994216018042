import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useMarkerStyles = makeStyles({
  numberWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    top: 3,
    cursor: (props: MarkerProps) => props.clickable ? 'pointer' : undefined,
  },
  number: {
    fontWeight: 700,
    fontSize: 11,
    background: 'white',
    height: 18,
    width: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    padding: '2px 0px 0 0',
  },
  activeNumber: {
    fontWeight: 700,
    fontSize: 14,
    background: 'white',
    height: 26,
    width: 27,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    padding: '0',
  },
});

type MarkerProps = {
  index: number,
  clickable?: boolean,
  lat?: number,
  lng?: number,
  active?: boolean
}

const Marker = (props: MarkerProps) => {
  const classes = useMarkerStyles(props);
  return props.active ?
    <Box position={'relative'} width={'35px'} zIndex={1}>
      <img src={'/assets/img/marker-active.svg'} className={'w-35'}/>
      <div className={classes.numberWrapper}><div className={classes.activeNumber}>{props.index}</div></div>
    </Box>
    :
    <Box position={'relative'} width={'26px'} >
      <img src={'/assets/img/marker.svg'}/>
      <div className={classes.numberWrapper}><div className={classes.number}>{props.index}</div></div>
    </Box>
}

export default Marker;
