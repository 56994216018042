import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, FormControlLabel, Radio, RadioProps } from '@mui/material';
import { Check } from '@mui/icons-material';

const useStyles = makeStyles({
  root: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    padding: '9px 18px 9px 6px',
    backgroundColor: (props: CRRadioProps) => props.variant === 'standart' ? undefined : 'white',
    display: 'flex',
    flex: 1,
    borderRadius: 4,
    marginRight: '0!important',
    marginLeft: '0!important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    fontSize: 18,
    color: '#1C434F',
    paddingTop: 4,
    marginLeft: '10px!important'
  },
  checkedRoot: {
    border: `1px solid #1D9C80!important`,
    backgroundColor: 'rgba(94, 203, 158, 0.1)!important',
  },
  icon: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    backgroundColor: 'white',
    border: '1px solid #A4B4B9',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      // backgroundColor: '#ACCBC8',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: `#1D9C80!important`,
    color: 'white',
    fontSize: '18px!important',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    width: 22,
    height: 22,
    padding: 3,
  },
  subLabel: {
    color: '#778E95',
    fontSize: 14,
    lineHeight: '15px',
  },
});

type CRRadioProps = RadioProps & {label: React.ReactNode, subLabel?: React.ReactNode, labelClassName?: string, controlClassName?: string, variant?: 'outlined' | 'standart'};

export const CRRadio = (props: CRRadioProps) => {
  const classes = useStyles(props);
  const {controlClassName, labelClassName, subLabel, ...rest} = props;
  return (
    <FormControlLabel className={clsx({[classes.root]: true, [classes.checkedRoot]: props.checked, [controlClassName || ""]: !!controlClassName})}
                      sx={props.sx}
                      control={<Radio
                        color="default"
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<span className={classes.icon} />}
                        {...rest}
                      />}
                      label={<Box className={`${classes.label} ${labelClassName}`}>
                        {props.label}
                        {subLabel && <Box className={classes.subLabel}>{subLabel}</Box>}
                      </Box>}
    />

  );
}
