import {
  Box,
  Stack,
  Step, stepClasses,
  StepConnector,
  stepConnectorClasses, stepperClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled
} from '@mui/material';
import * as React from 'react';
import { colors } from '@coverright/ui/themes';
import stepLabelClasses from '@mui/material/StepLabel/stepLabelClasses';

const ColorlibStepper = styled(Stepper)(({ theme }) => ({
  [`&.${stepperClasses.vertical} :nth-child(2)`]: {
    marginTop: '-7px',
  },
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.vertical}`]: {
    padding: 0,
    marginLeft: 21,
    marginTop: '-18px',
    marginBottom: '-18px',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.custom.green.variant2,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.custom.green.variant2,
    },
  },
  [`& .${stepConnectorClasses.lineVertical}`]: {
    minHeight: 36,
    borderColor: 'rgba(28, 67, 79, 0.3)',
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 2,
  width: 36,
  height: 36,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
  }),
  ...(ownerState.completed && {
  }),
}));

const ColorlibStepLabel = styled(StepLabel)(({ theme }) => ({
  [`&.${stepLabelClasses.root}`]: {
    padding: 0,
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    paddingRight: 2,
  },
  [`& .${stepLabelClasses.label}`]: {
    marginTop: 10,
    paddingLeft: '3px',
  },
  [`& .${stepLabelClasses.disabled}`]: {
    color: colors.text.secondary,
    marginTop: 10,
    paddingLeft: '3px',
  },
  [`& span.${stepLabelClasses.active}, & span.${stepLabelClasses.completed}`]: {
    color: colors.custom.green.variant2,
    fontWeight: 700,
    cursor: 'pointer',
    marginTop: 10,
    paddingLeft: '3px',
  },
}));

const ColorlibStep = styled(Step)(({ theme }) => ({
  [`&.${stepClasses.root}`]: {
    [`&:not(:first-child) .${stepLabelClasses.root}:hover`]: {
      backgroundColor: 'rgba(2, 144, 148, 0.08)',
      borderRadius: '10px',
    },
    [`&:first-child .${stepLabelClasses.root}`]: {
      backgroundColor: colors.custom.green.variant2,
      borderRadius: '10px',
      paddingLeft: '3px',
      alignItems: 'flex-start',
    },
    [`&:first-child .${stepLabelClasses.root} .${stepLabelClasses.completed}`]: {
      fontSize: 16,
      lineHeight: '20px',
      color: 'white',
      fontWeight: 400,
      padding: '10px 0 12px',
    }
  },
}));

export default function MainStepper({step}: {step: NavStep}) {


  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icon = props.icon === 1 ?
      <Box m={'10px 8px 0 9px'} className={'w-24 h-24'}><img src={step.icon + '-active.svg'} /></Box> :
      <Box sx={{
        background: active || completed ? colors.custom.green.variant2 : '#FFFFFF',
        border: '1px solid ' + (active || completed ? colors.custom.green.variant2 : 'rgba(28, 67, 79, 0.3)'),
        width: '11px',
        height: '11px',
        borderRadius: '6px',
        marginLeft: '7px',
      }} />;

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icon}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <ColorlibStepper orientation="vertical"  activeStep={1}  connector={<ColorlibConnector />}>
        <ColorlibStep>
          <ColorlibStepLabel StepIconComponent={ColorlibStepIcon}>{step.label}</ColorlibStepLabel>
        </ColorlibStep>
        {step.children?.map(({label, active, onClick}, i) => (
          <ColorlibStep key={label} active={active} onClick={onClick}>
            <ColorlibStepLabel StepIconComponent={ColorlibStepIcon}>{label}</ColorlibStepLabel>
          </ColorlibStep>
        ))}
      </ColorlibStepper>
    </Stack>
  );
}

export interface NavStep {
  icon?: string,
  forceActive?: boolean,
  forceOnClick?: boolean,
  active?: boolean,
  label: string,
  onClick?: () => void,
  activeRoutes?: string[],
  children?: NavStep[]
}
