import React from 'react';
import {
  Box, Fade, Hidden,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from '@mui/material';
import { colors } from '@coverright/ui/themes';
import { createStyles, makeStyles } from '@mui/styles';
import { Check } from '@mui/icons-material';
import clsx from 'clsx';
import { ContactsSection, CRPreloader } from '../..';
import { styled } from '@mui/material/styles';

/* eslint-disable-next-line */
export interface LoadingProps {
  labels: string[],
  title: string,
  subtitle: string,
  pin?: string,
  in: boolean
}

export function Loading(props: LoadingProps) {
  return (
    <Fade in={props.in}>
      <Box sx={{
        position: 'fixed',
        zIndex: 10,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: '#f5f7f6',
      }} >
        <Box sx={{display: {xs: 'none', lg: 'block'}}} className={'flex flex-justify-end mb-50 pt-16 pr-20'}>
          <ContactsSection helpModalComponent={() => <></>} />
        </Box>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column"
        }} >
          <Typography className={'fs-40 bold'} align={'center'}>{props.title}</Typography>
          <Typography className={'fs-22 bold mt-12'} color={colors.custom.green.variant2} align={'center'}>{props.subtitle}</Typography>
          <div className={"h-55"}/>
          <CRPreloader/>
          <div className={"h-40"}/>
          <LoadingStepper labels={props.labels}/>
          <div className={"h-50"}/>
        </Box>
      </Box>
    </Fade>
  );
}


const LoadingStepLabel = styled(StepLabel)({
  '&.MuiStepLabel': {
    fontSize: 18,
    fontWeight: 700,
    color: colors.text.secondary + `!important`,
    "&.MuiStepLabel-completed": {
      fontWeight: 700,
      fontSize: 18,
      color: `${colors.text.secondary}!important`
    },
    "&.MuiStepLabel-active": {
      fontSize: 18,
      fontWeight: 700,
      color: `${colors.text.secondary}!important`,
    },
  },
});


const AscendConnector = styled(StepConnector)({
  vertical: {
    padding: 0,
  },
  completed: {
    "& .MuiStepConnector-line": {
      borderColor: colors.custom.green.variant5,
    },
  },
  active: {
    "& .MuiStepConnector-line": {
      borderColor: colors.custom.green.variant5,
    },
  },
  lineHorizontal: {
    marginTop: 9,
    marginLeft: -5,
    border: "3px solid #CCD8DC",
  }
});

const useAscendStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: "white",
    width: 48,
    height: 48,
    fontSize: 24,
    fontWeight: 700,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
    backgroundColor: "#CCD8DC",
  },
  active: {
    backgroundColor: colors.custom.green.variant5,
    color: "white",
  },
  completed: {
    backgroundColor: `${colors.custom.green.variant5}!important`,
    color: "white",
    fontSize: 24,
    fontWeight: 700,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
});

function StepIcon(props: StepIconProps) {
  const classes = useAscendStepIconStyles();
  const {active, completed} = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <Check className={clsx({[classes.completed]: completed})}/>
    </div>
  );
}

const useStepperStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      backgroundColor: "transparent",
      minWidth: 567,
      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
      },
    },
    step: {
      // cursor: 'pointer'
      pointerEvents: "none"
    },
    stepper: {
      marginTop: 30,
    },
    green: {
      "& .MuiStepLabel-label": {
        color: `${colors.custom.green.variant5}!important`,
      },
    }
  }),
);

function LoadingStepper({labels}: {labels: string[]}) {
  const classes = useStepperStyles();


  return (<Box className={classes.stepper}>
      <Stepper alternativeLabel classes={{root: classes.root}} connector={<AscendConnector/>}>
        <Step completed={true}>
          <LoadingStepLabel StepIconComponent={() => <StepIcon icon={1}
                                                               completed={true}/>}>{labels[0]}</LoadingStepLabel>
        </Step>
        <Step completed={true}>
          <LoadingStepLabel StepIconComponent={() => <StepIcon icon={1}
                                                               completed={true}/>}>{labels[1]}</LoadingStepLabel>
        </Step>
        <Step completed={false}>
          <LoadingStepLabel StepIconComponent={() => <StepIcon icon={1}
                                                               completed={false}/>}>{labels[2]}</LoadingStepLabel>
        </Step>
      </Stepper>
    </Box>
  )
}
