import { validate } from 'uuid';
import { getStoredUser, setStoredUser } from './StoredUser';

export function getQuoteId(): string | undefined {
  const params = new URLSearchParams(document.location.search);
  let user = getStoredUser();

  if (params.has('quote') && validate(params.get('quote')!)) {
    user = {...user, quoteId: params.get('quote')!};
    setStoredUser(user)
  }

  return user?.quoteId || undefined;
}

const medicareListeners: any[] = [];


export function listenQuoteIdChange(callback: (newId?: string) => void) {
  medicareListeners.push(callback);
}

export function setQuoteId(quoteId: string) {
  if (medicareListeners.length) {
    const prevValue = getQuoteId();
    if (prevValue !== quoteId) {
      medicareListeners.forEach(c => c(quoteId));
    }
  }
  setStoredUser({...getStoredUser(), quoteId})
}

export function resetQuoteId(): void {
  setStoredUser({...getStoredUser(), quoteId: undefined})
}

const pdpListeners: any[] = [];

export function getPdpQuoteId(): string | undefined {
  const params = new URLSearchParams(document.location.search);
  let user = getStoredUser();

  if (params.has('pdpQuote') && validate(params.get('pdpQuote')!)) {
    user = {...user, pdpQuoteId: params.get('pdpQuote')!};
    setStoredUser(user)
  }

  return user?.pdpQuoteId || undefined;
}

export function setPdpQuoteId(quoteId: string) {
  if (pdpListeners.length) {
    const prevValue = getPdpQuoteId();
    if (prevValue !== quoteId) {
      pdpListeners.forEach(c => c(quoteId));
    }
  }
  setStoredUser({...getStoredUser(), pdpQuoteId: quoteId})
}

export function listenPdpQuoteChange(callback: (newId?: string) => void) {
  pdpListeners.push(callback);
}

export function resetPdpQuoteId(): void {
  setStoredUser({...getStoredUser(), medigapQuoteId: undefined})
}



const medigapListeners: any[] = [];

export function getMedigapQuoteId(): string | undefined {
  const params = new URLSearchParams(document.location.search);
  let user = getStoredUser();

  if (params.has('medigapQuote') && validate(params.get('medigapQuote')!)) {
    user = {...user, medigapQuoteId: params.get('medigapQuote')!};
    setStoredUser(user)
  }

  return user?.medigapQuoteId || undefined;
}

export function setMedigapQuoteId(quoteId: string) {
  if (medigapListeners.length) {
    const prevValue = getMedigapQuoteId();
    if (prevValue !== quoteId) {
      medigapListeners.forEach(c => c(quoteId));
    }
  }
  setStoredUser({...getStoredUser(), medigapQuoteId: quoteId})
}

export function listenMedigapQuoteChange(callback: (newId?: string) => void) {
  medigapListeners.push(callback);
}

export function resetMedigapQuoteId(): void {
  setStoredUser({...getStoredUser(), medigapQuoteId: undefined})
}


