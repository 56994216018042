import * as React from 'react';
import { EventParams, logEvent } from '@firebase/analytics';
import { AnalyticsContext, UserTestContext } from '@coverright/shared/contexts';
import { getCId } from '@coverright/utils';
import {keycloak} from "@coverright/shared/keycloak";

export function useLogEvent() {
  const analytics = React.useContext(AnalyticsContext);
  const {abTesting} = React.useContext(UserTestContext);

  const log = (eventName: string, params?: EventParams & TrackingEvent) => {
    return new Promise(resolve => {
      let trackName = eventName.toString();

      const sendEvent = (cId?: string) => {
        const data: any = {
          c_id: cId,
          product: (window as any).product,
          version: (window as any).version,
          page: document.location.href,
          page_canonical: document.location.origin + document.location.pathname,
          page_title: document.title,
          event_type: eventName,
          email: keycloak?.tokenParsed?.email,
          ...params
        };

        if (abTesting) {
          data['ab_testing'] = abTesting;
        }

        if (eventName === 'page_view') {
          (window as any).gtm_step_name = params?.page_title;
          trackName = 'Page view - ' + params?.page_title;
          if ((window as any).track) {
            (window as any).track(trackName, data)
          }
        }

        if ((window as any).track) {
          if (analytics) {
            logEvent(analytics, eventName as any, flatten(params) as any);
          }
          (window as any).track(eventName, data)
        } else {
          console.log('DEVELOP', eventName, data);
        }
      }

      let cId = (window as any).c_id || getCId();

      if (cId) {
        sendEvent(cId);
        resolve(true);
      } else {
        let count = 0;

        const interval = setInterval(() => {

          cId = (window as any).c_id || getCId();

          if (cId || count > 5) {
            sendEvent(cId);
            clearInterval(interval);
            resolve(true);
          }

          count++;
        }, 500)
      }


    })
  }

  return log;
}

interface TrackingEvent {
  c_id?: string,
  page?: string,
  product?: string,
  page_title?: string,
  element_title?: string,
  element_id?: string,
}

function traverseAndFlatten(currentNode: any, target: any, flattenedKey?: string) {
  for (const key in currentNode) {
    // eslint-disable-next-line no-prototype-builtins
    if (currentNode.hasOwnProperty(key)) {
      let newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = flattenedKey + '.' + key;
      }

      const value: any = currentNode[key];
      if (typeof value === "object") {
        traverseAndFlatten(value, target, newKey);
      } else {
        target[newKey] = value;
      }
    }
  }
}

function flatten(obj: any) {
  const flattenedObject = {};
  traverseAndFlatten(obj, flattenedObject);
  return flattenedObject;
}
