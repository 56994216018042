import { Box, Button,Typography } from '@mui/material';
import { CRModal } from '@coverright/ui/shared';
import * as React from 'react';
import {ModalCloseButton} from "@coverright/shared/ui";


type NotInYourAreaModalProps = {
  onClose: () => void,
  open: boolean
}

export function NotInYourAreaModal(props: NotInYourAreaModalProps) {
   return <CRModal
    width={450}
    open={props.open}
    onClose={props.onClose}
    modalScroll
    paperPadding={'24px'}
    data-test={'not-in-your-area-modal'}
   >
      <Box display={'flex'} flexDirection={'column'} flex={1} gap={2}>
        <Box sx={{display: 'flex', justifyContent: 'flex-end', m: '-8px'}}>
          <ModalCloseButton onClick={props.onClose} />
        </Box>

        <Typography variant={'h4'} align={'center'}>We’re currently not in your area!</Typography>

        <Typography  align={'center'}>
          CoverRight is currently not licensed in your state.
        </Typography>

        <Typography sx={{color: 'textSecondary'}} align={'center'}>
          You can continue to our free Medicare 101 course and learning resources but you will be unable to shop plans.
        </Typography>

        <Box display={"flex"} justifyContent={"center"}>
          <Button variant={"contained"}
                  color={'primary'}
                  onClick={props.onClose}
                  sx={{width: '310px'}}>
            Okay, got it
          </Button>
        </Box>
      </Box>
  </CRModal>
}
