import { Box, Button, Card, Collapse, Typography } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TextInput } from '@coverright/ui/inputs';
import { useHistory } from 'react-router-dom';
import { AppStateActionTypes, AppStateContext } from '../../AppStateContext';
import { PageWrapper } from '@coverright/ui/shared';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required')
    .test("test-advisor",
      "You are not authorized",
      (value?: string) => value ? validAdvisors.includes(value) : false)
  ,
  firstName: yup
    .string()
    .required('First Name is required'),
  lastName: yup
    .string()
    .required('Last Name is required'),
});

export default function () {
  const history = useHistory();
  const [state, dispatch] = React.useContext(AppStateContext);
  const formik = useFormik({
    initialValues: {
      email: state.adviserEmail || '',
      firstName: state.adviserFirstName || '',
      lastName: state.adviserLastName || '',
    },
    validationSchema,
    onSubmit: values => {
      if (formik.isValid) {
        dispatch({
          type: AppStateActionTypes.UPDATE,
          payload: {
            adviserEmail: values.email,
            adviserFirstName: values.firstName || '',
            adviserLastName: values.lastName || '',
          }
        });
        history.push('/visit-info')
      }
    },
  });

  React.useEffect(() => {
    formik.setFieldValue('lastName', state.adviserLastName || '')
    formik.validateForm()
  }, [state.adviserLastName])

  React.useEffect(() => {
    formik.setFieldValue('firstName', state.adviserFirstName || '')
    formik.validateForm()
  }, [state.adviserFirstName])

  React.useEffect(() => {
    formik.setFieldValue('email', state.adviserEmail || '')
    formik.validateForm()
  }, [state.adviserEmail])

  return <PageWrapper title={''}
                      formName={'advisor-info'}
                      hideFooter
  >
    <Card sx={{maxWidth: 480, width: '100%', margin: 'auto', maxHeight: 600, display: 'flex', mt: '75px', flexDirection: 'column', alignItems: 'center', p: '56px 0 30px'}}>
      <Box sx={{maxWidth: 180}}>
        <Box component={'img'} src={'/assets/img/Logo.svg'} />
        <Typography align={'right'} className={'fs-16 ls-1 medium mt-5'} sx={{color: '#1DB399'}}>Advisor Referrals</Typography>
      </Box>
      <Typography align={'center'} className={'fs-22 lh-27 medium mv-40'}>Please enter your<br />details below</Typography>
      <Box component={'form'} sx={{maxWidth: 420}} onSubmit={formik.handleSubmit} className={'fill-width'}>
        <Box sx={{display: 'flex', gap: 2}}>
          <TextInput
            fullWidth
            name="firstName"
            data-test="first_name"
            label="First Name"
            placeholder={'First name'}
            autoFocus={true}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
          <TextInput
            fullWidth
            name="lastName"
            data-test="last_name"
            label="Last Name"
            placeholder={'Last name'}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Box>
        <Collapse in={!!formik.values.lastName && !!formik.values.lastName}>
          <TextInput
            fullWidth
            name="email"
            label="Email"
            data-test="email"
            placeholder={'Email address'}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Collapse>
        <Button sx={{display: {xs: 'none', sm: 'flex'}}}
                variant={'contained'}
                size={'large'}
                data-test={'next_button'}
                type={'submit'}
                className={'mt-20'}
                fullWidth>
          Get Started
        </Button>
      </Box>
    </Card>
  </PageWrapper>;
}

const validAdvisors = [
  'nwhitmer@guidancecapital.com',
  'igor@vilga.com',
  'zachary.m.katz@nm.com',
  'richardc@coverright.com',
  'richard@coverright.com',
  'test-advisor@yopmail.com',
  'Perry.Goldschein@equitable.com',
  'jerry@artifexfinancial.com',
  'amir@unitedfpg.com',
  'wes.petteway@nm.com',
  'rjimenez.ariasagencies@gmail.com',
  'john@brightonfinancial.com',
  'adepersio@cspgllc.com',
  'sharon.hayut@magnusfinancial.com',
  'michael@cereusfinancial.com',
  'pedicinis@hallcapmgt.com',
  'jmccaffery@financialguide.com',
  'Matthew.McKee@morganstanley.com',
  'tyler@retirable.com',
  'ian@retirable.com',
  'shawn.tighe@alexbrown.com',
  'tschulte@uswealthmanagement.com',
  'jordan.gilberti@facetwealth.com',
  'lj@developingfinancial.com',
  'tom@interofp.com',
  'tbreaux@protonmail.com',
  'dwallace@barnumfg.com',
  'alex@rcacap.com',
  'Tayo.Ogunnaike@morganstanley.com',
  'Dennis.Dering@morganstanley.com',
  'Olivia.Cero@morganstanley.com',
  'nberlen@uswealthmanagement.com',
  'lorraine@brightonfinancial.com',
]
